import { getCurrentUserDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetCurrentUser = {
  data() {
    return {
      getCurrentUserDetailsObject: {},
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetCurrentUser() {
      try {
        this.overlay = true;
        this.Expand = false
        let result = await API.graphql(
          graphqlOperation(getCurrentUserDetails, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );
        this.$store.commit("SET_CURRENTUSER_DETAILS", {});
        this.$store.commit("SET_CURRENTUSER_DETAILS", JSON.parse(result.data.GetCurrentUserDetails).data.items[0]);
        this.getCurrentUserDetailsObject = JSON.parse(result.data.GetCurrentUserDetails).data.items[0];
        console.warn("getCurrentUserDetailsObject",this.getCurrentUserDetailsObject)
        this.user_type == JSON.parse(result.data.GetCurrentUserDetails).data.items[0].user_type;
        this.$store.commit("SET_USER_TYPE", JSON.parse(result.data.GetCurrentUserDetails).data.items[0].user_type);
        if (this.$route.name == "LandingPage" || this.$route.name == "Users") {
          this.$router.push("/Dashboard");
        }
        localStorage.setItem("UserEmail", JSON.stringify(this.$store.getters.get_user_email));
        this.overlay = false;
        this.Expand = true
      } catch (error) {
        this.overlay = false;
        this.Expand = true
      }
    },
  },
};
