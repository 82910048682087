<template>
  <v-dialog
    v-model="dialogTemplatePreview"
    persistent
    fullscreen
    color="#757575"
  >
    <Overlay :overlay="overlay" />
    <v-card>
      <v-toolbar dark color="primary" dense class="elevation-0">
        Template Preview
        <span class="ml-2" v-if="TemplateStoreObj.action == 'EDIT'"
          >({{ TemplateStoreObj.template_name }})</span
        >
        <span
          style="
            font-size: 20px;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
          "
        >
          Page {{ currentPage }}/{{ totalPage }}
        </span>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon @click="PageBuffer" v-bind="attrs" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span align="center">Refresh</span>
        </v-tooltip>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-file-eye</v-icon>
            </v-btn>
          </template>
          <v-card width="300px">
            <h3
              align="center"
              class="pt-3 font-weight-regular"
              style="color: #019267"
            >
              PAGE ORIENTATION
            </h3>
            <small style="color: #019267" class="ml-11"
              >*Changes will be Applied to All the Pages</small
            >
            <v-toolbar dense flat>
              <v-radio-group v-model="ApplyPageType" row class="mt-5 ml-10">
                <v-radio label="Portrait" value="P"></v-radio>
                <v-radio label="Landscape" value="L"></v-radio>
              </v-radio-group>
            </v-toolbar>
          </v-card>
        </v-menu>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <span style="font-size: 18px">H</span>
            </v-btn>
          </template>
          <v-card width="300px">
            <h3
              align="center"
              class="pt-3 font-weight-regular"
              style="color: #019267"
            >
              HEADER
            </h3>
            <small style="color: #019267" class="ml-13"
              >*Changes will be Applied to All Pages</small
            >
            <v-toolbar dense flat>
              <v-radio-group v-model="HeaderPaddingTO" row class="mt-5 ml-5">
                <v-radio label="Left" value="Left"></v-radio>
                <v-radio label="Middle" value="Middle"></v-radio>
                <v-radio label="Right" value="Right"></v-radio>
              </v-radio-group>
            </v-toolbar>
            <div v-if="HeaderPaddingTO == 'Left'">
              <v-card-text class="mt-n7">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  TOP PADDING
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderPaddingTop"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Top')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Top')"
                      :disabled="HeaderPaddingTop == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n16">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  LEFT PADDING
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderPaddingLeft"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Left')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Left')"
                      :disabled="HeaderPaddingLeft == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <small style="color: #019267" class="ml-16 mt-n16"
                  >*Applicable only for Images</small
                >
              </v-card-text>
              <v-card-text class="mt-n12">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  RESIZE
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderResize"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Resize')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Resize')"
                      :disabled="HeaderResize == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
            <div v-else-if="HeaderPaddingTO == 'Middle'">
              <v-card-text class="mt-n7">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  TOP PADDING
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderPaddingTop"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Top')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Top')"
                      :disabled="HeaderPaddingTop == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n16">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  LEFT PADDING
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderPaddingLeft"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Left')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Left')"
                      :disabled="HeaderPaddingLeft == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <small style="color: #019267" class="ml-16 mt-n16"
                  >*Applicable only for Images</small
                >
              </v-card-text>
              <v-card-text class="mt-n12">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  RESIZE
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderResize"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Resize')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Resize')"
                      :disabled="HeaderResize == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
            <div v-else-if="HeaderPaddingTO == 'Right'">
              <v-card-text class="mt-n7">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  TOP PADDING
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderPaddingTop"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Top')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Top')"
                      :disabled="HeaderPaddingTop == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n16">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  LEFT PADDING
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderPaddingLeft"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Left')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Left')"
                      :disabled="HeaderPaddingLeft == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <small style="color: #019267" class="ml-16 mt-n16"
                  >*Applicable only for Images</small
                >
              </v-card-text>
              <v-card-text class="mt-n12">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  RESIZE
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="HeaderResize"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="HeaderPadding('+', 'Resize')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="HeaderPadding('-', 'Resize')"
                      :disabled="HeaderResize == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-card>
        </v-menu>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <span style="font-size: 18px">F</span>
            </v-btn>
          </template>
          <v-card width="300px">
            <h3
              align="center"
              class="pt-3 font-weight-regular"
              style="color: #019267"
            >
              FOOTER
            </h3>
            <small style="color: #019267" class="ml-13"
              >*Changes will be Applied to All Pages</small
            >
            <v-toolbar dense flat>
              <v-radio-group v-model="FooterPaddingTO" row class="mt-5 ml-5">
                <v-radio label="Left" value="Left"></v-radio>
                <v-radio label="Middle" value="Middle"></v-radio>
                <v-radio label="Right" value="Right"></v-radio>
              </v-radio-group>
            </v-toolbar>
            <div v-if="FooterPaddingTO == 'Left'">
              <v-card-text class="mt-n7">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  TOP PADDING
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterPaddingTop"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Top')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Top')"
                      :disabled="FooterPaddingTop == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n16">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  LEFT PADDING
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterPaddingLeft"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Left')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Left')"
                      :disabled="FooterPaddingLeft == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <small style="color: #019267" class="ml-16 mt-n16"
                  >*Applicable only for Images</small
                >
              </v-card-text>
              <v-card-text class="mt-n12">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  RESIZE
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterResize"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Resize')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Resize')"
                      :disabled="FooterResize == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
            <div v-else-if="FooterPaddingTO == 'Middle'">
              <v-card-text class="mt-n7">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  TOP PADDING
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterPaddingTop"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Top')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Top')"
                      :disabled="FooterPaddingTop == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n16">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  LEFT PADDING
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterPaddingLeft"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Left')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Left')"
                      :disabled="FooterPaddingLeft == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <small style="color: #019267" class="ml-16 mt-n16"
                  >*Applicable only for Images</small
                >
              </v-card-text>
              <v-card-text class="mt-n12">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  RESIZE
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterResize"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Resize')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Resize')"
                      :disabled="FooterResize == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
            <div v-else-if="FooterPaddingTO == 'Right'">
              <v-card-text class="mt-n7">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  TOP PADDING
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterPaddingTop"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Top')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Top')"
                      :disabled="FooterPaddingTop == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n16">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  LEFT PADDING
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterPaddingLeft"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Left')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Left')"
                      :disabled="FooterPaddingLeft == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <small style="color: #019267" class="ml-16 mt-n16"
                  >*Applicable only for Images</small
                >
              </v-card-text>
              <v-card-text class="mt-n12">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  RESIZE
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="FooterResize"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="FooterPadding('+', 'Resize')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="FooterPadding('-', 'Resize')"
                      :disabled="FooterResize == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-card>
        </v-menu>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <span style="font-size: 18px">C</span>
            </v-btn>
          </template>
          <v-card width="300px">
            <h3
              align="center"
              class="pt-3 font-weight-regular"
              style="color: #019267"
            >
              CONTENT
            </h3>
            <small style="color: #019267" class="ml-13"
              >*Changes will be Applied to All Pages</small
            >
            <v-card-text class="mt-n5">
              <h4
                align="center"
                class="pt-3 font-weight-regular"
                style="color: #019267"
              >
                TOP PADDING
              </h4>
              <v-row no-gutters>
                <v-col md="7">
                  <v-text-field
                    dense
                    outlined
                    class="mr-3 mt-3"
                    v-model="ContentPaddingTop"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mr-5 mt-4"
                    @click="ContentPadding('+', 'Top')"
                  >
                    <v-icon small class="mx-1">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mx-3 mt-4"
                    @click="ContentPadding('-', 'Top')"
                    :disabled="ContentPaddingTop == 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="mt-n16">
              <h4
                align="center"
                class="pt-3 font-weight-regular"
                style="color: #019267"
              >
                LEFT PADDING
              </h4>
              <v-row no-gutters>
                <v-col md="7">
                  <v-text-field
                    dense
                    outlined
                    class="mr-3 mt-3"
                    v-model="ContentPaddingLeft"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mr-5 mt-4"
                    @click="ContentPadding('+', 'Left')"
                  >
                    <v-icon small class="mx-1">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mx-3 mt-4"
                    @click="ContentPadding('-', 'Left')"
                    :disabled="ContentPaddingLeft == 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="mt-n16">
              <h4
                align="center"
                class="pt-3 font-weight-regular"
                style="color: #019267"
              >
                RIGHT PADDING
              </h4>
              <v-row no-gutters class="mb-n4">
                <v-col md="7">
                  <v-text-field
                    dense
                    outlined
                    class="mr-3 mt-3"
                    v-model="ContentPaddingRight"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mr-5 mt-4"
                    @click="ContentPadding('+', 'Right')"
                  >
                    <v-icon small class="mx-1">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mx-3 mt-4"
                    @click="ContentPadding('-', 'Right')"
                    :disabled="ContentPaddingRight == 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <span style="font-size: 18px">S</span>
            </v-btn>
          </template>
          <v-card width="300px">
            <h3
              align="center"
              class="pt-3 font-weight-regular"
              style="color: #019267"
            >
              SIGNATURE
            </h3>
            <small style="color: #019267" class="ml-2"
              >*Changes will be Applied to Current Page({{ currentPage }})
              Signature</small
            >
            <v-card-text class="mt-n4">
              <h4
                align="center"
                class="pt-3 font-weight-regular"
                style="color: #019267"
              >
                TOP PADDING
              </h4>
              <v-row no-gutters class="mb-n4">
                <v-col md="7">
                  <v-text-field
                    dense
                    outlined
                    class="mr-3 mt-3"
                    v-model="SignaturePaddingTop"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mr-5 mt-4"
                    @click="SignaturePadding('+', 'Top')"
                  >
                    <v-icon small class="mx-1">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mx-3 mt-4"
                    @click="SignaturePadding('-', 'Top')"
                    :disabled="SignaturePaddingTop == 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="mt-n12">
              <h4
                align="center"
                class="pt-3 font-weight-regular"
                style="color: #019267"
              >
                LEFT PADDING
              </h4>
              <v-row no-gutters class="mb-n4">
                <v-col md="7">
                  <v-text-field
                    dense
                    outlined
                    class="mr-3 mt-3"
                    v-model="SignaturePaddingLeft"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mr-5 mt-4"
                    @click="SignaturePadding('+', 'Left')"
                  >
                    <v-icon small class="mx-1">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mx-3 mt-4"
                    @click="SignaturePadding('-', 'Left')"
                    :disabled="SignaturePaddingLeft == 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card width="300px" class="mt-n6 elevation-0">
            <h3
              align="center"
              class="pt-3 font-weight-regular"
              style="color: #019267"
            >
              TEXT PADDING
            </h3>
            <small style="color: #019267" class="ml-5"
              >*Changes will be Applied to Current Page({{ currentPage }})
              Text</small
            >
            <v-card-text class="mt-n4">
              <h4
                align="center"
                class="pt-3 font-weight-regular"
                style="color: #019267"
              >
                TOP PADDING
              </h4>
              <v-row no-gutters class="mb-n4">
                <v-col md="7">
                  <v-text-field
                    dense
                    outlined
                    class="mr-3 mt-3"
                    v-model="TextSignaturePaddingTop"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mr-5 mt-4"
                    @click="TextSignaturePadding('+', 'Top')"
                  >
                    <v-icon small class="mx-1">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mx-3 mt-4"
                    @click="TextSignaturePadding('-', 'Top')"
                    :disabled="TextSignaturePaddingTop == 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="mt-n12">
              <h4
                align="center"
                class="pt-3 font-weight-regular"
                style="color: #019267"
              >
                LEFT PADDING
              </h4>
              <v-row no-gutters class="mb-n4">
                <v-col md="7">
                  <v-text-field
                    dense
                    outlined
                    class="mr-3 mt-3"
                    v-model="TextSignaturePaddingLeft"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mr-5 mt-4"
                    @click="TextSignaturePadding('+', 'Left')"
                  >
                    <v-icon small class="mx-1">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col md="2">
                  <v-btn
                    small
                    color="primary"
                    class="mx-3 mt-4"
                    @click="TextSignaturePadding('-', 'Left')"
                    :disabled="TextSignaturePaddingLeft == 0"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-image-edit-outline</v-icon>
            </v-btn>
          </template>
          <v-card width="300px">
            <h3
              align="center"
              class="pt-3 font-weight-regular"
              style="color: #019267"
            >
              IMAGE
            </h3>
            <small style="color: #019267" class="ml-4"
              >*Changes will be Applied to Current Page({{ currentPage }})
              images</small
            >
            <v-toolbar dense flat>
              <v-radio-group v-model="ImagePaddingTO" row class="mt-5 ml-16">
                <v-radio label="Top" value="Top"></v-radio>
                <v-radio label="Bottom" value="Bottom"></v-radio>
              </v-radio-group>
            </v-toolbar>
            <div v-if="ImagePaddingTO == 'Top'">
              <v-card-text class="mt-n7">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  TOP PADDING
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="ImagePaddingTop"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="ImagePadding('+', 'Top')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="ImagePadding('-', 'Top')"
                      :disabled="ImagePaddingTop == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n14">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  LEFT PADDING
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="ImagePaddingLeft"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="ImagePadding('+', 'Left')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="ImagePadding('-', 'Left')"
                      :disabled="ImagePaddingLeft == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n10">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  HEIGHT
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="ImageHeight"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="ImagePadding('+', 'Height')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="ImagePadding('-', 'Height')"
                      :disabled="ImageHeight == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n14">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  WIDTH
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="ImageWidth"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="ImagePadding('+', 'Width')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="ImagePadding('-', 'Width')"
                      :disabled="ImageWidth == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
            <div v-if="ImagePaddingTO == 'Bottom'">
              <v-card-text class="mt-n7">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  TOP PADDING
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="ImagePaddingTop"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="ImagePadding('+', 'Top')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="ImagePadding('-', 'Top')"
                      :disabled="ImagePaddingTop == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n14">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  LEFT PADDING
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="ImagePaddingLeft"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="ImagePadding('+', 'Left')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="ImagePadding('-', 'Left')"
                      :disabled="ImagePaddingLeft == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n10">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  HEIGHT
                </h4>
                <v-row no-gutters>
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="ImageHeight"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="ImagePadding('+', 'Height')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="ImagePadding('-', 'Height')"
                      :disabled="ImageHeight == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-n14">
                <h4
                  align="center"
                  class="pt-3 font-weight-regular"
                  style="color: #019267"
                >
                  WIDTH
                </h4>
                <v-row no-gutters class="mb-n4">
                  <v-col md="7">
                    <v-text-field
                      dense
                      outlined
                      class="mr-3 mt-3"
                      v-model="ImageWidth"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mr-5 mt-4"
                      @click="ImagePadding('+', 'Width')"
                    >
                      <v-icon small class="mx-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      small
                      color="primary"
                      class="mx-3 mt-4"
                      @click="ImagePadding('-', 'Width')"
                      :disabled="ImageWidth == 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-card>
        </v-menu>
        <v-btn dark icon @click="ViewPdfPreview()">
          <v-icon>mdi-tray-arrow-down</v-icon>
        </v-btn>
        <v-btn dark icon @click="templatePreviewDialogEmit()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card elevation="0" color="#e5f6ed">
        <v-virtual-scroll
          :items="pageItems"
          :height="height"
          :item-height="pageItems[0].page_type == 'P' ? 1150 : 820"
          :bench="100"
          @scroll.passive="handleScroll"
          ref="scollMe"
        >
          <template v-slot:default="{ item }">
            <v-card
              :key="item.action"
              tile
              class="center elevation-2 mt-3"
              :color="pageItems[0].templateItems[11].content"
              :id="`PrintPage${item.page_number}`"
              :height="
                item.page_type == 'P'
                  ? enableBorder != true
                    ? '1123'
                    : '1113'
                  : enableBorder != true
                  ? '793'
                  : '783'
              "
              :width="item.page_type == 'P' ? '793' : '1123'"
              style="padding-top: 1px"
            >
              <div v-html="item.template_html"></div>
            </v-card>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import { FormPDFTemplate } from "@/mixins/FormPDFTemplate.js";
import Overlay from "@/components/Extras/Overlay.vue";

import { previewPDFTemplate } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

export default {
  components: {
    Overlay,
  },
  props: {
    dialogTemplatePreview: Boolean,
    TemplateStoreObj: Object,
    pageItems: Array,
    enableBorder: Boolean,
  },
  data: () => ({
    height: 0,
    currentPage: 0,
    totalPage: 0,
    overlay: false,
    ContentPaddingTop: 20,
    ContentPaddingLeft: 20,
    ContentPaddingRight: 20,
    HeaderPaddingTO: "Left",
    HeaderPaddingTop: 0,
    HeaderPaddingBottom: 0,
    HeaderPaddingLeft: 0,
    HeaderPaddingRight: 0,
    HeaderResize: 200,
    FooterPaddingTO: "Left",
    FooterPaddingTop: 0,
    FooterPaddingBottom: 0,
    FooterPaddingLeft: 0,
    FooterPaddingRight: 0,
    FooterResize: 200,
    SignaturePaddingTO: "Left",
    SignaturePaddingTop: 0,
    SignaturePaddingBottom: 0,
    SignaturePaddingLeft: 0,
    SignaturePaddingRight: 0,
    TextSignaturePaddingTop: 0,
    TextSignaturePaddingBottom: 0,
    TextSignaturePaddingLeft: 0,
    TextSignaturePaddingRight: 0,
    ImagePaddingTO: "Top",
    ImagePaddingTop: 0,
    ImagePaddingBottom: 0,
    ImagePaddingLeft: 0,
    ImagePaddingRight: 0,
    ImageHeight: 0,
    ImageWidth: 0,
    canvasPageItems: [],
    ApplyPageType: "P",
  }),
  mounted() {
    this.height = window.innerHeight - 50;
  },
  mixins: [FormPDFTemplate],
  watch: {
    async ApplyPageType(val) {
      if (val == "P") {
        this.overlay = true;
        for (let i = 0; i < this.pageItems.length; i++) {
          this.pageItems[i].page_type = "P";
        }
        await this.formPDFMethod();
        await this.PageBuffer();
        this.overlay = false;
      } else if (val == "L") {
        this.overlay = true;
        for (let i = 0; i < this.pageItems.length; i++) {
          this.pageItems[i].page_type = "L";
        }
        await this.formPDFMethod();
        await this.PageBuffer();
        this.overlay = false;
      }
    },
    currentPage(val) {
      this.SignaturePaddingTop =
        this.pageItems[val - 1].templateItems[6].paddingTop;
      this.SignaturePaddingLeft =
        this.pageItems[val - 1].templateItems[6].paddingLeft;
      this.TextSignaturePaddingTop =
        this.pageItems[val - 1].templateItems[6].TextpaddingTop;
      this.TextSignaturePaddingLeft =
        this.pageItems[val - 1].templateItems[6].TextpaddingLeft;
      if (this.ImagePaddingTO == "Top") {
        this.ImagePaddingTop =
          this.pageItems[val - 1].templateItems[3].paddingTop;
        this.ImagePaddingLeft =
          this.pageItems[val - 1].templateItems[3].paddingLeft;
        this.ImageHeight = this.pageItems[val - 1].templateItems[3].ImageHeight;
        this.ImageWidth = this.pageItems[val - 1].templateItems[3].ImageWidth;
      } else if (this.ImagePaddingTO == "Bottom") {
        this.ImagePaddingTop =
          this.pageItems[val - 1].templateItems[5].paddingTop;
        this.ImagePaddingLeft =
          this.pageItems[val - 1].templateItems[3].paddingLeft;
        this.ImageHeight = this.pageItems[val - 1].templateItems[5].ImageHeight;
        this.ImageWidth = this.pageItems[val - 1].templateItems[5].ImageWidth;
      }
    },
    dialogTemplatePreview(val) {
      if (val == true) {
        this.overlay = true;
        this.ApplyPageType = this.pageItems[0].page_type;
        this.ContentPaddingTop = this.pageItems[0].templateItems[4].paddingTop;
        this.ContentPaddingLeft =
          this.pageItems[0].templateItems[4].paddingLeft;
        this.ContentPaddingRight =
          this.pageItems[0].templateItems[4].paddingRight;
        setTimeout(() => this.PageBuffer(), 1000);
      }
    },
    HeaderPaddingTO(val) {
      if (val == "Left") {
        this.HeaderPaddingTop = this.pageItems[0].templateItems[0].paddingTop;
        this.HeaderPaddingLeft = this.pageItems[0].templateItems[0].paddingLeft;
        this.HeaderResize = this.pageItems[0].templateItems[0].resize;
      } else if (val == "Middle") {
        this.HeaderPaddingTop = this.pageItems[0].templateItems[1].paddingTop;
        this.HeaderPaddingLeft = this.pageItems[0].templateItems[1].paddingLeft;
        this.HeaderResize = this.pageItems[0].templateItems[1].resize;
      } else if (val == "Right") {
        this.HeaderPaddingTop = this.pageItems[0].templateItems[2].paddingTop;
        this.HeaderPaddingLeft = this.pageItems[0].templateItems[2].paddingLeft;
        this.HeaderResize = this.pageItems[0].templateItems[2].resize;
      }
    },
    FooterPaddingTO(val) {
      if (val == "Left") {
        this.FooterPaddingTop = this.pageItems[0].templateItems[7].paddingTop;
        this.FooterPaddingLeft = this.pageItems[0].templateItems[7].paddingLeft;
        this.FooterResize = this.pageItems[0].templateItems[7].resize;
      } else if (val == "Middle") {
        this.FooterPaddingTop = this.pageItems[0].templateItems[8].paddingTop;
        this.FooterPaddingLeft = this.pageItems[0].templateItems[8].paddingLeft;
        this.FooterPaddingRight =
          this.pageItems[0].templateItems[8].paddingRight;
        this.FooterResize = this.pageItems[0].templateItems[8].resize;
      } else if (val == "Right") {
        this.FooterPaddingTop = this.pageItems[0].templateItems[9].paddingTop;
        this.FooterPaddingRight =
          this.pageItems[0].templateItems[9].paddingRight;
        this.FooterResize = this.pageItems[0].templateItems[9].resize;
      }
    },
    ImagePaddingTO(val) {
      if (val == "Top") {
        this.ImagePaddingTop =
          this.pageItems[this.currentPage - 1].templateItems[3].paddingTop;
        this.ImagePaddingLeft =
          this.pageItems[this.currentPage - 1].templateItems[3].paddingLeft;
        this.ImageHeight =
          this.pageItems[this.currentPage - 1].templateItems[3].ImageHeight;
        this.ImageWidth =
          this.pageItems[this.currentPage - 1].templateItems[3].ImageWidth;
      } else if (val == "Bottom") {
        this.ImagePaddingTop =
          this.pageItems[this.currentPage - 1].templateItems[5].paddingTop;
        this.ImagePaddingLeft =
          this.pageItems[this.currentPage - 1].templateItems[5].paddingLeft;
        this.ImageHeight =
          this.pageItems[this.currentPage - 1].templateItems[5].ImageHeight;
        this.ImageWidth =
          this.pageItems[this.currentPage - 1].templateItems[5].ImageWidth;
      }
    },
  },
  methods: {
    ContentPadding(what, where) {
      switch (what) {
        case "+":
          if (where == "Left") {
            this.ContentPaddingLeft++;
            for (let i = 0; i < this.pageItems.length; i++) {
              if (document.getElementById(`Content${i + 1}`) != null) {
                document.getElementById(`Content${i + 1}`).style.paddingLeft =
                  this.ContentPaddingLeft + "px";
                this.pageItems[i].templateItems[4].paddingLeft =
                  this.ContentPaddingLeft;
              }
            }
          } else if (where == "Right") {
            this.ContentPaddingRight++;
            for (let i = 0; i < this.pageItems.length; i++) {
              if (document.getElementById(`Content${i + 1}`) != null) {
                document.getElementById(`Content${i + 1}`).style.paddingRight =
                  this.ContentPaddingRight + "px";
                this.pageItems[i].templateItems[4].paddingRight =
                  this.ContentPaddingRight;
              }
            }
          } else if (where == "Top") {
            this.ContentPaddingTop++;
            for (let i = 0; i < this.pageItems.length; i++) {
              if (document.getElementById(`Content${i + 1}`) != null) {
                document.getElementById(`Content${i + 1}`).style.paddingTop =
                  this.ContentPaddingTop + "px";
                this.pageItems[i].templateItems[4].paddingTop =
                  this.ContentPaddingTop;
              }
            }
          }
          break;
        case "-":
          if (where == "Left") {
            this.ContentPaddingLeft--;
            for (let i = 0; i < this.pageItems.length; i++) {
              if (document.getElementById(`Content${i + 1}`) != null) {
                document.getElementById(`Content${i + 1}`).style.paddingLeft =
                  this.ContentPaddingLeft + "px";
                this.pageItems[i].templateItems[4].paddingLeft =
                  this.ContentPaddingLeft;
              }
            }
          } else if (where == "Right") {
            this.ContentPaddingRight--;
            for (let i = 0; i < this.pageItems.length; i++) {
              if (document.getElementById(`Content${i + 1}`) != null) {
                document.getElementById(`Content${i + 1}`).style.paddingRight =
                  this.ContentPaddingRight + "px";
                this.pageItems[i].templateItems[4].paddingRight =
                  this.ContentPaddingRight;
              }
            }
          } else if (where == "Top") {
            this.ContentPaddingTop--;
            for (let i = 0; i < this.pageItems.length; i++) {
              if (document.getElementById(`Content${i + 1}`) != null) {
                document.getElementById(`Content${i + 1}`).style.paddingTop =
                  this.ContentPaddingTop + "px";
                this.pageItems[i].templateItems[4].paddingTop =
                  this.ContentPaddingTop;
              }
            }
          }
          break;
      }
    },
    HeaderPadding(what, where) {
      if (this.HeaderPaddingTO == "Left") {
        switch (what) {
          case "+":
            if (where == "Left") {
              this.HeaderPaddingLeft++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderLeft${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderLeft${i + 1}`
                  ).style.paddingLeft = this.HeaderPaddingLeft + "px";
                  this.pageItems[i].templateItems[0].paddingLeft =
                    this.HeaderPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.HeaderPaddingRight++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderLeft${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderLeft${i + 1}`
                  ).style.paddingRight = this.HeaderPaddingRight + "px";
                  this.pageItems[i].templateItems[0].paddingRight =
                    this.HeaderPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.HeaderPaddingTop++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderLeft${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderLeft${i + 1}`
                  ).style.paddingTop = this.HeaderPaddingTop + "px";
                  this.pageItems[i].templateItems[0].paddingTop =
                    this.HeaderPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.HeaderPaddingBottom++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderLeft${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderLeft${i + 1}`
                  ).style.paddingBottom = this.HeaderPaddingBottom + "px";
                  this.pageItems[i].templateItems[0].paddingBottom =
                    this.HeaderPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.HeaderResize++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`HeaderLeftImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `HeaderLeftImage${i + 1}`
                  ).style.width = this.HeaderResize + "px";
                  this.pageItems[i].templateItems[0].resize = this.HeaderResize;
                }
              }
            }
            break;
          case "-":
            if (where == "Left") {
              this.HeaderPaddingLeft--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderLeft${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderLeft${i + 1}`
                  ).style.paddingLeft = this.HeaderPaddingLeft + "px";
                  this.pageItems[i].templateItems[0].paddingLeft =
                    this.HeaderPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.HeaderPaddingRight--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderLeft${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderLeft${i + 1}`
                  ).style.paddingRight = this.HeaderPaddingRight + "px";
                  this.pageItems[i].templateItems[0].paddingRight =
                    this.HeaderPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.HeaderPaddingTop--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderLeft${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderLeft${i + 1}`
                  ).style.paddingTop = this.HeaderPaddingTop + "px";
                  this.pageItems[i].templateItems[0].paddingTop =
                    this.HeaderPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.HeaderPaddingBottom--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderLeft${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderLeft${i + 1}`
                  ).style.paddingBottom = this.HeaderPaddingBottom + "px";
                  this.pageItems[i].templateItems[0].paddingBottom =
                    this.HeaderPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.HeaderResize--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`HeaderLeftImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `HeaderLeftImage${i + 1}`
                  ).style.width = this.HeaderResize + "px";
                  this.pageItems[i].templateItems[0].resize = this.HeaderResize;
                }
              }
            }
            break;
        }
      } else if (this.HeaderPaddingTO == "Middle") {
        switch (what) {
          case "+":
            if (where == "Left") {
              this.HeaderPaddingLeft++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderCenter${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderCenter${i + 1}`
                  ).style.paddingLeft = this.HeaderPaddingLeft + "px";
                  this.pageItems[i].templateItems[1].paddingLeft =
                    this.HeaderPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.HeaderPaddingRight++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderCenter${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderCenter${i + 1}`
                  ).style.paddingRight = this.HeaderPaddingRight + "px";
                  this.pageItems[i].templateItems[1].paddingRight =
                    this.HeaderPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.HeaderPaddingTop++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderCenter${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderCenter${i + 1}`
                  ).style.paddingTop = this.HeaderPaddingTop + "px";
                  this.pageItems[i].templateItems[1].paddingTop =
                    this.HeaderPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.HeaderPaddingBottom++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderCenter${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderCenter${i + 1}`
                  ).style.paddingBottom = this.HeaderPaddingBottom + "px";
                  this.pageItems[i].templateItems[1].paddingBottom =
                    this.HeaderPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.HeaderResize++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`HeaderCenterImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `HeaderCenterImage${i + 1}`
                  ).style.width = this.HeaderResize + "px";
                  this.pageItems[i].templateItems[1].resize = this.HeaderResize;
                }
              }
            }
            break;
          case "-":
            if (where == "Left") {
              this.HeaderPaddingLeft--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderCenter${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderCenter${i + 1}`
                  ).style.paddingLeft = this.HeaderPaddingLeft + "px";
                  this.pageItems[i].templateItems[1].paddingLeft =
                    this.HeaderPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.HeaderPaddingRight--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderCenter${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderCenter${i + 1}`
                  ).style.paddingRight = this.HeaderPaddingRight + "px";
                  this.pageItems[i].templateItems[1].paddingRight =
                    this.HeaderPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.HeaderPaddingTop--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderCenter${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderCenter${i + 1}`
                  ).style.paddingTop = this.HeaderPaddingTop + "px";
                  this.pageItems[i].templateItems[1].paddingTop =
                    this.HeaderPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.HeaderPaddingBottom--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderCenter${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderCenter${i + 1}`
                  ).style.paddingBottom = this.HeaderPaddingBottom + "px";
                  this.pageItems[i].templateItems[1].paddingBottom =
                    this.HeaderPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.HeaderResize--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`HeaderCenterImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `HeaderCenterImage${i + 1}`
                  ).style.width = this.HeaderResize + "px";
                  this.pageItems[i].templateItems[1].resize = this.HeaderResize;
                }
              }
            }
            break;
        }
      } else if (this.HeaderPaddingTO == "Right") {
        switch (what) {
          case "+":
            if (where == "Left") {
              this.HeaderPaddingLeft++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderRight${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderRight${i + 1}`
                  ).style.paddingLeft = this.HeaderPaddingLeft + "px";
                  this.pageItems[i].templateItems[2].paddingLeft =
                    this.HeaderPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.HeaderPaddingRight++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderRight${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderRight${i + 1}`
                  ).style.paddingRight = this.HeaderPaddingRight + "px";
                  this.pageItems[i].templateItems[2].paddingRight =
                    this.HeaderPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.HeaderPaddingTop++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderRight${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderRight${i + 1}`
                  ).style.paddingTop = this.HeaderPaddingTop + "px";
                  this.pageItems[i].templateItems[2].paddingTop =
                    this.HeaderPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.HeaderPaddingBottom++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderRight${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderRight${i + 1}`
                  ).style.paddingBottom = this.HeaderPaddingBottom + "px";
                  this.pageItems[i].templateItems[2].paddingBottom =
                    this.HeaderPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.HeaderResize++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`HeaderRightImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `HeaderRightImage${i + 1}`
                  ).style.width = this.HeaderResize + "px";
                  this.pageItems[i].templateItems[2].resize = this.HeaderResize;
                }
              }
            }
            break;
          case "-":
            if (where == "Left") {
              this.HeaderPaddingLeft--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderRight${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderRight${i + 1}`
                  ).style.paddingLeft = this.HeaderPaddingLeft + "px";
                  this.pageItems[i].templateItems[2].paddingLeft =
                    this.HeaderPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.HeaderPaddingRight--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderRight${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderRight${i + 1}`
                  ).style.paddingRight = this.HeaderPaddingRight + "px";
                  this.pageItems[i].templateItems[2].paddingRight =
                    this.HeaderPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.HeaderPaddingTop--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderRight${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderRight${i + 1}`
                  ).style.paddingTop = this.HeaderPaddingTop + "px";
                  this.pageItems[i].templateItems[2].paddingTop =
                    this.HeaderPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.HeaderPaddingBottom--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`HeaderRight${i + 1}`) != null) {
                  document.getElementById(
                    `HeaderRight${i + 1}`
                  ).style.paddingBottom = this.HeaderPaddingBottom + "px";
                  this.pageItems[i].templateItems[2].paddingBottom =
                    this.HeaderPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.HeaderResize--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`HeaderRightImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `HeaderRightImage${i + 1}`
                  ).style.width = this.HeaderResize + "px";
                  this.pageItems[i].templateItems[2].resize = this.HeaderResize;
                }
              }
            }
            break;
        }
      }
    },
    ImagePadding(what, where) {
      if (this.ImagePaddingTO == "Top") {
        switch (what) {
          case "+":
            if (where == "Left") {
              this.ImagePaddingLeft++;
              if (
                document.getElementById(`ImageTop${this.currentPage}`) != null
              ) {
                document.getElementById(
                  `ImageTop${this.currentPage}`
                ).style.paddingLeft = this.ImagePaddingLeft + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[3].paddingLeft = this.ImagePaddingLeft;
              }
            } else if (where == "Top") {
              this.ImagePaddingTop++;
              if (
                document.getElementById(`ImageTop${this.currentPage}`) != null
              ) {
                document.getElementById(
                  `ImageTop${this.currentPage}`
                ).style.paddingTop = this.ImagePaddingTop + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[3].paddingTop = this.ImagePaddingTop;
              }
            } else if (where == "Height") {
              this.ImageHeight++;
              if (
                document.getElementById(`innerImageTop${this.currentPage}`) !=
                null
              ) {
                document.getElementById(
                  `innerImageTop${this.currentPage}`
                ).style.height = this.ImageHeight + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[3].ImageHeight = this.ImageHeight;
              }
            } else if (where == "Width") {
              this.ImageWidth++;
              if (
                document.getElementById(`innerImageTop${this.currentPage}`) !=
                null
              ) {
                document.getElementById(
                  `innerImageTop${this.currentPage}`
                ).style.width = this.ImageWidth + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[3].ImageWidth = this.ImageWidth;
              }
            }
            break;
          case "-":
            if (where == "Left") {
              this.ImagePaddingLeft--;
              if (
                document.getElementById(`ImageTop${this.currentPage}`) != null
              ) {
                document.getElementById(
                  `ImageTop${this.currentPage}`
                ).style.paddingLeft = this.ImagePaddingLeft + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[3].paddingLeft = this.ImagePaddingLeft;
              }
            } else if (where == "Top") {
              this.ImagePaddingTop--;
              if (
                document.getElementById(`ImageTop${this.currentPage}`) != null
              ) {
                document.getElementById(
                  `ImageTop${this.currentPage}`
                ).style.paddingTop = this.ImagePaddingTop + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[3].paddingTop = this.ImagePaddingTop;
              }
            } else if (where == "Height") {
              this.ImageHeight--;
              if (
                document.getElementById(`innerImageTop${this.currentPage}`) !=
                null
              ) {
                document.getElementById(
                  `innerImageTop${this.currentPage}`
                ).style.height = this.ImageHeight + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[3].ImageHeight = this.ImageHeight;
                this.pageItems[this.currentPage - 1].templateItems[3].content =
                  document.getElementById(
                    `innerImageTop${this.currentPage}`
                  ).outerHTML;
              }
            } else if (where == "Width") {
              this.ImageWidth--;
              if (
                document.getElementById(`innerImageTop${this.currentPage}`) !=
                null
              ) {
                document.getElementById(
                  `innerImageTop${this.currentPage}`
                ).style.width = this.ImageWidth + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[3].ImageWidth = this.ImageWidth;
                this.pageItems[this.currentPage - 1].templateItems[3].content =
                  document.getElementById(
                    `innerImageTop${this.currentPage}`
                  ).outerHTML;
              }
            }
            break;
        }
      } else if (this.ImagePaddingTO == "Bottom") {
        switch (what) {
          case "+":
            if (where == "Left") {
              this.ImagePaddingLeft++;
              if (
                document.getElementById(`ImageBottom${this.currentPage}`) !=
                null
              ) {
                document.getElementById(
                  `ImageBottom${this.currentPage}`
                ).style.paddingLeft = this.ImagePaddingLeft + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[5].paddingLeft = this.ImagePaddingLeft;
              }
            } else if (where == "Top") {
              this.ImagePaddingTop++;
              if (
                document.getElementById(`ImageBottom${this.currentPage}`) !=
                null
              ) {
                document.getElementById(
                  `ImageBottom${this.currentPage}`
                ).style.paddingTop = this.ImagePaddingTop + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[5].paddingTop = this.ImagePaddingTop;
              }
            } else if (where == "Height") {
              this.ImageHeight++;
              if (
                document.getElementById(
                  `innerImageBottom${this.currentPage}`
                ) != null
              ) {
                document.getElementById(
                  `innerImageBottom${this.currentPage}`
                ).style.height = this.ImageHeight + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[5].ImageHeight = this.ImageHeight;

                this.pageItems[this.currentPage - 1].templateItems[5].content =
                  document.getElementById(
                    `innerImageBottom${this.currentPage}`
                  ).outerHTML;
              }
            } else if (where == "Width") {
              this.ImageWidth++;
              if (
                document.getElementById(
                  `innerImageBottom${this.currentPage}`
                ) != null
              ) {
                document.getElementById(
                  `innerImageBottom${this.currentPage}`
                ).style.width = this.ImageWidth + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[5].ImageWidth = this.ImageWidth;
                this.pageItems[this.currentPage - 1].templateItems[5].content =
                  document.getElementById(
                    `innerImageBottom${this.currentPage}`
                  ).outerHTML;
              }
            }
            break;
          case "-":
            if (where == "Left") {
              this.ImagePaddingLeft--;
              if (
                document.getElementById(`ImageBottom${this.currentPage}`) !=
                null
              ) {
                document.getElementById(
                  `ImageBottom${this.currentPage}`
                ).style.paddingLeft = this.ImagePaddingLeft + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[5].paddingLeft = this.ImagePaddingLeft;
              }
            } else if (where == "Top") {
              this.ImagePaddingTop--;
              if (
                document.getElementById(`ImageBottom${this.currentPage}`) !=
                null
              ) {
                document.getElementById(
                  `ImageBottom${this.currentPage}`
                ).style.paddingTop = this.ImagePaddingTop + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[5].paddingTop = this.ImagePaddingTop;
              }
            } else if (where == "Height") {
              this.ImageHeight--;
              if (
                document.getElementById(
                  `innerImageBottom${this.currentPage}`
                ) != null
              ) {
                document.getElementById(
                  `innerImageBottom${this.currentPage}`
                ).style.height = this.ImageHeight + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[5].ImageHeight = this.ImageHeight;
              }
            } else if (where == "Width") {
              this.ImageWidth--;
              if (
                document.getElementById(
                  `innerImageBottom${this.currentPage}`
                ) != null
              ) {
                document.getElementById(
                  `innerImageBottom${this.currentPage}`
                ).style.width = this.ImageWidth + "px";
                this.pageItems[
                  this.currentPage - 1
                ].templateItems[5].ImageWidth = this.ImageWidth;
              }
            }
            break;
        }
      }
    },
    FooterPadding(what, where) {
      if (this.FooterPaddingTO == "Left") {
        switch (what) {
          case "+":
            if (where == "Left") {
              this.FooterPaddingLeft++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterLeft${i + 1}`) != null) {
                  document.getElementById(
                    `FooterLeft${i + 1}`
                  ).style.paddingLeft = this.FooterPaddingLeft + "px";
                  this.pageItems[i].templateItems[7].paddingLeft =
                    this.FooterPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.FooterPaddingRight++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterLeft${i + 1}`) != null) {
                  document.getElementById(
                    `FooterLeft${i + 1}`
                  ).style.paddingRight = this.FooterPaddingRight + "px";
                  this.pageItems[i].templateItems[7].paddingRight =
                    this.FooterPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.FooterPaddingTop++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterLeft${i + 1}`) != null) {
                  document.getElementById(
                    `FooterLeft${i + 1}`
                  ).style.paddingTop = this.FooterPaddingTop + "px";
                  this.pageItems[i].templateItems[7].paddingTop =
                    this.FooterPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.FooterPaddingBottom++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterLeft${i + 1}`) != null) {
                  document.getElementById(
                    `FooterLeft${i + 1}`
                  ).style.paddingBottom = this.FooterPaddingBottom + "px";
                  this.pageItems[i].templateItems[7].paddingBottom =
                    this.FooterPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.FooterResize++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`FooterLeftImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `FooterLeftImage${i + 1}`
                  ).style.width = this.FooterResize + "px";
                  this.pageItems[i].templateItems[7].resize = this.FooterResize;
                }
              }
            }
            break;
          case "-":
            if (where == "Left") {
              this.FooterPaddingLeft--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterLeft${i + 1}`) != null) {
                  document.getElementById(
                    `FooterLeft${i + 1}`
                  ).style.paddingLeft = this.FooterPaddingLeft + "px";
                  this.pageItems[i].templateItems[7].paddingLeft =
                    this.FooterPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.FooterPaddingRight--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterLeft${i + 1}`) != null) {
                  document.getElementById(
                    `FooterLeft${i + 1}`
                  ).style.paddingRight = this.FooterPaddingRight + "px";
                  this.pageItems[i].templateItems[7].paddingRight =
                    this.FooterPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.FooterPaddingTop--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterLeft${i + 1}`) != null) {
                  document.getElementById(
                    `FooterLeft${i + 1}`
                  ).style.paddingTop = this.FooterPaddingTop + "px";
                  this.pageItems[i].templateItems[7].paddingTop =
                    this.FooterPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.FooterPaddingBottom--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterLeft${i + 1}`) != null) {
                  document.getElementById(
                    `FooterLeft${i + 1}`
                  ).style.paddingBottom = this.FooterPaddingBottom + "px";
                  this.pageItems[i].templateItems[7].paddingBottom =
                    this.FooterPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.FooterResize--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`FooterLeftImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `FooterLeftImage${i + 1}`
                  ).style.width = this.FooterResize + "px";
                  this.pageItems[i].templateItems[7].resize = this.FooterResize;
                }
              }
            }
            break;
        }
      } else if (this.FooterPaddingTO == "Middle") {
        switch (what) {
          case "+":
            if (where == "Left") {
              this.FooterPaddingLeft++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterCenter${i + 1}`) != null) {
                  document.getElementById(
                    `FooterCenter${i + 1}`
                  ).style.paddingLeft = this.FooterPaddingLeft + "px";
                  this.pageItems[i].templateItems[8].paddingLeft =
                    this.FooterPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.FooterPaddingRight++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterCenter${i + 1}`) != null) {
                  document.getElementById(
                    `FooterCenter${i + 1}`
                  ).style.paddingRight = this.FooterPaddingRight + "px";
                  this.pageItems[i].templateItems[8].paddingRight =
                    this.FooterPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.FooterPaddingTop++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterCenter${i + 1}`) != null) {
                  document.getElementById(
                    `FooterCenter${i + 1}`
                  ).style.paddingTop = this.FooterPaddingTop + "px";
                  this.pageItems[i].templateItems[8].paddingTop =
                    this.FooterPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.FooterPaddingBottom++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterCenter${i + 1}`) != null) {
                  document.getElementById(
                    `FooterCenter${i + 1}`
                  ).style.paddingBottom = this.FooterPaddingBottom + "px";
                  this.pageItems[i].templateItems[8].paddingBottom =
                    this.FooterPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.FooterResize++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`FooterMiddleImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `FooterMiddleImage${i + 1}`
                  ).style.width = this.FooterResize + "px";
                  this.pageItems[i].templateItems[8].resize = this.FooterResize;
                }
              }
            }
            break;
          case "-":
            if (where == "Left") {
              this.FooterPaddingLeft--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterCenter${i + 1}`) != null) {
                  document.getElementById(
                    `FooterCenter${i + 1}`
                  ).style.paddingLeft = this.FooterPaddingLeft + "px";
                  this.pageItems[i].templateItems[8].paddingLeft =
                    this.FooterPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.FooterPaddingRight--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterCenter${i + 1}`) != null) {
                  document.getElementById(
                    `FooterCenter${i + 1}`
                  ).style.paddingRight = this.FooterPaddingRight + "px";
                  this.pageItems[i].templateItems[8].paddingRight =
                    this.FooterPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.FooterPaddingTop--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterCenter${i + 1}`) != null) {
                  document.getElementById(
                    `FooterCenter${i + 1}`
                  ).style.paddingTop = this.FooterPaddingTop + "px";
                  this.pageItems[i].templateItems[8].paddingTop =
                    this.FooterPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.FooterPaddingBottom--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterCenter${i + 1}`) != null) {
                  document.getElementById(
                    `FooterCenter${i + 1}`
                  ).style.paddingBottom = this.FooterPaddingBottom + "px";
                  this.pageItems[i].templateItems[8].paddingBottom =
                    this.FooterPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.FooterResize--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`FooterMiddleImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `FooterMiddleImage${i + 1}`
                  ).style.width = this.FooterResize + "px";
                  this.pageItems[i].templateItems[8].resize = this.FooterResize;
                }
              }
            }
            break;
        }
      } else if (this.FooterPaddingTO == "Right") {
        switch (what) {
          case "+":
            if (where == "Left") {
              this.FooterPaddingLeft++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterRight${i + 1}`) != null) {
                  document.getElementById(
                    `FooterRight${i + 1}`
                  ).style.paddingLeft = this.FooterPaddingLeft + "px";
                  this.pageItems[i].templateItems[9].paddingLeft =
                    this.FooterPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.FooterPaddingRight++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterRight${i + 1}`) != null) {
                  document.getElementById(
                    `FooterRight${i + 1}`
                  ).style.paddingRight = this.FooterPaddingRight + "px";
                  this.pageItems[i].templateItems[9].paddingRight =
                    this.FooterPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.FooterPaddingTop++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterRight${i + 1}`) != null) {
                  document.getElementById(
                    `FooterRight${i + 1}`
                  ).style.paddingTop = this.FooterPaddingTop + "px";
                  this.pageItems[i].templateItems[9].paddingTop =
                    this.FooterPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.FooterPaddingBottom++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterRight${i + 1}`) != null) {
                  document.getElementById(
                    `FooterRight${i + 1}`
                  ).style.paddingBottom = this.FooterPaddingBottom + "px";
                  this.pageItems[i].templateItems[9].paddingBottom =
                    this.FooterPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.FooterResize++;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`FooterRightImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `FooterRightImage${i + 1}`
                  ).style.width = this.FooterResize + "px";
                  this.pageItems[i].templateItems[9].resize = this.FooterResize;
                }
              }
            }
            break;
          case "-":
            if (where == "Left") {
              this.FooterPaddingLeft--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterRight${i + 1}`) != null) {
                  document.getElementById(
                    `FooterRight${i + 1}`
                  ).style.paddingLeft = this.FooterPaddingLeft + "px";
                  this.pageItems[i].templateItems[9].paddingLeft =
                    this.FooterPaddingLeft;
                }
              }
            } else if (where == "Right") {
              this.FooterPaddingRight--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterRight${i + 1}`) != null) {
                  document.getElementById(
                    `FooterRight${i + 1}`
                  ).style.paddingRight = this.FooterPaddingRight + "px";
                  this.pageItems[i].templateItems[9].paddingRight =
                    this.FooterPaddingRight;
                }
              }
            } else if (where == "Top") {
              this.FooterPaddingTop--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterRight${i + 1}`) != null) {
                  document.getElementById(
                    `FooterRight${i + 1}`
                  ).style.paddingTop = this.FooterPaddingTop + "px";
                  this.pageItems[i].templateItems[9].paddingTop =
                    this.FooterPaddingTop;
                }
              }
            } else if (where == "Bottom") {
              this.FooterPaddingBottom--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (document.getElementById(`FooterRight${i + 1}`) != null) {
                  document.getElementById(
                    `FooterRight${i + 1}`
                  ).style.paddingBottom = this.FooterPaddingBottom + "px";
                  this.pageItems[i].templateItems[9].paddingBottom =
                    this.FooterPaddingBottom;
                }
              }
            } else if (where == "Resize") {
              this.FooterResize--;
              for (let i = 0; i < this.pageItems.length; i++) {
                if (
                  document.getElementById(`FooterRightImage${i + 1}`) != null
                ) {
                  document.getElementById(
                    `FooterRightImage${i + 1}`
                  ).style.width = this.FooterResize + "px";
                  this.pageItems[i].templateItems[9].resize = this.FooterResize;
                }
              }
            }
            break;
        }
      }
    },
    SignaturePadding(what, where) {
      switch (what) {
        case "+":
          if (where == "Left") {
            this.SignaturePaddingLeft++;
            if (
              document.getElementById(`Signature${this.currentPage}`) != null
            ) {
              document.getElementById(
                `Signature${this.currentPage}`
              ).style.paddingLeft = this.SignaturePaddingLeft + "px";
              this.pageItems[
                this.currentPage - 1
              ].templateItems[6].paddingLeft = this.SignaturePaddingLeft;
            }
          } else if (where == "Top") {
            this.SignaturePaddingTop++;
            if (
              document.getElementById(`Signature${this.currentPage}`) != null
            ) {
              document.getElementById(
                `Signature${this.currentPage}`
              ).style.paddingTop = this.SignaturePaddingTop + "px";
              this.pageItems[this.currentPage - 1].templateItems[6].paddingTop =
                this.SignaturePaddingTop;
            }
          }
          break;
        case "-":
          if (where == "Left") {
            this.SignaturePaddingLeft--;
            if (
              document.getElementById(`Signature${this.currentPage}`) != null
            ) {
              document.getElementById(
                `Signature${this.currentPage}`
              ).style.paddingLeft = this.SignaturePaddingLeft + "px";
              this.pageItems[
                this.currentPage - 1
              ].templateItems[6].paddingLeft = this.SignaturePaddingLeft;
            }
          } else if (where == "Top") {
            this.SignaturePaddingTop--;
            if (
              document.getElementById(`Signature${this.currentPage}`) != null
            ) {
              document.getElementById(
                `Signature${this.currentPage}`
              ).style.paddingTop = this.SignaturePaddingTop + "px";
              this.pageItems[this.currentPage - 1].templateItems[6].paddingTop =
                this.SignaturePaddingTop;
            }
          }
          break;
      }
    },
    TextSignaturePadding(what, where) {
      switch (what) {
        case "+":
          if (where == "Left") {
            this.TextSignaturePaddingLeft++;
            if (
              document.getElementById(`TextSignature${this.currentPage}`) !=
              null
            ) {
              document.getElementById(
                `TextSignature${this.currentPage}`
              ).style.paddingLeft = this.TextSignaturePaddingLeft + "px";
              this.pageItems[
                this.currentPage - 1
              ].templateItems[6].TextpaddingLeft =
                this.TextSignaturePaddingLeft;
            }
          } else if (where == "Top") {
            this.TextSignaturePaddingTop++;
            if (
              document.getElementById(`TextSignature${this.currentPage}`) !=
              null
            ) {
              document.getElementById(
                `TextSignature${this.currentPage}`
              ).style.paddingTop = this.TextSignaturePaddingTop + "px";
              this.pageItems[
                this.currentPage - 1
              ].templateItems[6].TextpaddingTop = this.TextSignaturePaddingTop;
            }
          }
          break;
        case "-":
          if (where == "Left") {
            this.TextSignaturePaddingLeft--;
            if (
              document.getElementById(`TextSignature${this.currentPage}`) !=
              null
            ) {
              document.getElementById(
                `TextSignature${this.currentPage}`
              ).style.paddingLeft = this.TextSignaturePaddingLeft + "px";
              this.pageItems[
                this.currentPage - 1
              ].templateItems[6].TextpaddingLeft =
                this.TextSignaturePaddingLeft;
            }
          } else if (where == "Top") {
            this.TextSignaturePaddingTop--;
            if (
              document.getElementById(`TextSignature${this.currentPage}`) !=
              null
            ) {
              document.getElementById(
                `TextSignature${this.currentPage}`
              ).style.paddingTop = this.TextSignaturePaddingTop + "px";
              this.pageItems[
                this.currentPage - 1
              ].templateItems[6].TextpaddingTop = this.TextSignaturePaddingTop;
            }
          }
          break;
      }
    },
    async PageBuffer() {
      this.overlay = true;
      for (let i = 0; i < this.pageItems.length; i++) {
        await this.formPDFMethod();
        // Step 1: Calculate Heights
        let TempHeight = 0;
        if (
          this.pageItems[i].templateItems[0].content_present == true ||
          this.pageItems[i].templateItems[1].content_present == true ||
          this.pageItems[i].templateItems[2].content_present == true
        ) {
          TempHeight = Math.max(
            document.getElementById("HeaderCenter" + (i + 1)) != undefined
              ? document.getElementById("HeaderCenter" + (i + 1)).offsetHeight +
                  3
              : 0,
            document.getElementById("HeaderLeft" + (i + 1)) != undefined
              ? document.getElementById("HeaderLeft" + (i + 1)).offsetHeight + 3
              : 0,
            document.getElementById("HeaderRight" + (i + 1)) != undefined
              ? document.getElementById("HeaderRight" + (i + 1)).offsetHeight +
                  3
              : 0
          );

          this.pageItems[i].templateItems[4].Top = TempHeight;
        } else {
          this.pageItems[i].templateItems[4].Top = TempHeight;
        }

        this.pageItems[i].content_height =
          (this.pageItems[i].page_type == "P" ? 1000 : 730) - TempHeight;
        // Step 2: Get The Content & Do Aligment
        if (
          this.pageItems[i].templateItems[4].content_present == true &&
          document.getElementById(`Content${this.pageItems[i].page_number}`) !=
            null &&
          document.getElementById(`Content${this.pageItems[i].page_number}`)
            .offsetHeight > this.pageItems[i].content_height
        ) {
          let NodesArray = document.getElementById(
            `Content${this.pageItems[i].page_number}`
          ).childNodes;

          let AllNodeHeight = [];
          for (let j = 0; j < NodesArray.length; j++) {
            AllNodeHeight.push(
              NodesArray[j].offsetHeight != undefined
                ? NodesArray[j].offsetHeight
                : 0
            );
          }

          let tillIdx = 0;
          let tillHeight = 0;
          for (let k = 0; k < AllNodeHeight.length; k++) {
            if (tillHeight < this.pageItems[i].content_height) {
              tillIdx += 1;
              tillHeight += AllNodeHeight[k] + 16;
            }
          }
          tillHeight = tillHeight - AllNodeHeight[tillIdx - 1];
          tillIdx = tillIdx - 1;

          // Initalial to CurrNodes Index & Next Nodes
          this.pageItems[i].currNodes = this.toArray(NodesArray).slice(
            0,
            tillIdx
          );
          this.pageItems[i].nextNodes = this.toArray(NodesArray).slice(
            tillIdx,
            NodesArray.length
          );

          // Add nextNodes to Next Page OR New page
          if (
            this.pageItems[i + 1] != undefined ||
            this.pageItems[i + 1] != null
          ) {
            this.pageItems[i + 1].currNodes = this.pageItems[
              i
            ].nextNodes.concat(this.pageItems[i + 1].currNodes);
          } else if (this.pageItems[i].nextNodes.length > 1) {
            this.pageItems.push({
              page_number: this.pageItems.length + 1,
              page_type: this.pageItems[0].page_type == "P" ? "P" : "L",
              templateItems: [
                this.pageItems[0].templateItems[0],
                this.pageItems[0].templateItems[1],
                this.pageItems[0].templateItems[2],
                {
                  type: "IMAGE",
                  position: "TOP",
                  columns: "12",
                  align: "center",
                  label: "Image",
                  height: "50px",
                  color: "",
                  content_present: false,
                  content_type: "IMAGE",
                  content: "",
                  paddingTop: 100,
                  paddingBottom: 0,
                  paddingLeft: 250,
                  paddingRight: 0,
                  ImageHeight: 80,
                  ImageWidth: 200,
                },
                {
                  type: "MAIN CONTENT",
                  position: "TOP",
                  columns: "12",
                  align: "center",
                  label: "Main Content",
                  height: "200px",
                  color: "#e5f6ed",
                  content_present: true,
                  content_type: "TEXT",
                  content: "<div></div>",
                  Top: this.pageItems[i].templateItems[4].Top,
                  paddingTop: this.pageItems[i].templateItems[4].paddingTop,
                  paddingLeft: this.pageItems[i].templateItems[4].paddingLeft,
                  paddingRight: this.pageItems[i].templateItems[4].paddingRight,
                },
                {
                  type: "IMAGE",
                  position: "BOTTOM",
                  columns: "12",
                  align: "center",
                  label: "Image",
                  height: "50px",
                  color: "",
                  content_present: false,
                  content_type: "IMAGE",
                  content: "",
                  paddingTop: 800,
                  paddingBottom: 0,
                  paddingLeft: 250,
                  paddingRight: 0,
                  ImageHeight: 80,
                  ImageWidth: 200,
                },
                {
                  type: "SIGNATURE",
                  position: "",
                  columns: "12",
                  align: "center",
                  label: "Signature",
                  height: "75px",
                  color: "",
                  content_present: false,
                  content_type: "SIGNATURE",
                  content: "",
                  paddingTop: 700,
                  paddingLeft: 300,
                  TextpaddingTop: 800,
                  TextpaddingLeft: 280,
                },
                this.pageItems[0].templateItems[7],
                this.pageItems[0].templateItems[8],
                this.pageItems[0].templateItems[9],
                this.pageItems[0].templateItems[10],
                this.pageItems[0].templateItems[11],
              ],
              template_html: "",
              content_height: 0,
              currNodes: this.pageItems[i].nextNodes,
              nextNodes: [],
            });
          }
          await this.formPDFMethod();
          this.formPageData();
        }
        // BackGround Image Adding------------------------------------------------
        if (this.pageItems[i].templateItems[11].imgSrc != "") {
          document.getElementById(`PrintPage${i + 1}`).style.backgroundSize =
            this.pageItems[i].page_type == "P"
              ? "794px 1123px"
              : "1123px 794px";
          document.getElementById(
            `PrintPage${i + 1}`
          ).style.backgroundImage = `url(${this.pageItems[i].templateItems[11].imgSrc})`;
        }
        // BackGround Image Removing------------------------------------------------
        if (this.pageItems[i].templateItems[11].imgSrc == "") {
          document.getElementById(`PrintPage${i + 1}`).style.backgroundImage =
            null;
        }
      }
      // Remove Border -------------------------------------------------------------
      // if (this.enableBorder === false) {
      //   console.log("Remove Border", this.enableBorder);
      //   for (let m = 0; m < this.pageItems.length; m++) {
      //     document.getElementById(`innerPage${m + 1}`).style.border = "";
      //   }
      // }
      await this.formPDFMethod();
      this.overlay = false;
      console.warn("Final pageItems", this.pageItems);
    },
    toArray(nodeList) {
      return Array.from(nodeList);
    },
    formPageData() {
      for (let i = 0; i < this.pageItems.length; i++) {
        let appendDoc = document.getElementById(
          `Content${this.pageItems[i].page_number}`
        );

        // remove
        while (appendDoc.firstChild) {
          appendDoc.removeChild(appendDoc.lastChild);
        }
        // append
        let CurrNodesToBeAppended = Array.from(this.pageItems[i].currNodes);

        for (let j = 0; j < CurrNodesToBeAppended.length; j++) {
          if (CurrNodesToBeAppended[j] != null) {
            appendDoc.appendChild(CurrNodesToBeAppended[j]);
          }
        }
        this.pageItems[i].templateItems[4].content = appendDoc.innerHTML;
      }
    },
    handleScroll(e) {
      let pageBuffers = [];
      let eachPageHeigth = e.target.scrollHeight / this.pageItems.length;
      let currentScroll = e.target.scrollTop.toFixed();
      for (let i = 0; i < this.pageItems.length; i++) {
        pageBuffers.push(eachPageHeigth * (i + 1));
      }
      for (let j = 0; j < pageBuffers.length; j++) {
        if (
          currentScroll > pageBuffers[j] &&
          currentScroll < pageBuffers[j + 1]
        ) {
          this.currentPage = j + 2;
        } else if (currentScroll < pageBuffers[0]) {
          this.currentPage = 1;
        }
      }
      this.totalPage = this.pageItems.length;
    },

    async ViewPdfPreview() {
      try {
        this.overlay = true;
        await this.PageBuffer();
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(previewPDFTemplate, {
            input: {
              settings: JSON.stringify(this.pageItems),
              file_name:
                this.$store.getters.get_user_email.split("@")[0].toLowerCase() +
                ".pdf",
            },
          })
        );

        window.open(String(JSON.parse(result.data.PreviewPDFTemplate)));
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        console.log("error", error);
      }
    },
    // async downloadPDF() {
    //   this.canvasPageItems = [];
    //   let PrintPages = [];
    //   let myDocument = document.createElement("div");
    //   for (let i = 0; i < this.pageItems.length; i++) {
    //     PrintPages.push(document.getElementById(`PrintPage${i + 1}`));
    //   }
    //   for (let j = 0; j < PrintPages.length; j++) {
    //     await html2canvas(PrintPages[j], {
    //       scale: 4,
    //       useCORS: true,
    //     }).then(function (canvas) {
    //       myDocument.appendChild(canvas);
    //     });
    //   }
    //   for (let k = 0; k < myDocument.childNodes.length; k++) {
    //     this.canvasPageItems.push(myDocument.childNodes[k]);
    //   }
    // },
    // async generateMyPdfData() {
    //   this.overlay = true;
    //   await this.downloadPDF();
    //   this.generateMyPDF();
    // },
    // async generateMyPDF() {
    //   console.log("generateMyPDF Called!");
    //   var doc = new jsPDF("p", "pt", "a4", true);
    //   var width = doc.internal.pageSize.getWidth();
    //   var height = doc.internal.pageSize.getHeight();
    //   for (let i = 0; i < this.canvasPageItems.length; i++) {
    //     doc.addImage(
    //       this.canvasPageItems[i].toDataURL("image/png"),
    //       "PNG",
    //       0,
    //       0,
    //       width,
    //       height
    //     );
    //     // For not Adding Extra Page...
    //     if (i != this.canvasPageItems.length - 1) {
    //       doc.addPage();
    //     }
    //   }
    //   doc.save("test.pdf");
    //   this.overlay = false;
    //   this.$emit("generated");
    // },
    async templatePreviewDialogEmit() {
      await this.formPDFMethod();
      this.$emit("clicked", this.pageItems);
    },
  },
};
</script>
<style>
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.main_div {
  position: relative;
  height: 1000px;
  display: flow-root;
}
.sub_div {
  position: absolute;
  bottom: 0px;
}
.margin-top {
  margin-top: 150px;
}
.center {
  margin: auto;
  width: 50%;
}
.ql-font-monospace {
  font-family: "Courier New", monospace;
}
.ql-font-serif {
  font-family: Georgia, serif;
}
* {
  margin: 0;
  padding: 0;
}
.v-application p {
  margin-bottom: 0px;
}
</style>
