<template>
  <div>
    <Overlay :overlay="overlay" />
    <SignatureWarning :dialogSignatureWarning="dialogSignatureWarning" @clicked="dialogSignatureWarningEmit" />
    <PDFPreviewDialog :StoreObj="StoreObj" :PDFViewDialog="PDFViewDialog" :template_html="template_html" @clicked="PDFPreviewDialogEmit" />
    <IssueSingleOrBulkWarningDialog :StoreObj="StoreObj" :dialogSingleBulkIssueWarning="dialogSingleBulkIssueWarning" @clicked="dialogSingleBulkIssueWarningEmit" />
    <ValidInvalidDataDialog :dialogValidInvalidData="dialogValidInvalidData" :tableData="tableData" :tableHeader="tableHeader" :StoreObj="StoreObj" @clicked="dialogValidInvalidDataEmit" />
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0 pl-2 pt-2">
            <div class="largeFontSizeNew">Templates</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0 pr-2 pt-2">
            <v-spacer></v-spacer>
            <v-btn small class="borderRadius" color="primary" @click="ViewAllTemplatesEmit((Toggle = 2))"><v-icon>mdi-chevron-double-left</v-icon>Back</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          dense
          :headers="GetPortalPDFsListHeaders"
          :items="GetPortalTemplatesList.filter((item) => item.is_editable == true)"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
          }"
          :items-per-page="50"
          class="elevation-0"
          :no-data-text="noDataText"
          loading-text="Loading... Please wait"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.template_name`]="{ item }">
            <div class="FontSize">{{ item.template_name }}</div>
          </template>
          <template v-slot:[`item.template_created_on`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.template_created_on).toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.category_name`]="{ item }">
            <div class="FontSize">{{ item.category_name == undefined ? "-" : item.category_name }}</div>
          </template>
          <template v-slot:[`item.is_editabel`]="{ item }">
            <div class="FontSize">{{ item.is_editabel == true ? "Custom" : "SureCreds" }}</div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-card-actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small color="blue" v-bind="attrs" v-on="on" outlined @click="checkItem(item, 'PREVIEW')">Preview</v-btn>
                </template>
                <span align="center">Preview {{ item.template_name }}</span>
              </v-tooltip>
              <v-btn x-small outlined color="primary" class="ml-2" @click="checkItem(item, 'USETEMPLATE')">Use This Template</v-btn>
              <download-excel :data="checkData(item)" :fields="checkFields(item)">
                <v-btn x-small outlined color="primary" class="ml-2">Download Excel Template </v-btn>
              </download-excel>
            </v-card-actions>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllPortalPDFs } from "@/mixins/GetAllPortalPDFs.js";
import IssueSingleOrBulkWarningDialog from "@/components/Dashboard/Dialogs/IssueSingleOrBulkWarningDialog.vue";
import PDFPreviewDialog from "@/components/TemplateDesigner/Dialogs/PDFPreviewDialog.vue";
import SignatureWarning from "@/components/TemplateDesigner/Dialogs/SignatureWarning.vue";
import ValidInvalidDataDialog from "@/components/Dashboard/Dialogs/ValidInvalidDataDialog.vue";
import { FormPDFTemplate } from "@/mixins/FormPDFTemplate.js";
export default {
  mixins: [GetAllPortalPDFs, FormPDFTemplate],
  components: {
    Overlay,
    PDFPreviewDialog,
    SignatureWarning,
    IssueSingleOrBulkWarningDialog,
    ValidInvalidDataDialog,
  },
  data: () => ({
    page: 1,
    Toggle: 0,
    pageCount: 0,
    overlay: false,
    dialogValidInvalidData: false,
    dialogSingleBulkIssueWarning: false,
    GetPortalPDFsListHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "template_name",
      },
      { text: "Created On", value: "template_created_on" },
      { text: "Source", value: "is_editabel" },
      { text: "Actions", value: "Actions" },
    ],
    GetAllPortalPDFsList: [],
    PDFViewDialog: false,
    template_html: "",
    dialogSignatureWarning: false,
    StoreObj: {},
    templateItems: [],
    tableData: [],
    tableHeader: [],
    json_fields: {
      "Complete name": "name",
      City: "city",
      Telephone: "phone.mobile",
      "Telephone 2": {
        field: "phone.landline",
        callback: (value) => {
          return `Landline Phone - ${value}`;
        },
      },
    },
    json_data: [
      {
        name: "",
        city: "",
        country: "",
        birthdate: "",
        phone: {
          mobile: "",
          landline: "",
        },
      },
    ],
  }),
  watch: {},
  mounted() {
    this.GetAllPortalTemplatesMethod();
  },
  methods: {
    checkData(item){
      let json_data_object = {};
      let json_data_array = []
      for (let i = 0; i < item.template_fields.length; i++) {
        json_data_object[item.template_fields[i]] = "";
      }
      console.log("json_data", json_data_object);
      json_data_array.push(json_data_object)
      return json_data_array;
    },
    checkFields(item) {
      let json_fields = {};
      for (let i = 0; i < item.template_fields.length; i++) {
        json_fields[item.template_fields[i]] = item.template_fields[i];
      }
      console.log("json_fields", json_fields);
      return json_fields;
    },
    dialogValidInvalidDataEmit(Toggle) {
      this.dialogValidInvalidData = false;
      if (Toggle == 2) {
        this.ViewAllTemplatesEmit((Toggle = 3));
      }
    },
    dialogSingleBulkIssueWarningEmit(Toggle, tableData, tableHeader) {
      console.log("Toggle", Toggle);
      this.dialogSingleBulkIssueWarning = false;
      if (Toggle == 2) {
        this.templateItems = this.StoreObj.settings;
        this.formPDFMethod();
      } else if (Toggle == 3) {
        this.tableData = tableData;
        this.tableHeader = tableHeader;
        this.dialogValidInvalidData = true;
      }
    },
    dialogSignatureWarningEmit() {
      this.dialogSignatureWarning = false;
    },
    PDFPreviewDialogEmit() {
      this.PDFViewDialog = false;
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (this.StoreObj.settings.filter((item) => item.type == "SIGNATURE" && item.content_present == true).length == 0) {
        this.deciderMethod(item, action);
      } else {
        if (
          this.$store.getters.get_current_user_details.signature_url != undefined &&
          this.$store.getters.get_current_user_details.signature_url != null &&
          this.$store.getters.get_current_user_details.signature_url != ""
        ) {
          this.dialogSingleBulkIssueWarning = true;
        } else {
          this.dialogSignatureWarning = true;
        }
      }
    },
    deciderMethod(item, action) {
      if (action == "USETEMPLATE") {
        this.ViewAllTemplatesEmit((this.Toggle = 1), item);
      } else if (action == "PREVIEW") {
        this.PDFViewDialog = true;
        this.template_html = item.template_html;
      }
    },
    ViewAllTemplatesEmit(Toggle, item) {
      this.$emit("clicked", Toggle, item);
    },
  },
};
</script>
