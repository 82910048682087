<template>
  <v-dialog v-model="dialogAddSignature" persistent :max-width="'900px'">
    <v-card class="overflow-hidden">
      <v-toolbar dark dense color="primary" class="elevation-0">
        <div
          v-if="StoreObj.position != undefined && StoreObj.type != undefined"
        >
          Add
          {{ StoreObj.type.slice(0, 1) + StoreObj.type.slice(1).toLowerCase() }}
          to Page {{ fromPage }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="text-capitalize"
          dark
          outlined
          @click="clearSignature((Toggle = 1))"
        >
          Clear All
        </v-btn>
        <v-btn icon @click="dialogAddSignatureEmit((Toggle = 1))">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card class="elevation-0">
              <div class="font-weight-bold mt-4"></div>
              <!-- <v-radio-group
                label="Alignment"
                dense
                class="my-0 py-0"
                v-model="text_settings.position"
                row
              >
                <v-radio label="Left" value="LEFT"></v-radio>
                <v-radio label="Right" value="RIGHT"></v-radio>
              </v-radio-group> -->
              <div class="font-weight-bold">Configure Text</div>
              <v-checkbox
                dense
                class="px-0 mx-0 pb-0 mb-0"
                label="Add Text"
                v-model="text_settings.configure_text_before_signature"
                @change="configureTextMethod('BEFORESIGNATURE')"
              ></v-checkbox>
              <vue-editor
                class="editorHeightSM"
                :editorToolbar="customToolbar"
                :disabled="
                  text_settings.configure_text_before_signature == false
                "
                v-model="text_settings.text_content_before_signature"
                placeholder="Add Content"
              ></vue-editor>
              <!-- <v-checkbox
                dense
                class="px-0 mx-0 pt-12 pb-0 mb-0"
                label="Configure Text After Signature"
                v-model="text_settings.configure_text_after_signature"
                @change="configureTextMethod('AFTERSIGNATURE')"
              ></v-checkbox>
              <vue-editor
                class="editorHeightSM"
                :editorToolbar="customToolbar"
                :disabled="
                  text_settings.configure_text_after_signature == false
                "
                v-model="text_settings.text_content_after_signature"
                placeholder="Add Content"
              ></vue-editor> -->
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card outlined class="mt-2">
              <v-card-title class="py-0">Preview</v-card-title>
              <v-card-text class="py-0">
                <div class="mt-1" v-html="previewhtml"></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-capitalize"
          small
          color="primary"
          @click="addSignatureMethod"
        >
          <v-icon small>mdi-plus</v-icon>
          Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  props: {
    StoreObj: Object,
    dialogAddSignature: Boolean,
    GetAllMediaList: Array,
    fromPage: Number,
  },
  components: {
    VueEditor,
  },
  data: () => ({
    Toggle: 0,
    customToolbar: [
      // [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline"],
      [{ color: [] }],
    ],
    text_settings: {
      text_content: "",
      position: "LEFT",
      configure_text_before_signature: false,
      text_content_before_signature: "",
      configure_text_after_signature: false,
      text_content_after_signature: "",
    },
    previewhtml: "",
  }),
  watch: {
    "text_settings.text_content_before_signature"() {
      this.changeSettingsMethod("BEFORESIGNATURE");
    },
    "text_settings.text_content_after_signature"() {
      this.changeSettingsMethod("AFTERSIGNATURE");
    },
    dialogAddSignature(val) {
      if (val == true) {
        this.text_settings.position = "LEFT";
        this.previewhtml = `<img src="${this.$store.getters.get_current_user_details.signature_url}" style="width:250px;"/>`;
        this.text_settings.text_content = `<img src="${this.$store.getters.get_current_user_details.signature_url}" style="width:250px;"/>`;
        if (this.StoreObj.content_present == true) {
          this.text_settings = this.StoreObj.signature_Settings;
        }
      }
    },
  },
  methods: {
    configureTextMethod(Position) {
      switch (Position) {
        case "BEFORESIGNATURE":
          this.text_settings.text_content_before_signature = "";
          break;
        case "AFTERSIGNATURE":
          this.text_settings.text_content_after_signature = "";
          break;
      }
    },
    clearSignature() {
      this.text_settings = {
        text_content: "",
        position: "",
        configure_text_before_signature: false,
        text_content_before_signature: "",
        configure_text_after_signature: false,
        text_content_after_signature: "",
      };
      let addSignature = {};
      addSignature.position = "";
      addSignature.signatureSettings = this.text_settings;
      addSignature.content = "";
      addSignature.content_type = "SIGNATURE";
      this.dialogAddSignatureEmit((this.Toggle = 2), addSignature);
    },
    changeSettingsMethod() {
      this.previewhtml = `<img src="${this.$store.getters.get_current_user_details.signature_url}" style="width:250px;"/>`;
      if (this.text_settings.configure_text_before_signature == true) {
        this.previewhtml = this.previewhtml.concat(
          this.text_settings.text_content_before_signature
        );
        this.text_settings.text_content = this.previewhtml;
      }
      // if (this.text_settings.configure_text_after_signature == true) {
      //   this.previewhtml = this.previewhtml.concat(
      //     this.text_settings.text_content_after_signature
      //   );
      //   this.text_settings.text_content = this.previewhtml;
      // }
    },
    addSignatureMethod() {
      if (
        this.text_settings.text_content != undefined &&
        this.text_settings.text_content != null &&
        this.text_settings.text_content != ""
      ) {
        let addSignature = {};
        addSignature.position = this.text_settings.position;
        addSignature.signatureSettings = this.text_settings;
        addSignature.content = this.previewhtml;
        addSignature.content_type = "SIGNATURE";
        this.dialogAddSignatureEmit((this.Toggle = 2), addSignature);
      }
    },
    dialogAddSignatureEmit(Toggle, addSignature) {
      this.$emit("clicked", Toggle, addSignature, this.fromPage);
    },
  },
};
</script>
