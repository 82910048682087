<template>
  <v-app>
    <Overlay :overlay="overlay" />
    <v-app-bar :content-class="true" app clipped-left color="white" elevation="1">
      <v-app-bar-nav-icon v-if="RouterList.length != 0" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-card max-width="150px" class="elevation-0">
        <v-img src="@/assets/SureCredsLogo.png"></v-img>
      </v-card>
      <v-divider vertical class="ml-1"></v-divider>
      <!-- <v-card  v-if="account_logo_url == '' && user_type!='MEMBER'" class="ml-3" :class="clicked == true ? 'elevation-3' : 'elevation-1'" @click="handleUpload">
        <input ref="excel-upload-input" accept="image/*" class="excel-upload-input" type="file" @change="handleClick" />
        <v-img max-width="170px" src="@/assets/UploadBusinessLogo.png"></v-img>
      </v-card> -->
      <!-- <v-tooltip v-else-if="account_logo_url != '' && user_type!='MEMBER'" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card max-width="170px" v-bind="attrs" v-on="on" class="ml-3 elevation-0 overflow-hidden" @click="handleUpload">
            <input ref="excel-upload-input" accept="image/*" class="excel-upload-input" type="file" @change="handleClick" />
            <v-img :src="account_logo_url"></v-img>
          </v-card>
        </template>
        <span>Click To Update</span>
      </v-tooltip> -->
      <v-card max-width="150px" class="ml-3 elevation-0 overflow-hidden">
        <v-img height="50px" contain :src="account_logo_url"></v-img>
      </v-card>
      <v-spacer></v-spacer>
      <span class="">
        <div align="center" class="PrimaryFontColorSmall">{{ user_name }}</div>
        <div class="PrimaryFontColorXSmall">{{ $store.getters.get_user_email }}</div></span
      >
      <v-btn large icon color="primary" @click="$router.push('Home'), clearItem()"><v-icon>mdi-exit-to-app</v-icon></v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app clipped width="200px" color="#313131" dark>
      <v-list dark dense rounded>
        <v-list-item-group link dense rounded v-model="selectedItem" color="primary" active-class="primary" no-action>
          <v-list-item color="primary" v-for="(item, idx) in RouterList" :key="idx" @click="$route.name != item.MenuRoute && item.has_child_routes == false ? $router.push(item.MenuRoute) : ''">
            <v-list-item-icon class="pr-0 mr-0">
              <v-icon small color="white" v-text="item.MenuICon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="px-0 mx-0" :to="item.MenuRoute" link>
              <v-list-item-title class="white--text fontStyle" v-text="item.MenuName"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view :user_typeBI="user_type" />
    </v-main>
  </v-app>
</template>
<script>
var AWS = require("aws-sdk");
import Overlay from "@/components/Extras/Overlay.vue";
import RouterList from "@/JsonFiles/RouterList.json";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import { updateIssuerURLsAndAPIId } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  mixins: [GetCurrentUser],
  components: {
    Overlay,
  },
  data: () => ({
    clicked: false,
    drawer: true,
    model: null,
    overlay: false,
    RouterList: [],
    user_name: "",
    account_logo_url: "",
    selectedFile: null,
    selectedItem: 0,
  }),
  watch: {
    "$store.getters.get_current_user_details"(val) {
      this.user_name = val.user_name;
      this.user_type = val.user_type;
      this.account_logo_url =
        this.$store.getters.get_current_user_details.issuer_details.account_logo_url != undefined &&
        this.$store.getters.get_current_user_details.issuer_details.account_logo_url != null &&
        this.$store.getters.get_current_user_details.issuer_details.account_logo_url != ""
          ? this.$store.getters.get_current_user_details.issuer_details.account_logo_url
          : "";
      if (val.user_type == "MEMBER") {
        this.RouterList = RouterList.filter((item) => item.MenuName == "Dashboard" || item.MenuName == "My Profile");
      } else {
        if (val.issuer_details.enable_verification_requests == true) {
          this.RouterList = RouterList;
        } else {
          this.RouterList = RouterList.filter((item) => item.MenuName != "Verification Requests");
        }
      }
    },
  },
  mounted() {
    this.GetCurrentUser();
    this.account_logo_url =
      this.$store.getters.get_current_user_details.issuer_details.account_logo_url != undefined &&
      this.$store.getters.get_current_user_details.issuer_details.account_logo_url != null &&
      this.$store.getters.get_current_user_details.issuer_details.account_logo_url != ""
        ? this.$store.getters.get_current_user_details.issuer_details.account_logo_url
        : "";
  },
  methods: {
    async updateCurrentBusinessLogo(s3_URL) {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          update_type: "ACCOUNT_LOGO_URL",
          update_url: s3_URL,
        };
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(updateIssuerURLsAndAPIId, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuerURLsAndAPIId);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.GetCurrentUser();
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    checkImagePresent() {
      this.clicked = false;
      if (this.account_logo_url == null || this.account_logo_url == "" || this.account_logo_url == undefined) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.clicked = false;
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function () {
        self.uploadImageToS3();
      };
    },
    async uploadImageToS3() {
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
        secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
          Key: `${self.$store.getters.get_current_user_details.issuer_id}/account_logo/${new Date().getTime()}_${self.selectedFile.name}`,
          Body: buf,
          ContentType: self.selectedFile.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.loading = false;
          } else if (data) {
            var s3URL = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${encodeURI(param.Key)}`;
            self.updateCurrentBusinessLogo(s3URL);
          }
        });
      };
    },
    clearItem() {
      localStorage.removeItem("UserEmail");
    },
  },
};
</script>
