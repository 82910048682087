<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <IssueCredentialDialog :dialogIssueCredentials="dialogIssueCredentials" :StoreObj="StoreObj" :actionEnum="actionEnum" @clicked="IssueCredentialsEmit" />
    <v-card class="elevation-0" v-if="current_view == 'DASHBOARD'">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">Dashboard</div>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field dense outlined label="Search" class="FontSize field_label_size field_height borderRadius mt-6 mr-3" v-model="search" prepend-inner-icon="mdi-magnify"></v-text-field>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="borderRadius fontStyle text-capitalize" v-bind="attrs" v-on="on" small color="secondary"><v-icon small class="mr-1">mdi-plus</v-icon>Issue Credential</v-btn>
              </template>
              <v-list dense>
                <v-list-item dense v-for="(item, index) in items" :key="index" @click="checkMenuItem(item)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card outlined class="mx-4">
        <v-card-text>
          <v-row no-gutters>
            <h2 class="mr-4 font-weight-black">SureCreds Credits :</h2>
            <v-col cols="12" xs="12" sm="12" md="3">
              <h2 class="font-weight-black">
                <v-icon style="font-size: 25px" color="primary">mdi-wallet-plus</v-icon>
                Procured : {{ dashboard.procured }}
              </h2>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
              <h2 class="font-weight-black">
                <v-icon style="font-size: 25px" color="primary">mdi-history</v-icon>
                Consumed : {{ dashboard.consumed }}
              </h2>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
              <h2 class="font-weight-black">
                <v-icon style="font-size: 25px" color="primary">mdi-wallet</v-icon>
                Balance : {{ dashboard.balance }}
              </h2>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-text class="pt-5">
        <v-expand-x-transition>
          <v-card outlined v-show="dashboardTableExpand">
            <v-data-table
              :search="search"
              dense
              class="elevation-0"
              :headers="GetIssuedCredentialsListHeaders"
              :items="GetIssuedCredentialsList"
              :footer-props="{
                'items-per-page-options': [100, 200, 300, 400, 500],
              }"
              :items-per-page="100"
              :no-data-text="noDataText"
            >
              <template v-slot:[`item.sl_no`]="{ item }">
                <div class="FontSize">{{ GetIssuedCredentialsList.indexOf(item) + 1 }}</div>
              </template>
              <template v-slot:[`item.credential_number`]="{ item }">
                <div class="FontSize">
                  {{ item.credential_number }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom v-if="item.transaction_status != 'PENDING'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="primary" icon v-bind="attrs" v-on="on" class="mr-2" @click="downloadItem(item)"><v-icon>mdi-download</v-icon></v-btn>
                  </template>
                  <span>Download</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.transaction_status != 'PENDING'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="primary" icon v-bind="attrs" v-on="on" @click="openViewBlock(item)"><v-icon>mdi-forward</v-icon></v-btn>
                  </template>
                  <span class="text-center">View On viewblock.io</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.transacted_on`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="FontSize"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-text="
                        `${new Date(item.transacted_on).toDateString() + ',' + ' ' + new Date(item.transacted_on).toLocaleString().split(',')[1]}`.length > 10
                          ? `${new Date(item.transacted_on).toDateString() + ',' + ' ' + new Date(item.transacted_on).toLocaleString().split(',')[1]}`.slice(0, 10) + '..'
                          : `${new Date(item.transacted_on).toDateString() + ',' + ' ' + new Date(item.transacted_on).toLocaleString().split(',')[1]}`
                      "
                    ></div>
                  </template>
                  <span v-text="`${new Date(item.transacted_on).toDateString() + ',' + ' ' + new Date(item.transacted_on).toLocaleString().split(',')[1]}`" class="FontSize"></span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.mined_at`]="{ item }">
                <div v-if="item.mined_at == undefined">-</div>
                <v-tooltip bottom v-if="item.mined_at != undefined">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="FontSize"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-text="
                        `${new Date(item.mined_at).toDateString() + ',' + ' ' + new Date(item.mined_at).toLocaleString().split(',')[1]}`.length > 10
                          ? `${new Date(item.mined_at).toDateString() + ',' + ' ' + new Date(item.mined_at).toLocaleString().split(',')[1]}`.slice(0, 10) + '..'
                          : `${new Date(item.mined_at).toDateString() + ',' + ' ' + new Date(item.mined_at).toLocaleString().split(',')[1]}`
                      "
                    ></div>
                  </template>
                  <span v-text="`${new Date(item.mined_at).toDateString() + ',' + ' ' + new Date(item.mined_at).toLocaleString().split(',')[1]}`" class="FontSize"></span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.issued_by_user_name`]="{ item }">
                <div class="FontSize">{{ item.issued_by_user_name }}</div>
              </template>
              <template v-slot:[`item.category_name`]="{ item }">
                <div class="FontSize">{{ item.category_name != undefined ? item.category_name : "-" }}</div>
              </template>
              <template v-slot:[`item.issued_to`]="{ item }">
                <div class="FontSize">{{ item.issued_to }}</div>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <div class="FontSize" v-text="item.type == 'DOCUMENT' ? 'Document' : item.type == 'FREE_TEXT' ? 'Free Text' : item.type == 'TEMPLATE' ? 'Template' : '-'"></div>
              </template>
              <template v-slot:[`item.source`]="{ item }">
                <div class="FontSize" v-text="item.source == undefined ? '-' : item.source"></div>
              </template>
              <template v-slot:[`item.transaction_status`]="{ item }">
                <div class="FontSize" :class="item.transaction_status == 'ISSUED' ? 'GreenHeader' : item.transaction_status == 'DELAYED' ? 'RedHeader' : 'BlueHeader'">
                  {{ item.transaction_status }}
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-expand-x-transition>
      </v-card-text>
    </v-card>
    <ViewAllPDFTemplates v-if="current_view == 'VIEWALLTEMPLATES'" @clicked="ViewAllTemplatesEmit" />
    <FillPDFFields v-if="current_view == 'FILLFIELDS'" :StoreObj="StoreObj" @clicked="FillPDFFieldsEmit" />
  </div>
</template>
<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { GetAllIssuedCredentials } from "@/mixins/GetAllIssuedCredentials.js";
import IssueCredentialDialog from "@/components/Dashboard/Dialogs/IssueCredentialDialog.vue";
import ViewAllPDFTemplates from "@/components/Dashboard/Cards/ViewAllPDFTemplates.vue";
import FillPDFFields from "@/components/Dashboard/Cards/FillPDFFields.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
export default {
  components: {
    Overlay,
    Snackbar,
    IssueCredentialDialog,
    ViewAllPDFTemplates,
    FillPDFFields,
  },
  mixins: [GetAllIssuedCredentials, GetCurrentUser],
  data: () => ({
    dashboardTableExpand: false,
    search: null,
    dashboard: {
      procured: 0,
      consumed: 0,
      balance: 0,
    },
    current_view: "DASHBOARD",
    items: [
      { title: "With Document", value: "WITHDOCUMENT" },
      { title: "Free Text", value: "WITHOUTDOCUMENT" },
      { title: "Templatized", value: "TEMPLATIZED" },
    ],
    overlay: false,
    SnackBarComponent: {},
    GetIssuedCredentialsList: [],
    dialogDownloadCredentials: false,
    GetIssuedCredentialsListHeaders: [
      { text: "Transaction ID", value: "credential_number" },
      { text: "Created On", value: "transacted_on" },
      { text: "Issued By", value: "issued_by_user_name" },
      { text: "Issued To", value: "issued_to" },
      { text: "Source", value: "source" },
      { text: "Issued At", value: "mined_at" },
      { text: "Type", value: "type" },
      { text: "Status", value: "transaction_status" },
      { text: "Action", value: "actions" },
    ],
    StoreObj: {},
    dialogIssueCredentials: false,
    clearInterval: null,
    actionEnum: "",
    template_fields_array: [],
    Connection: null,
  }),
  watch: {
    getCurrentUserDetailsObject() {
      this.dashboard = {
        procured: this.$store.getters.get_current_user_details.issuer_details.available_credits + this.$store.getters.get_current_user_details.issuer_details.credits_issued,
        consumed: this.$store.getters.get_current_user_details.issuer_details.credits_issued,
        balance: this.$store.getters.get_current_user_details.issuer_details.available_credits,
      };
    },
  },
  mounted() {
    this.GetCurrentUser();
    this.WebSocketConnection();
    this.GetIssuedCredentialsMethod();
  },
  methods: {
    WebSocketConnection() {
      this.Connection = new WebSocket(`wss://oag87gu424.execute-api.ap-south-1.amazonaws.com/surecreds?issuer_id=${this.$store.getters.get_current_user_details.issuer_details.issuer_id}`);
      var self = this;
      (self.Connection.onopen = function () {
        console.warn("Check On Open");
      }),
        (self.Connection.onclose = function () {
          self.WebSocketConnection();
        }),
        (self.Connection.onmessage = function (event) {
          for (let i = 0; i < self.GetIssuedCredentialsList.length; i++) {
            if (JSON.parse(event.data).transaction_id == self.GetIssuedCredentialsList[i].transaction_id) {
              self.GetIssuedCredentialsList[i].transaction_status = "ISSUED";
              self.GetIssuedCredentialsList[i]["mined_at"] = JSON.parse(event.data).mined_at;
            }
            self.$forceUpdate();
          }
          var audio = new Audio(require("@/assets/CIS.mp3"));
          audio.play();
        });
    },
    FillPDFFieldsEmit(Toggle, ResultObject) {
      if (Toggle == 1) {
        this.current_view = "VIEWALLTEMPLATES";
      } else {
        this.current_view = "DASHBOARD";
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: ResultObject.Status_Message,
        };
        this.GetIssuedCredentialsMethod();
        this.dashboard = {
          procured: ResultObject.credits.available_credits + ResultObject.credits.credits_issued,
          consumed: ResultObject.credits.credits_issued,
          balance: ResultObject.credits.available_credits,
        };
      }
    },
    downloadItem(item) {
      window.open(item.URL);
    },
    IssueCredentialsEmit(Toggle, ResultObject) {
      this.dialogIssueCredentials = false;
      if (Toggle == 2) {
        this.GetIssuedCredentialsMethod();
        this.dashboard = {
          procured: ResultObject.credits.available_credits + ResultObject.credits.credits_issued,
          consumed: ResultObject.credits.credits_issued,
          balance: ResultObject.credits.available_credits,
        };
      }
    },
    FormViewEmit(Toggle) {
      if (Toggle == 1) {
        this.current_view = "VIEWALLTEMPLATES";
      } else if (Toggle == 2) {
        this.current_view = "DASHBOARD";
      } else if (Toggle == 3) {
        this.current_view = "DASHBOARD";
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Credential Prepared Successfully !!",
        };
        this.GetIssuedCredentialsMethod();
      }
    },
    ViewAllTemplatesEmit(Toggle, item) {
      if (Toggle == 1) {
        this.StoreObj = item;
        this.current_view = "FILLFIELDS";
      } else if (Toggle == 2) {
        this.current_view = "DASHBOARD";
      } else if (Toggle == 3) {
        this.current_view = "DASHBOARD";
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Credential Will Be Issued Shortly !!",
        };
      }
    },
    checkMenuItem(item) {
      if (this.available_credits != 0) {
        this.actionEnum = item.value;
        if (item.value != "TEMPLATIZED") {
          this.dialogIssueCredentials = true;
        } else {
          this.current_view = "VIEWALLTEMPLATES";
        }
      } else {
        this.dialogRecharge = true;
      }
    },
    checkInterval() {
      var self = this;
      self.clearInterval = setInterval(function () {
        self.GetIssuedCredentialsMethod();
      }, 60000);
    },
    openViewBlock(item) {
      window.open(`https://viewblock.io/arweave/tx/${item.transaction_id}`);
    },
    dialogDownloadCredentialsEmit() {
      this.dialogDownloadCredentials = false;
    },
  },
};
</script>
