var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticClass:"overflow-y-auto center",attrs:{"persistent":"","fullscreen":"","color":"#757575"},model:{value:(_vm.PDFViewDialog),callback:function ($$v) {_vm.PDFViewDialog=$$v},expression:"PDFViewDialog"}},[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('v-card',{staticClass:"overflow-y-auto"},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dark":"","color":"primary","dense":""}},[_vm._v(" PDF Preview "),_c('span',{staticClass:"ml-2"},[_vm._v(" ("+_vm._s(_vm.StoreObj.template_name)+")")]),_c('span',{staticStyle:{"font-size":"20px","position":"fixed","left":"50%","transform":"translateX(-50%)"}},[_vm._v(" Page "+_vm._s(_vm.currentPage)+"/"+_vm._s(_vm.totalPage)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.ViewPdfPreview()}}},[_c('v-icon',[_vm._v("mdi-tray-arrow-down")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.PDFPreviewDialogEmit((_vm.Toggle = 1))}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',{staticClass:"overflow-y-auto",attrs:{"elevation":"0","color":"#e5f6ed"}},[_c('v-virtual-scroll',{attrs:{"items":_vm.pageItems,"height":_vm.height,"item-height":_vm.pageItems[0] != undefined
              ? _vm.pageItems[0].page_type == 'P'
                ? 1150
                : 820
              : 0,"bench":100},on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
return [_c('v-card-text',{key:item.action,staticClass:"text-center"},[_c('v-card',{staticClass:"overflow-y-auto center elevation-2",staticStyle:{"padding-top":"1px"},attrs:{"tile":"","color":_vm.pageItems[0].templateItems[11].content,"id":("DisplayPage" + (item.page_number)),"height":item != undefined
                    ? item.page_type == 'P'
                      ? '1123'
                      : _vm.pageItems[0].templateItems[10].content_present == false
                      ? '796'
                      : '786'
                    : '0',"width":item != undefined
                    ? item.page_type == 'P'
                      ? '793'
                      : '1123'
                    : '0'}},[_c('div',{domProps:{"innerHTML":_vm._s(item.template_html)}})])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }