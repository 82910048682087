<template>
  <v-card class="elevation-0">
    <BackgroundImageDialog
      :dialogUploadImage="dialogUploadImage"
      :GetAllMediaList="GetAllMediaList"
      @clicked="closedialogUploadImage"
    />
    <div>
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="8">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">
              Design Template
              <span v-if="TemplateStoreObj.action == 'EDIT'"
                >( {{ TemplateStoreObj.template_name }} )</span
              >
            </div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn
              small
              outlined
              color="primary"
              class="borderRadius mr-2"
              @click="previewMethod()"
            >
              <v-icon small class="mr-1">mdi-eye</v-icon>Preview
            </v-btn>
            <v-btn
              :disabled="!enableSaveBtn"
              small
              outlined
              color="primary"
              class="borderRadius mr-2"
              @click="dialogSavePDFWarning = true"
            >
              <v-icon small class="mr-1">mdi-content-save</v-icon>Save
            </v-btn>
            <v-btn
              small
              color="primary"
              @click="designTemplateEmit((Toggle = 1))"
            >
              <v-icon>mdi-chevron-double-left</v-icon>
              Back
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
    </div>
    <div v-for="(pageItem, idx) in pageItems" :key="idx">
      <div :v-bind="pageItem" v-if="pageItem.page_number == currentPageItem">
        <v-card-text class="pt-0">
          <v-row no-gutters class="mt-1">
            <v-col cols="12" sm="12" xs="12" md="8">
              <v-row no-gutters>
                <v-col
                  v-for="(item, idx) in pageItem['templateItems']"
                  :key="idx"
                  cols="12"
                  sm="12"
                  :md="item.columns"
                  v-show="item.type != 'BORDER' && item.type != 'BACKGROUND'"
                >
                  <v-card
                    :disabled="
                      item.type == 'SIGNATURE' &&
                      ($store.getters.get_current_user_details.signature_url ==
                        '' ||
                        $store.getters.get_current_user_details.signature_url ==
                          undefined ||
                        $store.getters.get_current_user_details.signature_url ==
                          null)
                    "
                    :color="item.color"
                    class="elevation-0 borderRadiusZero"
                    :align="item.align"
                    :height="item.height"
                    @click="checkItem(item)"
                  >
                    <v-card-text class="pt-0">
                      <div
                        v-if="
                          (item.type == 'HEADER' || item.type == 'FOOTER') &&
                          currentPageItem != 1
                        "
                      >
                        <v-icon v-if="item.color != ''" small color="red"
                          >mdi-delete</v-icon
                        >
                        <v-icon v-else small color="primary"
                          >mdi-plus-circle</v-icon
                        >
                      </div>
                      <v-icon v-else small color="primary"
                        >mdi-plus-circle</v-icon
                      >
                      <div>
                        {{ item.label
                        }}<span v-if="item.content_present == false">
                          (<i>{{
                            item.type == "MAIN CONTENT"
                              ? "Mandatory"
                              : "Optional"
                          }}</i>
                          )</span
                        >
                      </div>
                      <div
                        v-if="
                          item.type == 'SIGNATURE' &&
                          ($store.getters.get_current_user_details
                            .signature_url == '' ||
                            $store.getters.get_current_user_details
                              .signature_url == undefined ||
                            $store.getters.get_current_user_details
                              .signature_url == null)
                        "
                        class="font-weight-bold red--text"
                      >
                        Please Upload Signature In My Profile Tab To Enable This
                        Section
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" xs="12" md="4">
              <v-card
                height="100%"
                fill-height
                class="elevation-0 borderRadiusZero"
              >
                <v-card-title class="pb-0">Stylings</v-card-title>
                <v-tabs dense v-model="tabSettings" grow>
                  <v-tab class="text-capitalize">Page</v-tab>
                  <v-tab class="text-capitalize">Border</v-tab>
                  <v-tab class="text-capitalize">Background</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabSettings">
                  <v-tab-item>
                    <v-select
                      label="Current Page"
                      class="mx-5 mt-4"
                      dense
                      outlined
                      :items="pageItems"
                      v-model="currentPageItem"
                      item-text="page_number"
                      item-value="page_number"
                    >
                      <template v-slot:selection="{ item }">
                        <span class="d-flex justify-center" style="width: 100%">
                          {{ item.page_number }}
                        </span>
                      </template>
                    </v-select>
                    <v-toolbar flat dense class="mt-n5">
                      <v-card-text align="center">
                        <v-btn
                          small
                          color="primary"
                          class="mr-5"
                          width="90"
                          @click="addPage"
                        >
                          <v-icon small class="mr-1">mdi-plus</v-icon>
                          Create
                        </v-btn>
                        <v-btn
                          small
                          color="primary"
                          width="90"
                          @click="deletePageDialog = true"
                          :disabled="pageItems.length == 1 ? true : false"
                        >
                          <v-icon small class="mr-1">mdi-delete</v-icon>
                          Remove
                        </v-btn>
                      </v-card-text>
                    </v-toolbar>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="0">
                      <v-card-text class="py-0 mt-n3">
                        <v-checkbox
                          dense
                          class="mx-0 px-0 mt-3"
                          v-model="enable_border"
                          label="Add Border"
                        ></v-checkbox>
                        <v-select
                          dense
                          outlined
                          v-if="enable_border == true"
                          class="field_height field_label_size FontSize mt-n3"
                          :rules="[(v) => !!v || 'Required']"
                          label="Border Size"
                          v-model="border_settings.border_size"
                          :items="borderSizeItems"
                        ></v-select>
                      </v-card-text>
                      <v-row no-gutters justify="center" class="mt-n3">
                        <v-color-picker
                          v-if="enable_border == true"
                          v-model="border_settings.border_color"
                          mode="hexa"
                          :hide-mode-switch="true"
                        ></v-color-picker>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card>
                      <v-row no-gutters justify="center" class="pt-2">
                        <v-color-picker
                          v-model="background"
                          mode="hexa"
                          :hide-mode-switch="true"
                        ></v-color-picker>
                      </v-row>
                      <v-row no-gutters justify="center" class="mt-n5">
                        <v-checkbox
                          dense
                          class="mx-0 px-0"
                          v-model="enable_background_image"
                          label="Add Background Image"
                        ></v-checkbox>
                      </v-row>
                      <v-row no-gutters justify="center" class="mt-n3">
                        <v-btn
                          :disabled="!enable_background_image"
                          small
                          @click="dialogUploadImage = true"
                          color="secondary"
                          class="borderRadius mb-3"
                          ><v-icon small class="mr-1">mdi-plus</v-icon>Add
                          BackGround Image</v-btn
                        >
                      </v-row>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <AddHeaderFooter
          :GetAllMediaList="GetAllMediaList"
          :StoreObj="StoreObj"
          :dialogAddHeaderFooter="dialogAddHeaderFooter"
          @clicked="allCommonEmit"
        />
        <AddImage
          :fromPage="pageItem.page_number"
          :GetAllMediaList="GetAllMediaList"
          :StoreObj="StoreObj"
          :dialogAddImage="dialogAddImage"
          @clicked="allCommonEmit"
        />
        <AddSignature
          :fromPage="pageItem.page_number"
          :GetAllMediaList="GetAllMediaList"
          :StoreObj="StoreObj"
          :dialogAddSignature="dialogAddSignature"
          @clicked="allCommonEmit"
        />
        <AddMainContent
          :fromPage="pageItem.page_number"
          :StoreObj="StoreObj"
          :pageItems="pageItems"
          :dialogAddText="dialogAddText"
          @clicked="allCommonEmit"
        />
        <SavePDFWarningDialog
          :TemplateStoreObj="TemplateStoreObj"
          :StoreObj="StoreObj"
          :pageItems="pageItems"
          :dialogSavePDFWarning="dialogSavePDFWarning"
          @clicked="dialogSavePDFWarningEmit"
        />
      </div>
    </div>
    <DelPageDialog
      :deletePageDialog="deletePageDialog"
      :fromPage="currentPageItem"
      @closeDialog="closeDeletePageDialog"
    />
    <TemplatePreviewDialog
      :TemplateStoreObj="TemplateStoreObj"
      :dialogTemplatePreview="dialogTemplatePreview"
      :pageItems="pageItems"
      :enableBorder="enable_border"
      @clicked="templatePreviewDialogEmit"
    />
  </v-card>
</template>
<script>
import BackgroundImageDialog from "@/components/TemplateDesigner/Dialogs/BackgroundImageDialog.vue";
import SavePDFWarningDialog from "@/components/TemplateDesigner/Dialogs/SavePDFWarningDialog.vue";
import AddHeaderFooter from "@/components/TemplateDesigner/Dialogs/AddHeaderFooter.vue";
import AddImage from "@/components/TemplateDesigner/Dialogs/AddImage.vue";
import AddSignature from "@/components/TemplateDesigner/Dialogs/AddSignature.vue";
import AddMainContent from "@/components/TemplateDesigner/Dialogs/AddMainContent.vue";
import TemplatePreviewDialog from "@/components/TemplateDesigner/Dialogs/TemplatePreviewDialog.vue";
import DelPageDialog from "@/components/TemplateDesigner/Dialogs/DelPageDialog.vue";

import { GetAllMedia } from "@/mixins/GetAllMedia.js";
import { FormPDFTemplate } from "@/mixins/FormPDFTemplate.js";

export default {
  components: {
    AddHeaderFooter,
    AddImage,
    AddSignature,
    AddMainContent,
    TemplatePreviewDialog,
    SavePDFWarningDialog,
    BackgroundImageDialog,
    DelPageDialog,
  },
  props: {
    TemplateStoreObj: Object,
    tempPageItems: Array,
  },
  mixins: [GetAllMedia, FormPDFTemplate],
  data: () => ({
    deletePageDialog: false,
    pageItems: [
      {
        page_number: 1,
        page_type: "P",
        templateItems: [
          {
            type: "HEADER",
            position: "LEFT",
            columns: "4",
            align: "left",
            label: "Left Header",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            resize: 200,
          },
          {
            type: "HEADER",
            position: "MIDDLE",
            columns: "4",
            align: "center",
            label: "Center Header",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 250,
            paddingRight: 0,
            resize: 200,
          },
          {
            type: "HEADER",
            position: "RIGHT",
            columns: "4",
            align: "right",
            label: "Right Header",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 500,
            paddingRight: 0,
            resize: 200,
          },
          {
            type: "IMAGE",
            position: "TOP",
            columns: "12",
            align: "center",
            label: "Image",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "IMAGE",
            content: "",
            paddingTop: 100,
            paddingBottom: 0,
            paddingLeft: 250,
            paddingRight: 0,
            ImageHeight: 80,
            ImageWidth: 200,
          },
          {
            type: "MAIN CONTENT",
            position: "TOP",
            columns: "12",
            align: "center",
            label: "Main Content",
            height: "200px",
            color: "",
            content_present: false,
            content_type: "TEXT",
            content: "",
            Top: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 20,
            paddingRight: 20,
          },
          {
            type: "IMAGE",
            position: "BOTTOM",
            columns: "12",
            align: "center",
            label: "Image",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "IMAGE",
            content: "",
            paddingTop: 700,
            paddingBottom: 0,
            paddingLeft: 250,
            paddingRight: 0,
            ImageHeight: 80,
            ImageWidth: 200,
          },
          {
            type: "SIGNATURE",
            position: "",
            columns: "12",
            align: "center",
            label: "Signature",
            height: "75px",
            color: "",
            content_present: false,
            content_type: "SIGNATURE",
            content: "",
            paddingTop: 700,
            paddingLeft: 300,
            TextpaddingTop: 800,
            TextpaddingLeft: 280,
          },
          {
            type: "FOOTER",
            position: "LEFT",
            columns: "4",
            align: "left",
            label: "Left Footer",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
            paddingTop: 950,
            paddingLeft: 0,
            resize: 200,
          },
          {
            type: "FOOTER",
            position: "MIDDLE",
            columns: "4",
            align: "center",
            label: "Center Footer",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
            paddingTop: 950,
            paddingLeft: 250,
            resize: 200,
          },
          {
            type: "FOOTER",
            position: "RIGHT",
            columns: "4",
            align: "right",
            label: " Right Footer",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
            paddingTop: 950,
            paddingLeft: 500,
            resize: 200,
          },
          {
            type: "BORDER",
            content_present: false,
            content_type: "BORDER",
            content: "",
          },
          {
            type: "BACKGROUND",
            content_type: "BACKGROUND",
            content: "#FFFFFF",
            imgSrc: "",
          },
        ],
        template_html: "",
        content_height: 0,
        currNodes: [],
        nextNodes: [],
      },
    ],
    enable_background_image: false,
    currentPageItem: 1,
    tabSettings: 0,
    dialogAddImage: false,
    dialogAddSignature: false,
    dialogAddHeaderFooter: false,
    dialogAddText: false,
    dialogTemplatePreview: false,
    StoreObj: {},
    GetAllMediaList: [],
    dialogSavePDFWarning: false,
    border_settings: {
      border_color: "#000000",
      border_size: "1px",
    },
    borderSizeItems: [
      "1px",
      "2px",
      "3px",
      "4px",
      "5px",
      "6px",
      "7px",
      "8px",
      "9px",
      "10px",
    ],
    settingTypeITEMS: ["BORDER", "BACKGROUND"],
    background: "#FFFFFFFF",
    enable_border: false,
    allActiveFields: [],
    dialogUploadImage: false,
    enableSaveBtn: false,
  }),
  watch: {
    enable_border(val) {
      console.log("enable_border Called!");
      if (val == true) {
        for (let i = 0; i < this.pageItems.length; i++) {
          this.pageItems[i].templateItems[10].content = "1px solid #000000";
          this.pageItems[i].templateItems[10].content_present = true;
        }
      }
      if (val == false) {
        for (let i = 0; i < this.pageItems.length; i++) {
          this.pageItems[i].templateItems[10].content = "";
          this.pageItems[i].templateItems[10].content_present = false;
        }
      }
      console.log("enable_border end!", this.pageItems);
    },
    allActiveFields(val) {
      console.warn("Check All Active Fields", val);
    },
    background(val) {
      if (val != undefined && val != null && val != "") {
        for (let i = 0; i < this.pageItems.length; i++) {
          for (let j = 0; j < this.pageItems[i].templateItems.length; j++) {
            if (this.pageItems[i].templateItems[j].type == "BACKGROUND") {
              this.pageItems[i].templateItems[j].content = this.background;
            }
          }
        }
      }
    },
    enable_background_image(val) {
      if (val == true) {
        for (let i = 0; i < this.pageItems.length; i++) {
          this.pageItems[i].templateItems[11].content = "transparent";
          this.background = "transparent";
        }
      } else if (val == false) {
        console.log(val);
        for (let i = 0; i < this.pageItems.length; i++) {
          this.pageItems[i].templateItems[11].content = "#FFFFFFFF";
          this.background = "#FFFFFFFF";
          this.pageItems[i].templateItems[11].imgSrc = "";
        }
      }
    },
    "border_settings.border_color"(val) {
      if (val != undefined && val != null && val != "") {
        for (let i = 0; i < this.pageItems.length; i++) {
          for (let j = 0; j < this.pageItems[i].templateItems.length; j++) {
            if (this.pageItems[i].templateItems[j].type == "BORDER") {
              this.pageItems[i].templateItems[
                j
              ].content = `${this.border_settings.border_size} solid ${this.border_settings.border_color}`;
            }
          }
        }
      }
    },
    "border_settings.border_size"(val) {
      if (val != undefined && val != null && val != "") {
        for (let i = 0; i < this.pageItems.length; i++) {
          for (let j = 0; j < this.pageItems[i].templateItems.length; j++) {
            if (this.pageItems[i].templateItems[j].type == "BORDER") {
              this.pageItems[i].templateItems[
                j
              ].content = `${this.border_settings.border_size} solid ${this.border_settings.border_color}`;
            }
          }
        }
      }
    },
  },
  mounted() {
    this.enableSaveBtn = false;
    console.log("Template Mounted!!", this.enableSaveBtn);
    if (this.TemplateStoreObj.action == "EDIT") {
      this.enableSaveBtn = true;
      this.pageItems = this.tempPageItems;
      // Add Border if Available
      this.enable_border = this.pageItems[0].templateItems[10].content_present;
      this.border_settings.border_color = String(
        this.pageItems[0].templateItems[10].content.split(" ")[2] != undefined
          ? this.pageItems[0].templateItems[10].content.split(" ")[2]
          : ""
      );
      this.border_settings.border_size = String(
        this.pageItems[0].templateItems[10].content.split(" ")[0] != undefined
          ? this.pageItems[0].templateItems[10].content.split(" ")[0]
          : ""
      );
      // Add Background Data if Available
      this.enable_background_image =
        this.pageItems[0].templateItems[11].imgSrc.length != 0 ? true : false;
      this.background = this.pageItems[0].templateItems[11].content;
      console.log(
        "enable_border",
        this.enable_border,
        this.border_settings,
        this.pageItems
      );
    }
    this.GetAllMediaMethod();
  },
  methods: {
    closedialogUploadImage(val, src) {
      if (val == true) {
        this.dialogUploadImage = false;
        for (let i = 0; i < this.pageItems.length; i++) {
          this.pageItems[i].templateItems[11].imgSrc = src;
        }
        console.warn(this.pageItems[0].templateItems[11].imgSrc);
      } else if (val == false) {
        this.dialogUploadImage = false;
      }
    },
    closeDeletePageDialog(val) {
      console.log("Designer Close Called!!!");
      this.deletePageDialog = false;
      if (val == true) {
        if (this.currentPageItem != 1) {
          this.pageItems.splice(this.currentPageItem - 1, 1);
          for (let i = 0; i < this.pageItems.length; i++) {
            this.pageItems[i].page_number = i + 1;
          }
          this.currentPageItem = 1;
          console.log("Items After Removal", this.pageItems);
        }
      }
      let PageItems = Array.from(this.pageItems);
      let existingActiveFieldArray = [];
      for (let k = 0; k < PageItems.length; k++) {
        let myLoop = PageItems[k].templateItems[4].content;
        if (myLoop != "") {
          for (let j = 0; j < myLoop.split("}").length; j++) {
            if (myLoop.split("}")[j].includes("{")) {
              if (myLoop.split("}")[j].split("{")[1] != "") {
                existingActiveFieldArray.push(
                  myLoop.split("}")[j].split("{")[1]
                );
              }
            }
          }
        }
      }
      console.log(this.existingActiveFieldArray);
      if (existingActiveFieldArray.length != 0) {
        this.enableSaveBtn = true;
      } else if (existingActiveFieldArray.length == 0) {
        this.enableSaveBtn = false;
      }
    },
    addPage() {
      this.pageItems.push({
        page_number: this.pageItems.length + 1,
        page_type: this.pageItems[0].page_type == "P" ? "P" : "L",
        templateItems: [
          this.pageItems[0].templateItems[0],
          this.pageItems[0].templateItems[1],
          this.pageItems[0].templateItems[2],
          {
            type: "IMAGE",
            position: "TOP",
            columns: "12",
            align: "center",
            label: "Image",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "IMAGE",
            content: "",
            paddingTop: 100,
            paddingBottom: 0,
            paddingLeft: 250,
            paddingRight: 0,
            ImageHeight: 80,
            ImageWidth: 200,
          },
          {
            type: "MAIN CONTENT",
            position: "TOP",
            columns: "12",
            align: "center",
            label: "Main Content",
            height: "200px",
            color: "",
            content_present: false,
            content_type: "TEXT",
            content: "",
            Top: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 20,
            paddingRight: 20,
          },
          {
            type: "IMAGE",
            position: "BOTTOM",
            columns: "12",
            align: "center",
            label: "Image",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "IMAGE",
            content: "",
            paddingTop: 800,
            paddingBottom: 0,
            paddingLeft: 250,
            paddingRight: 0,
            ImageHeight: 80,
            ImageWidth: 200,
          },
          {
            type: "SIGNATURE",
            position: "",
            columns: "12",
            align: "center",
            label: "Signature",
            height: "75px",
            color: "",
            content_present: false,
            content_type: "SIGNATURE",
            content: "",
            paddingTop: 700,
            paddingLeft: 300,
            TextpaddingTop: 800,
            TextpaddingLeft: 280,
          },
          this.pageItems[0].templateItems[7],
          this.pageItems[0].templateItems[8],
          this.pageItems[0].templateItems[9],
          this.pageItems[0].templateItems[10],
          this.pageItems[0].templateItems[11],
        ],
        template_html: "",
        content_height: 0,
        currNodes: [],
        nextNodes: [],
      });
    },
    calculateActiveFieldsMethod(content) {
      this.allActiveFields = [];
      for (let i = 0; i < content.split("}").length; i++) {
        if (content.split("}")[i].includes("{")) {
          if (content.split("}")[i].split("{")[1] != "") {
            this.allActiveFields.push(content.split("}")[i].split("{")[1]);
          }
        }
      }
    },
    updateActiveFields(allActiveFields) {
      console.warn("allActiveFields", this.allActiveFields);
      this.allActiveFields = allActiveFields;
    },
    async previewMethod() {
      await this.formPDFMethod();
      this.dialogTemplatePreview = true;
    },
    // enableBorderMethod() {
    //   console.log('enableBorderMethod Called!')
    //   if (this.enable_border == true) {
    //     for (let i = 0; i < this.pageItems.length; i++) {
    //       this.pageItems[i].templateItems[10].content = "1px solid #000000";
    //       this.pageItems[i].templateItems[10].content_present = true;
    //     }
    //   }
    //   if (this.enable_border == false) {
    //     for (let i = 0; i < this.templateItems.length; i++) {
    //       this.pageItems[i].templateItems[10].content = "";
    //       this.pageItems[i].templateItems[10].content_present = false;
    //     }
    //   }
    //   console.log("enableBorderMethod", this.pageItems);
    // },
    dialogSavePDFWarningEmit(Toggle, ResultObject) {
      this.dialogSavePDFWarning = false;
      if (Toggle == 2) {
        this.designTemplateEmit((Toggle = 2), ResultObject);
      }
    },
    templatePreviewDialogEmit(val) {
      this.dialogTemplatePreview = false;
      this.pageItems = val;
      console.log(this.pageItems);
    },
    allCommonEmit(Toggle, Object, fromPage) {
      console.log("Toggle", Toggle, "Object", Object, "fromPage", fromPage);
      console.warn("Object", Object);
      this.dialogAddHeaderFooter = false;
      this.dialogAddImage = false;
      this.dialogAddSignature = false;
      this.dialogAddText = false;
      if (Toggle == 2) {
        // ADD Header/Footer to the All the Pages
        console.log(this.StoreObj);
        if (this.StoreObj.type == "HEADER" || this.StoreObj.type == "FOOTER") {
          let replaceIdx = this.pageItems[0].templateItems.indexOf(
            this.StoreObj
          );
          console.log(replaceIdx, "replaceIdx");
          for (let i = 0; i < this.pageItems.length; i++) {
            console.log("this.StoreObj", this.StoreObj, "i", i);
            if (Object.content.length != 0) {
              this.pageItems[i].templateItems[replaceIdx].color = "#e5f6ed";
              this.pageItems[i].templateItems[replaceIdx].content =
                Object.content;
              this.pageItems[i].templateItems[replaceIdx].content_type =
                Object.content_type;
              this.pageItems[i].templateItems[
                replaceIdx
              ].content_present = true;
            } else {
              this.pageItems[i].templateItems[replaceIdx].color = "";
              this.pageItems[i].templateItems[replaceIdx].content = "";
              this.pageItems[i].templateItems[replaceIdx].content_type = "";
              this.pageItems[i].templateItems[
                replaceIdx
              ].content_present = false;
            }
          }
        }
        // ADD Image to Particular Page
        else if (this.StoreObj.type == "IMAGE") {
          console.log("Image Called!");
          for (let i = 0; i < this.pageItems.length; i++) {
            if (this.pageItems[i].page_number == fromPage) {
              if (Object.content.length != 0) {
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].color = "#e5f6ed";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content = Object.content;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_type = Object.content_type;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_present = true;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].position = Object.position;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].text_settings = Object.text_settings;
              } else {
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].color = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_type = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_present = false;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].text_settings = {};
              }
            }
          }
        }
        // ADD SIGNATURE to Particular Page
        else if (this.StoreObj.type == "SIGNATURE") {
          console.log("SIGNATURE Called!");
          for (let i = 0; i < this.pageItems.length; i++) {
            if (this.pageItems[i].page_number == fromPage) {
              if (Object.content.length != 0) {
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].color = "#e5f6ed";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content = Object.content;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_type = Object.content_type;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_present = true;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].position = Object.position;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].signature_Settings = Object.signatureSettings;
              } else {
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].color = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_type = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_present = false;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].position = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].signature_Settings = Object.signatureSettings;
              }
            }
          }
        }
        // ADD CONTENT to Particular Page
        else if (this.StoreObj.type == "MAIN CONTENT") {
          console.log("MAIN CONTENT Called!");
          for (let i = 0; i < this.pageItems.length; i++) {
            if (this.pageItems[i].page_number == fromPage) {
              if (Object.content.length != 0) {
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].color = "#e5f6ed";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content = Object.content;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_type = Object.content_type;
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_present = true;
              } else {
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].color = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_type = "";
                this.pageItems[i].templateItems[
                  this.pageItems[i].templateItems.indexOf(this.StoreObj)
                ].content_present = false;
              }
            }
          }
          let PageItems = Array.from(this.pageItems);
          let existingActiveFieldArray = [];
          for (let k = 0; k < PageItems.length; k++) {
            let myLoop = PageItems[k].templateItems[4].content;
            if (myLoop != "") {
              for (let j = 0; j < myLoop.split("}").length; j++) {
                if (myLoop.split("}")[j].includes("{")) {
                  if (myLoop.split("}")[j].split("{")[1] != "") {
                    existingActiveFieldArray.push(
                      myLoop.split("}")[j].split("{")[1]
                    );
                  }
                }
              }
            }
          }
          console.log(this.existingActiveFieldArray);
          if (existingActiveFieldArray.length != 0) {
            this.enableSaveBtn = true;
          } else if (existingActiveFieldArray.length == 0) {
            this.enableSaveBtn = false;
          }
        }
      }
      this.formPDFMethod();
    },
    checkItem(item) {
      console.log("item", item);
      this.StoreObj = item;
      switch (item.type) {
        case "HEADER":
          if (this.currentPageItem == 1) {
            this.dialogAddHeaderFooter = true;
          } else {
            if (item.color != "") {
              this.removeHeaderFooter(item, this.currentPageItem);
            } else {
              this.addHeaderFooter(item, this.currentPageItem);
            }
          }
          break;
        case "FOOTER":
          if (this.currentPageItem == 1) {
            this.dialogAddHeaderFooter = true;
          } else {
            if (item.color != "") {
              this.removeHeaderFooter(item, this.currentPageItem);
            } else {
              this.addHeaderFooter(item, this.currentPageItem);
            }
          }
          break;
        case "IMAGE":
          this.dialogAddImage = true;
          break;
        case "SIGNATURE":
          this.dialogAddSignature = true;
          break;
        case "MAIN CONTENT":
          this.dialogAddText = true;
          break;
      }
    },
    designTemplateEmit(Toggle, ResultObject) {
      this.$emit("clicked", Toggle, ResultObject);
    },
    removeHeaderFooter(item, currPage) {
      console.log("removeHeaderFooter", item, currPage);
      this.currentPageItem = 1;
      if (item.align == "left") {
        if (item.type == "HEADER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = {
            type: "HEADER",
            position: "LEFT",
            columns: "4",
            align: "left",
            label: "Left Header",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
          };
        } else if (item.type == "FOOTER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = {
            type: "FOOTER",
            position: "LEFT",
            columns: "4",
            align: "left",
            label: "Left Footer",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
          };
        }
      } else if (item.align == "center") {
        if (item.type == "HEADER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = {
            type: "HEADER",
            position: "MIDDLE",
            columns: "4",
            align: "center",
            label: "Center Header",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
          };
        } else if (item.type == "FOOTER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = {
            type: "FOOTER",
            position: "MIDDLE",
            columns: "4",
            align: "center",
            label: "Center Footer",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
          };
        }
      } else if (item.align == "right") {
        if (item.type == "HEADER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = {
            type: "HEADER",
            position: "RIGHT",
            columns: "4",
            align: "right",
            label: "Right Header",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
          };
        } else if (item.type == "FOOTER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = {
            type: "FOOTER",
            position: "RIGHT",
            columns: "4",
            align: "right",
            label: "Right Footer",
            height: "50px",
            color: "",
            content_present: false,
            content_type: "",
            content: "",
          };
        }
      }
      this.currentPageItem = currPage;
    },
    addHeaderFooter(item, currPage) {
      console.log("addHeaderFooter", item, currPage);
      this.currentPageItem = 1;
      if (item.align == "left") {
        if (item.type == "HEADER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = this.pageItems[0].templateItems[0];
        } else if (item.type == "FOOTER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = this.pageItems[0].templateItems[7];
        }
      } else if (item.align == "center") {
        if (item.type == "HEADER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = this.pageItems[0].templateItems[1];
        } else if (item.type == "FOOTER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = this.pageItems[0].templateItems[8];
        }
      } else if (item.align == "right") {
        if (item.type == "HEADER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = this.pageItems[0].templateItems[2];
        } else if (item.type == "FOOTER") {
          this.pageItems[currPage - 1].templateItems[
            this.pageItems[currPage - 1].templateItems.indexOf(item)
          ] = this.pageItems[0].templateItems[9];
        }
      }
      this.currentPageItem = currPage;
    },
  },
};
</script>

<style>
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.main_div {
  width: 700px;
  position: relative;
  height: 1060px;
}
.sub_div {
  position: absolute;
  bottom: 0px;
}
.margin-top {
  margin-top: 150px;
}
</style>
