export const FormPDFTemplate = {
  data() {
    return {};
  },
  methods: {
    formPDFMethod() {
      for (let i = 0; i < this.pageItems.length; i++) {
        this.pageItems[i].template_html = "";
        if (
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "HEADER" && item.content_present == true
          ).length == 3
        ) {
          let headerItems = this.pageItems[i].templateItems.filter(
            (item) => item.type == "HEADER" && item.content_present == true
          );
          this.pageItems[i].template_html = `
                  <div id="HeaderLeft${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
            this.pageItems[i].templateItems[0].paddingTop
          }px;padding-left:${
            this.pageItems[i].templateItems[0].paddingLeft
          }px; position: absolute; width: unset;" class="ql-align-left">
                    ${
                      headerItems.filter((item) => item.position == "LEFT")[0]
                        .content_type == "TEXT"
                        ? headerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content
                        : headerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content_type == "BUSINESS_LOGO"
                        ? `${
                            this.$store.getters.get_current_user_details
                              .issuer_details.account_logo_url == undefined
                              ? "Please Upload Business Logo"
                              : `<img src="${
                                  this.$store.getters.get_current_user_details
                                    .issuer_details.account_logo_url
                                }" style="width:${
                                  this.pageItems[i].templateItems[0].resize
                                }px" id="HeaderLeftImage${i + 1}"/>`
                          }`
                        : headerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                        : `<img src=${
                            headerItems.filter(
                              (item) => item.position == "LEFT"
                            )[0].content
                          } style="width:${
                            this.pageItems[i].templateItems[0].resize
                          }px" id="HeaderLeftImage${i + 1}"/>`
                    }
                  </div>
                  
                  <div id="HeaderCenter${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
            this.pageItems[i].templateItems[1].paddingTop
          }px;padding-left:${
            this.pageItems[i].templateItems[1].paddingLeft
          }px;padding-right:${
            this.pageItems[i].templateItems[1].paddingRight
          }px; position: absolute; width: unset;" class="ql-align-center">
                  ${
                    headerItems.filter((item) => item.position == "MIDDLE")[0]
                      .content_type == "TEXT"
                      ? headerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content
                      : headerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[1].resize
                              }px" id="HeaderCenterImage${i + 1}"/>`
                        }`
                      : headerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          headerItems.filter(
                            (item) => item.position == "MIDDLE"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[1].resize
                        }px" id="HeaderCenterImage${i + 1}"/>`
                  }
                  
                  </div>
                  <div id="HeaderRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
            this.pageItems[i].templateItems[2].paddingTop
          }px;padding-left:${
            this.pageItems[i].templateItems[2].paddingLeft
          }px;padding-right:${
            this.pageItems[i].templateItems[2].paddingRight
          }px; position: absolute; width: unset;" class=" ql-align-right">
                  ${
                    headerItems.filter((item) => item.position == "RIGHT")[0]
                      .content_type == "TEXT"
                      ? headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content
                      : headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[2].resize
                              }px" id="HeaderRightImage${i + 1}"/>`
                        }`
                      : headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          headerItems.filter(
                            (item) => item.position == "RIGHT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[2].resize
                        }px" id="HeaderRightImage${i + 1}"/>`
                  }
                    </div>
              `;
        }
        if (
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "HEADER" && item.content_present == true
          ).length == 2
        ) {
          let headerItems = this.pageItems[i].templateItems.filter(
            (item) => item.type == "HEADER" && item.content_present == true
          );
          if (
            this.pageItems[i].templateItems.filter(
              (item) =>
                item.type == "HEADER" &&
                item.content_present == true &&
                item.position != "MIDDLE"
            ).length == 2
          ) {
            this.pageItems[i].template_html = `
                  <div class=" ql-align-left" id="HeaderRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[2].paddingTop
            }px;padding-right:${
              this.pageItems[i].templateItems[2].paddingRight
            }px; padding-left:${
              this.pageItems[i].templateItems[2].paddingLeft
            }px;position: absolute; width: unset;">
                  ${
                    headerItems.filter((item) => item.position == "LEFT")[0]
                      .content_type == "TEXT"
                      ? headerItems.filter((item) => item.position == "LEFT")[0]
                          .content
                      : headerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[2].resize
                              }px" id="HeaderRightImage${i + 1}"/>`
                        }`
                      : headerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          headerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[2].resize
                        }px" id="HeaderRightImage${i + 1}"/>`
                  }
                  </div>
                  <div class=" ql-align-right" id="HeaderRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[2].paddingTop
            }px;padding-right:${
              this.pageItems[i].templateItems[2].paddingRight
            }px;padding-left:${
              this.pageItems[i].templateItems[2].paddingLeft
            }px; position: absolute; width: unset;">
                  ${
                    headerItems.filter((item) => item.position == "RIGHT")[0]
                      .content_type == "TEXT"
                      ? headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content
                      : headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[2].resize
                              }px" id="HeaderRightImage${i + 1}"/>`
                        }`
                      : headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          headerItems.filter(
                            (item) => item.position == "RIGHT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[2].resize
                        }px" id="HeaderRightImage${i + 1}"/>`
                  }
                  </div>
              `;
          } else {
            this.pageItems[i].template_html = `
                  <div class=" ql-align-left" id="HeaderLeft${
                    this.pageItems[i].page_number
                  }"style="padding-top:${
              this.pageItems[i].templateItems[0].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[0].paddingLeft
            }px; position: absolute; width: unset;">
                  ${
                    this.pageItems[i].templateItems.filter(
                      (item) => item.position == "LEFT" && item.type == "HEADER"
                    )[0].content_present == true
                      ? this.pageItems[i].templateItems.filter(
                          (item) =>
                            item.position == "LEFT" && item.type == "HEADER"
                        )[0].content_type == "TEXT"
                        ? this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "LEFT" && item.type == "HEADER"
                          )[0].content
                        : this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "LEFT" && item.type == "HEADER"
                          )[0].content_type == "BUSINESS_LOGO"
                        ? `${
                            this.$store.getters.get_current_user_details
                              .issuer_details.account_logo_url == undefined
                              ? "Please Upload Business Logo"
                              : `<img src="${
                                  this.$store.getters.get_current_user_details
                                    .issuer_details.account_logo_url
                                }" style="width:${
                                  this.pageItems[i].templateItems[0].resize
                                }px" id="HeaderLeftImage${i + 1}"/>`
                          }`
                        : headerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                        : `<img src=${
                            this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "LEFT" && item.type == "HEADER"
                            )[0].content
                          } style="width:${
                            this.pageItems[i].templateItems[0].resize
                          }px" id="HeaderLeftImage${i + 1}"/>`
                      : ""
                  }
                  </div>
                  <div class=" ql-align-center" id="HeaderCenter${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[1].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[1].paddingLeft
            }px;padding-right:${
              this.pageItems[i].templateItems[1].paddingRight
            }px;position: absolute; width: unset;">
                  ${
                    this.pageItems[i].templateItems.filter(
                      (item) =>
                        item.position == "MIDDLE" && item.type == "HEADER"
                    )[0].content_present == true
                      ? this.pageItems[i].templateItems.filter(
                          (item) =>
                            item.position == "MIDDLE" && item.type == "HEADER"
                        )[0].content_type == "TEXT"
                        ? this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "MIDDLE" && item.type == "HEADER"
                          )[0].content
                        : this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "MIDDLE" && item.type == "HEADER"
                          )[0].content_type == "BUSINESS_LOGO"
                        ? `${
                            this.$store.getters.get_current_user_details
                              .issuer_details.account_logo_url == undefined
                              ? "Please Upload Business Logo"
                              : `<img src="${
                                  this.$store.getters.get_current_user_details
                                    .issuer_details.account_logo_url
                                }" style="width:${
                                  this.pageItems[i].templateItems[1].resize
                                }px" id="HeaderCenterImage${i + 1}"/>`
                          }`
                        : this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "MIDDLE" && item.type == "HEADER"
                          )[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                        : `<img src=${
                            this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "MIDDLE" &&
                                item.type == "HEADER"
                            )[0].content
                          } style="width:${
                            this.pageItems[i].templateItems[1].resize
                          }px" id="HeaderCenterImage${i + 1}"/>`
                      : ""
                  }
                  </div>
                  <div class=" ql-align-right" id="HeaderRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[2].paddingTop
            }px;padding-right:${
              this.pageItems[i].templateItems[2].paddingRight
            }px;padding-left:${
              this.pageItems[i].templateItems[2].paddingLeft
            }px; position: absolute;width: unset;">
                  ${
                    this.pageItems[i].templateItems.filter(
                      (item) =>
                        item.position == "RIGHT" && item.type == "HEADER"
                    )[0].content_present == true
                      ? this.pageItems[i].templateItems.filter(
                          (item) =>
                            item.position == "RIGHT" && item.type == "HEADER"
                        )[0].content_type == "TEXT"
                        ? this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "RIGHT" && item.type == "HEADER"
                          )[0].content
                        : this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "RIGHT" && item.type == "HEADER"
                          )[0].content_type == "BUSINESS_LOGO"
                        ? `${
                            this.$store.getters.get_current_user_details
                              .issuer_details.account_logo_url == undefined
                              ? "Please Upload Business Logo"
                              : `<img src="${
                                  this.$store.getters.get_current_user_details
                                    .issuer_details.account_logo_url
                                }" style="width:${
                                  this.pageItems[i].templateItems[2].resize
                                }px" id="HeaderRightImage${i + 1}"/>`
                          }`
                        : this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "RIGHT" && item.type == "HEADER"
                          )[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                        : `<img src=${
                            this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "RIGHT" &&
                                item.type == "HEADER"
                            )[0].content
                          } style="width:${
                            this.pageItems[i].templateItems[2].resize
                          }px" id="HeaderRightImage${i + 1}"/>`
                      : ""
                  }
                  </div>
             `;
          }
        }
        if (
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "HEADER" && item.content_present == true
          ).length == 1
        ) {
          let headerItems = this.pageItems[i].templateItems.filter(
            (item) => item.type == "HEADER" && item.content_present == true
          );
          if (
            headerItems.filter((item) => item.position == "LEFT").length == 1
          ) {
            headerItems = this.pageItems[i].templateItems.filter(
              (item) => item.position == "LEFT"
            );
            this.pageItems[i].template_html = `
                  <div class="ql-align-left" id="HeaderLeft${
                    this.pageItems[i].page_number
                  }"style="padding-top:${
              this.pageItems[i].templateItems[0].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[0].paddingLeft
            }px; position: absolute; width: unset;">
                  ${
                    headerItems.filter((item) => item.position == "LEFT")[0]
                      .content_type == "TEXT"
                      ? headerItems.filter((item) => item.position == "LEFT")[0]
                          .content
                      : headerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[0].resize
                              }px" id="HeaderLeftImage${i + 1}"/>`
                        }`
                      : headerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          headerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[0].resize
                        }px" id="HeaderLeftImage${i + 1}"/>`
                  }
                  </div>
               `;
          }
          if (
            headerItems.filter((item) => item.position == "MIDDLE").length == 1
          ) {
            this.pageItems[i].template_html = `
                  <div class=" ql-align-center" id="HeaderCenter${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[1].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[1].paddingLeft
            }px;padding-right:${
              this.pageItems[i].templateItems[1].paddingRight
            }px;position: absolute; width: unset;">
                  ${
                    headerItems.filter((item) => item.position == "MIDDLE")[0]
                      .content_type == "TEXT"
                      ? headerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content
                      : headerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[1].resize
                              }px" id="HeaderCenterImage${i + 1}"/>`
                        }`
                      : headerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          headerItems.filter(
                            (item) => item.position == "MIDDLE"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[1].resize
                        }px" id="HeaderCenterImage${i + 1}"/>`
                  }
                  </div>
                `;
          }
          if (
            headerItems.filter((item) => item.position == "RIGHT").length == 1
          ) {
            this.pageItems[i].template_html = `
                  <div class=" ql-align-right" id="HeaderRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[2].paddingTop
            }px;padding-right:${
              this.pageItems[i].templateItems[2].paddingRight
            }px;padding-left:${
              this.pageItems[i].templateItems[2].paddingLeft
            }px; position: absolute; width: unset;">
                  ${
                    headerItems.filter((item) => item.position == "RIGHT")[0]
                      .content_type == "TEXT"
                      ? headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content
                      : headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[2].resize
                              }px" id="HeaderRightImage${i + 1}"/>`
                        }`
                      : headerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          headerItems.filter(
                            (item) => item.position == "RIGHT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[2].resize
                        }px" id="HeaderRightImage${i + 1}"/>`
                  }
                  </div>
               `;
          }
        }
        // ===================================================================================================================================
        // ===================================================================================================================================
        // =================================================IMAGE TOP============================================================================
        let image_html_top = "";
        if (
          this.pageItems[i].templateItems.filter(
            (item) =>
              item.type == "IMAGE" &&
              item.position == "TOP" &&
              item.content_present == true
          ).length != 0
        ) {
          if (
            this.pageItems[i].templateItems.filter(
              (item) =>
                item.type == "IMAGE" &&
                item.position == "TOP" &&
                item.content_present == true
            )[0].content_type == "IMAGE"
          ) {
            image_html_top = `
          <div class=" ql-align-center"
          style="padding-top:${
            this.pageItems[i].templateItems[3].paddingTop
          }px;padding-left:${
              this.pageItems[i].templateItems[3].paddingLeft
            }px; position: absolute; width: unset;"
           id="ImageTop${this.pageItems[i].page_number}"
          >
             ${
               this.pageItems[i].templateItems.filter(
                 (item) =>
                   item.type == "IMAGE" &&
                   item.position == "TOP" &&
                   item.content_present == true
               )[0].content
             }
          </>
        `;
          } else {
            let topImageSettings = this.pageItems[i].templateItems.filter(
              (item) =>
                item.type == "IMAGE" &&
                item.position == "TOP" &&
                item.content_present == true
            )[0].text_settings;
            image_html_top =
              this.$store.getters.get_current_user_details.issuer_details
                .account_logo_url == undefined
                ? `<h1>Please Upload Business Logo</h1>`
                : `<img src=${this.$store.getters.get_current_user_details.issuer_details.account_logo_url} style="width:${this.pageItems[i].templateItems[3].ImageWidth}px; height:${this.pageItems[i].templateItems[3].ImageHeight}px;" id="innerImageTop${this.pageItems[i].page_number}"/>`;

            if (topImageSettings.configure_text_before_image == true) {
              image_html_top =
                topImageSettings.text_content_before_image.concat(
                  image_html_top
                );
            }
            if (topImageSettings.configure_text_after_image == true) {
              image_html_top = image_html_top.concat(
                topImageSettings.text_content_after_image
              );
            }
            image_html_top = `
          <div class=" ql-align-center"  style="padding-top:${this.pageItems[i].templateItems[3].paddingTop}px;padding-left:${this.pageItems[i].templateItems[3].paddingLeft}px; position: absolute; width: unset;"
           id="ImageTop${this.pageItems[i].page_number}"
           >
          ${image_html_top}
          </div>
          `;
          }
        }
        this.pageItems[i].template_html =
          this.pageItems[i].template_html.concat(image_html_top);
        // ===================================================================================================================================
        // ===================================================================================================================================
        // =================================================MAIN CONTENT============================================================================
        let main_content_html = "";
        if (
          this.pageItems[i].templateItems.filter(
            (item) =>
              item.type == "MAIN CONTENT" && item.content_present == true
          ).length != 0
        ) {
          main_content_html = `
               <div class=" ${
                 this.pageItems[i].templateItems
                   .filter(
                     (item) =>
                       item.type == "MAIN CONTENT" &&
                       item.content_present == true
                   )[0]
                   .content.includes("ql-align-right") ||
                 this.pageItems[i].templateItems
                   .filter(
                     (item) =>
                       item.type == "MAIN CONTENT" &&
                       item.content_present == true
                   )[0]
                   .content.includes("ql-align-center") ||
                 this.pageItems[i].templateItems
                   .filter(
                     (item) =>
                       item.type == "MAIN CONTENT" &&
                       item.content_present == true
                   )[0]
                   .content.includes("ql-align-justify")
                   ? ""
                   : "ql-align-left"
               }"
               style="top:${
                 this.pageItems[i].templateItems[4].Top
               }px;padding-top:${
            this.pageItems[i].templateItems[4].paddingTop
          }px;padding-left:${
            this.pageItems[i].templateItems[4].paddingLeft
          }px;padding-Right:${
            this.pageItems[i].templateItems[4].paddingRight
          }px;position:absolute; width:unset;"
               id="Content${this.pageItems[i].page_number}"
               >
                  ${
                    this.pageItems[i].templateItems.filter(
                      (item) =>
                        item.type == "MAIN CONTENT" &&
                        item.content_present == true
                    )[0].content
                  }
               </div>
              `;
        }
        this.pageItems[i].template_html =
          this.pageItems[i].template_html.concat(main_content_html);
        // ===================================================================================================================================
        // ===================================================================================================================================
        // =================================================IMAGE BOTTOM======================================================================
        let image_html_bottom = "";
        if (
          this.pageItems[i].templateItems.filter(
            (item) =>
              item.type == "IMAGE" &&
              item.position == "BOTTOM" &&
              item.content_present == true
          ).length != 0
        ) {
          if (
            this.pageItems[i].templateItems.filter(
              (item) =>
                item.type == "IMAGE" &&
                item.position == "BOTTOM" &&
                item.content_present == true
            )[0].content_type == "IMAGE"
          ) {
            image_html_bottom = `
          <div class=" ql-align-center"
          style="padding-top:${
            this.pageItems[i].templateItems[5].paddingTop
          }px;padding-left:${
              this.pageItems[i].templateItems[5].paddingLeft
            }px; position: absolute; width: unset;"
          id="ImageBottom${this.pageItems[i].page_number}"
          >
             ${
               this.pageItems[i].templateItems.filter(
                 (item) =>
                   item.type == "IMAGE" &&
                   item.position == "BOTTOM" &&
                   item.content_present == true
               )[0].content
             }
          </div>
        `;
          } else {
            let bottomImageSettings = this.pageItems[i].templateItems.filter(
              (item) =>
                item.type == "IMAGE" &&
                item.position == "BOTTOM" &&
                item.content_present == true
            )[0].text_settings;
            image_html_bottom =
              this.$store.getters.get_current_user_details.issuer_details
                .account_logo_url == undefined
                ? `<h1>Please Upload Business Logo</h1>`
                : `<img src=${this.$store.getters.get_current_user_details.issuer_details.account_logo_url} style="width:${this.pageItems[i].templateItems[5].ImageWidth}px; height:${this.pageItems[i].templateItems[5].ImageHeight}px;" id="innerImageBottom${this.pageItems[i].page_number}"/>`;

            if (bottomImageSettings.configure_text_before_image == true) {
              image_html_bottom =
                bottomImageSettings.text_content_before_image.concat(
                  image_html_bottom
                );
            }
            if (bottomImageSettings.configure_text_after_image == true) {
              image_html_bottom = image_html_bottom.concat(
                bottomImageSettings.text_content_after_image
              );
            }
            image_html_bottom = `
          <div class=" ql-align-center" style="padding-top:${this.pageItems[i].templateItems[5].paddingTop}px;padding-left:${this.pageItems[i].templateItems[5].paddingLeft}px; position: absolute; width: unset;"
          id="ImageBottom${this.pageItems[i].page_number}"
          >
          ${image_html_bottom}
          </div>
          `;
          }
        }
        this.pageItems[i].template_html =
          this.pageItems[i].template_html.concat(image_html_bottom);
        // ===================================================================================================================================
        // ===================================================================================================================================
        // =================================================SIGNATURE=========================================================================
        let signature_html = "";
        if (
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "SIGNATURE"
          )[0].content_present == true
        ) {
          let previewhtml = ` <div id="Signature${this.pageItems[i].page_number}" style="padding-top:${this.pageItems[i].templateItems[6].paddingTop}px;padding-left:${this.pageItems[i].templateItems[6].paddingLeft}px; position: absolute; width: unset;"> <img src="${this.$store.getters.get_current_user_details.signature_url}" style="width:250px;"/></div>`;
          let signatureSettings = this.pageItems[i].templateItems.filter(
            (item) => item.type == "SIGNATURE"
          )[0].signature_Settings;
          // text -------------------------------
          if (signatureSettings.configure_text_before_signature == true) {
            previewhtml = previewhtml.concat(
              `<div id="TextSignature${this.pageItems[i].page_number}" style="padding-top:${this.pageItems[i].templateItems[6].TextpaddingTop}px;padding-left:${this.pageItems[i].templateItems[6].TextpaddingLeft}px; position: absolute; width: unset;">${signatureSettings.text_content_before_signature}</div>`
            );
          }
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "SIGNATURE"
          )[0].content = previewhtml;
        }
        if (
          this.pageItems[i].templateItems.filter(
            (item) =>
              item.type == "SIGNATURE" &&
              item.position == "LEFT" &&
              item.content_present == true
          ).length != 0
        ) {
          signature_html = this.pageItems[i].templateItems.filter(
            (item) =>
              item.type == "SIGNATURE" &&
              item.position == "LEFT" &&
              item.content_present == true
          )[0].content;
        }
        if (
          this.pageItems[i].templateItems.filter(
            (item) =>
              item.type == "SIGNATURE" &&
              item.position == "RIGHT" &&
              item.content_present == true
          ).length != 0
        ) {
          signature_html = this.pageItems[i].templateItems.filter(
            (item) =>
              item.type == "SIGNATURE" &&
              item.position == "RIGHT" &&
              item.content_present == true
          )[0].content;
        }
        this.pageItems[i].template_html =
          this.pageItems[i].template_html.concat(signature_html);
        // ===================================================================================================================================
        // ===================================================================================================================================
        // =================================================FOOTER============================================================================
        let footer_html = "";
        if (
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "FOOTER" && item.content_present == true
          ).length == 3
        ) {
          let footerItems = this.pageItems[i].templateItems.filter(
            (item) => item.type == "FOOTER" && item.content_present == true
          );
          footer_html = `
                  <div class=" ql-align-left"  id="FooterLeft${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
            this.pageItems[i].templateItems[7].paddingTop
          }px;padding-left:${
            this.pageItems[i].templateItems[7].paddingLeft
          }px; position:absolute;width:unset">
                  ${
                    footerItems.filter((item) => item.position == "LEFT")[0]
                      .content_type == "TEXT"
                      ? footerItems.filter((item) => item.position == "LEFT")[0]
                          .content
                      : footerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[7].resize
                              }px" id="FooterLeftImage${i + 1}"/>`
                        }`
                      : footerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          footerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[7].resize
                        }px" id="FooterLeftImage${i + 1}"/>`
                  }
                  </div>
                  <div class=" ql-align-center" id="FooterCenter${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
            this.pageItems[i].templateItems[8].paddingTop
          }px;padding-left:${
            this.pageItems[i].templateItems[8].paddingLeft
          }px; position:absolute;width:unset;">

                  ${
                    footerItems.filter((item) => item.position == "MIDDLE")[0]
                      .content_type == "TEXT"
                      ? footerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content
                      : footerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[8].resize
                              }px" id="FooterMiddleImage${i + 1}"/>`
                        }`
                      : footerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          footerItems.filter(
                            (item) => item.position == "MIDDLE"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[8].resize
                        }px" id="FooterMiddleImage${i + 1}"/>`
                  }
                  </div>
                  <div class=" ql-align-right" id="FooterRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
            this.pageItems[i].templateItems[9].paddingTop
          }px;padding-left:${
            this.pageItems[i].templateItems[9].paddingLeft
          }px;position:absolute;width:unset;">
                  ${
                    footerItems.filter((item) => item.position == "RIGHT")[0]
                      .content_type == "TEXT"
                      ? footerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content
                      : footerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[9].resize
                              }px" id="FooterRightImage${i + 1}"/>`
                        }`
                      : footerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          footerItems.filter(
                            (item) => item.position == "RIGHT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[9].resize
                        }px" id="FooterRightImage${i + 1}"/>`
                  }
                  </div>
              `;
        }
        if (
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "FOOTER" && item.content_present == true
          ).length == 2
        ) {
          let footerItems = this.pageItems[i].templateItems.filter(
            (item) => item.type == "FOOTER" && item.content_present == true
          );
          if (
            this.pageItems[i].templateItems.filter(
              (item) =>
                item.type == "FOOTER" &&
                item.content_present == true &&
                item.position != "MIDDLE"
            ).length == 2
          ) {
            footer_html = `
                  <div class=" ql-align-left" id="FooterLeft${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[7].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[7].paddingLeft
            }px; position:absolute;width:unset">
                  ${
                    footerItems.filter((item) => item.position == "LEFT")[0]
                      .content_type == "TEXT"
                      ? footerItems.filter((item) => item.position == "LEFT")[0]
                          .content
                      : footerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[7].resize
                              }px" id="FooterLeftImage${i + 1}"/>`
                        }`
                      : footerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          footerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[7].resize
                        }px" id="FooterLeftImage${i + 1}"/>`
                  }
                  </div>
                  <div class=" ql-align-right" id="FooterRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[9].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[9].paddingLeft
            }px; position:absolute; width:unset">
                  ${
                    footerItems.filter((item) => item.position == "RIGHT")[0]
                      .content_type == "TEXT"
                      ? footerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content
                      : footerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_LOGO"
                      ? `${
                          this.$store.getters.get_current_user_details
                            .issuer_details.account_logo_url == undefined
                            ? "Please Upload Business Logo"
                            : `<img src="${
                                this.$store.getters.get_current_user_details
                                  .issuer_details.account_logo_url
                              }" style="width:${
                                this.pageItems[i].templateItems[9].resize
                              }px" id="FooterRightImage${i + 1}"/>`
                        }`
                      : footerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                      : `<img src=${
                          footerItems.filter(
                            (item) => item.position == "RIGHT"
                          )[0].content
                        } style="width:${
                          this.pageItems[i].templateItems[9].resize
                        }px" id="FooterRightImage${i + 1}"/>`
                  }
                  </div>
              `;
          } else {
            footer_html = `
                  <div class=" ql-align-left" id="FooterLeft${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[7].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[7].paddingLeft
            }px; position:absolute;width:unset">
                    ${
                      this.pageItems[i].templateItems.filter(
                        (item) =>
                          item.position == "LEFT" && item.type == "FOOTER"
                      )[0].content_present == true
                        ? this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "LEFT" && item.type == "FOOTER"
                          )[0].content_type == "TEXT"
                          ? this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "LEFT" && item.type == "FOOTER"
                            )[0].content
                          : this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "LEFT" && item.type == "FOOTER"
                            )[0].content_type == "BUSINESS_LOGO"
                          ? `${
                              this.$store.getters.get_current_user_details
                                .issuer_details.account_logo_url == undefined
                                ? "Please Upload Business Logo"
                                : `<img src="${
                                    this.$store.getters.get_current_user_details
                                      .issuer_details.account_logo_url
                                  }" style="width:${
                                    this.pageItems[i].templateItems[7].resize
                                  }px" id="FooterLeftImage${i + 1}"/>`
                            }`
                          : this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "LEFT" && item.type == "FOOTER"
                            )[0].content_type == "BUSINESS_NAME"
                          ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                          : `<img src=${
                              this.pageItems[i].templateItems.filter(
                                (item) =>
                                  item.position == "LEFT" &&
                                  item.type == "FOOTER"
                              )[0].content
                            } style="width:${
                              this.pageItems[i].templateItems[7].resize
                            }px" id="FooterLeftImage${i + 1}"/>`
                        : ""
                    }
                  </div>
                  <div class=" ql-align-center" id="FooterCenter${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[8].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[8].paddingLeft
            }px; position:absolute;width:unset">
                    ${
                      this.pageItems[i].templateItems.filter(
                        (item) =>
                          item.position == "MIDDLE" && item.type == "FOOTER"
                      )[0].content_present == true
                        ? this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "MIDDLE" && item.type == "FOOTER"
                          )[0].content_type == "TEXT"
                          ? this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "MIDDLE" &&
                                item.type == "FOOTER"
                            )[0].content
                          : this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "MIDDLE" &&
                                item.type == "FOOTER"
                            )[0].content_type == "BUSINESS_LOGO"
                          ? `${
                              this.$store.getters.get_current_user_details
                                .issuer_details.account_logo_url == undefined
                                ? "Please Upload Business Logo"
                                : `<img src="${
                                    this.$store.getters.get_current_user_details
                                      .issuer_details.account_logo_url
                                  }" style="width:${
                                    this.pageItems[i].templateItems[8].resize
                                  }px" id="FooterCenterImage${i + 1}"/>`
                            }`
                          : this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "MIDDLE" &&
                                item.type == "FOOTER"
                            )[0].content_type == "BUSINESS_NAME"
                          ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                          : `<img src=${
                              this.pageItems[i].templateItems.filter(
                                (item) =>
                                  item.position == "MIDDLE" &&
                                  item.type == "FOOTER"
                              )[0].content
                            } style="width:${
                              this.pageItems[i].templateItems[8].resize
                            }px" id="FooterCenterImage${i + 1}"/>`
                        : ""
                    }
                  </div>
                  <div class=" ql-align-right" id="FooterRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[9].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[9].paddingLeft
            }px; position:absolute;width:unset">
                  ${
                    this.pageItems[i].templateItems.filter(
                      (item) =>
                        item.position == "RIGHT" && item.type == "FOOTER"
                    )[0].content_present == true
                      ? this.pageItems[i].templateItems.filter(
                          (item) =>
                            item.position == "RIGHT" && item.type == "FOOTER"
                        )[0].content_type == "TEXT"
                        ? this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "RIGHT" && item.type == "FOOTER"
                          )[0].content
                        : this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "RIGHT" && item.type == "FOOTER"
                          )[0].content_type == "BUSINESS_LOGO"
                        ? `${
                            this.$store.getters.get_current_user_details
                              .issuer_details.account_logo_url == undefined
                              ? "Please Upload Business Logo"
                              : `<img src="${
                                  this.$store.getters.get_current_user_details
                                    .issuer_details.account_logo_url
                                }" style="width:${
                                  this.pageItems[i].templateItems[9].resize
                                }px" id="FooterRightImage${i + 1}"/>`
                          }`
                        : this.pageItems[i].templateItems.filter(
                            (item) =>
                              item.position == "RIGHT" && item.type == "FOOTER"
                          )[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                        : `<img src=${
                            this.pageItems[i].templateItems.filter(
                              (item) =>
                                item.position == "RIGHT" &&
                                item.type == "FOOTER"
                            )[0].content
                          } style="width:${
                            this.pageItems[i].templateItems[9].resize
                          }px" id="FooterRightImage${i + 1}"/>`
                      : ""
                  }
                  </div>
              `;
          }
        }
        if (
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "FOOTER" && item.content_present == true
          ).length == 1
        ) {
          let footerItems = this.pageItems[i].templateItems.filter(
            (item) => item.type == "FOOTER" && item.content_present == true
          );
          if (
            footerItems.filter((item) => item.position == "LEFT").length == 1
          ) {
            footer_html = `
                  <div class=" ql-align-left" id="FooterLeft${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[7].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[7].paddingLeft
            }px; position:absolute;width:unset">
                  ${
                    footerItems.filter((item) => item.position == "LEFT")[0]
                      .content_present == true
                      ? footerItems.filter((item) => item.position == "LEFT")[0]
                          .content_type == "TEXT"
                        ? footerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content
                        : footerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content_type == "BUSINESS_LOGO"
                        ? `${
                            this.$store.getters.get_current_user_details
                              .issuer_details.account_logo_url == undefined
                              ? "Please Upload Business Logo"
                              : `<img src="${
                                  this.$store.getters.get_current_user_details
                                    .issuer_details.account_logo_url
                                }" style="width:${
                                  this.pageItems[i].templateItems[7].resize
                                }px" id="FooterLeftImage${i + 1}"/>`
                          }`
                        : footerItems.filter(
                            (item) => item.position == "LEFT"
                          )[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                        : `<img src=${
                            footerItems.filter(
                              (item) => item.position == "LEFT"
                            )[0].content
                          } style="width:${
                            this.pageItems[i].templateItems[7].resize
                          }px" id="FooterLeftImage${i + 1}"/>`
                      : ""
                  }
                  </div>
               `;
          }
          if (
            footerItems.filter((item) => item.position == "MIDDLE").length == 1
          ) {
            footer_html = `
                  <div class=" ql-align-center" id="FooterCenter${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[8].paddingTop
            }px;padding-left:${
              this.pageItems[i].templateItems[8].paddingLeft
            }px; position:absolute;width:unset">
                  ${
                    footerItems.filter((item) => item.position == "MIDDLE")[0]
                      .content_present == true
                      ? footerItems.filter(
                          (item) => item.position == "MIDDLE"
                        )[0].content_type == "TEXT"
                        ? footerItems.filter(
                            (item) => item.position == "MIDDLE"
                          )[0].content
                        : footerItems.filter(
                            (item) => item.position == "MIDDLE"
                          )[0].content_type == "BUSINESS_LOGO"
                        ? `${
                            this.$store.getters.get_current_user_details
                              .issuer_details.account_logo_url == undefined
                              ? "Please Upload Business Logo"
                              : `<img src="${
                                  this.$store.getters.get_current_user_details
                                    .issuer_details.account_logo_url
                                }" style="width:${
                                  this.pageItems[i].templateItems[8].resize
                                }px" id="FooterMiddleImage${i + 1}"/>`
                          }`
                        : footerItems.filter(
                            (item) => item.position == "MIDDLE"
                          )[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                        : `<img src=${
                            footerItems.filter(
                              (item) => item.position == "MIDDLE"
                            )[0].content
                          } style="width:${
                            this.pageItems[i].templateItems[8].resize
                          }px" id="FooterMiddleImage${i + 1}"/>`
                      : ""
                  }
                  </div>
                `;
          }
          if (
            footerItems.filter((item) => item.position == "RIGHT").length == 1
          ) {
            footer_html = `
                  <div class=" ql-align-right" id="FooterRight${
                    this.pageItems[i].page_number
                  }" style="padding-top:${
              this.pageItems[i].templateItems[9].paddingTop
            }px;padding-right:${
              this.pageItems[i].templateItems[9].paddingRight
            }px; position:absolute;width:unset">
                  ${
                    footerItems.filter((item) => item.position == "RIGHT")[0]
                      .content_present == true
                      ? footerItems.filter(
                          (item) => item.position == "RIGHT"
                        )[0].content_type == "TEXT"
                        ? footerItems.filter(
                            (item) => item.position == "RIGHT"
                          )[0].content
                        : footerItems.filter(
                            (item) => item.position == "RIGHT"
                          )[0].content_type == "BUSINESS_LOGO"
                        ? `${
                            this.$store.getters.get_current_user_details
                              .issuer_details.account_logo_url == undefined
                              ? "Please Upload Business Logo"
                              : `<img src="${
                                  this.$store.getters.get_current_user_details
                                    .issuer_details.account_logo_url
                                }" style="width:${
                                  this.pageItems[i].templateItems[9].resize
                                }px" id="FooterRightImage${i + 1}"/>`
                          }`
                        : footerItems.filter(
                            (item) => item.position == "RIGHT"
                          )[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</h1>`
                        : `<img src=${
                            footerItems.filter(
                              (item) => item.position == "RIGHT"
                            )[0].content
                          } style="width:${
                            this.pageItems[i].templateItems[9].resize
                          }px" id="FooterRightImage${i + 1}"/>`
                      : ""
                  }
                  </div>
                `;
          }
        }
        this.pageItems[i].template_html =
          this.pageItems[i].template_html.concat(footer_html);
        console.warn("Check Template HTML", this.pageItems[i].template_html);
        console.warn("Check Template Items", this.pageItems[i].templateItems);
        this.pageItems[i].template_html = `<html>
                  <head>
                    <style>
                    body {
                      font-size: 90.5%;
                    } 
                    *{
                    margin: 0;
                    padding: 0;
                    }
                    .ql-align-center {
                      text-align: center;
                    }
                    .ql-align-left {
                      text-align: left;
                    }
                    .ql-align-right {
                      text-align: right;
                    }
                    .ql-align-justify {
                      text-align: justify;
                    }
                    .sub_div {
                      position: absolute;
                      bottom: 0px;
                    }
                    .margin-top {
                      margin-top: 150px;
                    }
                    .center {
                      margin: auto;
                      width: 50%;
                    }
                    .footer-left {
                      position: absolute;
                      bottom: 10px;
                      left: 0;
                    }
                    .footer-right {
                      position: absolute;
                      bottom: 10px;
                      right: 3%;
                    }
                    .footer-center {
                      position: absolute;
                      margin: auto;
                      left: 0;
                      bottom: 10px;
                      right: 0;
                    }      
                    .ql-font-monospace {
                      font-family: "Courier New", monospace;
                    }
                    .ql-font-serif {
                      font-family: Georgia, serif;
                    }
                    </style>
                  </head>
                  <body style="background-color:${
                    this.pageItems[i].templateItems.filter(
                      (item) => item.type == "BACKGROUND"
                    )[0].content
                  }; background-image: url(
                  ${
                    this.pageItems[i].templateItems.filter(
                      (item) => item.type == "BACKGROUND"
                    )[0].imgSrc
                  }); background-size: ${
          this.pageItems[i].page_type == "P" ? "794px 1123px;" : "1123px 794px;"
        }">
                  <div style="background-color:${
                    this.pageItems[i].templateItems.filter(
                      (item) => item.type == "BACKGROUND"
                    )[0].content
                  };height: ${
          this.pageItems[i].page_type == "P" ? "1070px;" : "740px;"
        } ;margin:20px;border:${
          this.pageItems[i].templateItems.filter(
            (item) => item.type == "BORDER"
          )[0].content_present == true
            ? this.pageItems[i].templateItems.filter(
                (item) => item.type == "BORDER"
              )[0].content
            : ""
        };"
        id="innerPage${this.pageItems[i].page_number}">
                  ${this.pageItems[i].template_html}
                  </div>
                  </body>
                  </html>`;
        // =================================================END============================================================================
        if (this.$route.name == "Dashboard") {
          this.StoreObj.template_html = this.pageItems[i].template_html;
          this.deciderMethod(this.StoreObj, this.StoreObj.action);
        }
      }
    },
  },
};
