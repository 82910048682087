<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <RegenerateAPIIDWarning :RegenerateAPIIDWarningDialog="RegenerateAPIIDWarningDialog" @clicked="RegenerateAPIIDWarningDialogEmit" />
    <v-expand-x-transition>
      <v-card class="elevation-0" v-show="Expand">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-toolbar class="elevation-0">
              <div class="largeFontSizeNew">API/ Encryption</div>
            </v-toolbar>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-toolbar class="elevation-0">
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" class="mt-n6">
            <v-card outlined class="ma-4 pa-2">
              <v-card-title class="py-0"
                >API
                <v-spacer></v-spacer>
                <v-btn small @click="RegenerateAPIIDWarningDialog = true" :loading="loadinguser_id" outlined color="primary" class="ml-6 borderRadius fontStyle text-capitalize mr-2"
                  >Regenerate API ID</v-btn
                >
                <v-btn small class="borderRadius fontStyle text-capitalize" color="primary" @click="OpenNewWindow()">View API Docs</v-btn>
              </v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-card-text align="left" class="py-0">
                <div>
                  <span class="xmediumFontSize font-weight-bold">API Key : </span
                  ><span class="PrimaryFontColorSmall" v-on:mouseover="mouseover('APIKEY')" v-on:mouseleave="mouseleave('APIKEY')">{{ api_key }}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon v-clipboard:copy="$store.getters.get_current_user_details.issuer_details.api_key" v-clipboard:success="onCopy" class="mr-4">
                        <v-icon small color="primary">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>API Key</span>
                  </v-tooltip>
                </div>
                <div class="my-2">
                  <span class="xmediumFontSize font-weight-bold">Issuer API ID<span class="ml-3"> : </span></span
                  ><span class="PrimaryFontColorSmall" v-on:mouseover="mouseover('APIID')" v-on:mouseleave="mouseleave('APIID')">{{ issuer_api_id }}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon v-clipboard:copy="issuer_api_id" v-clipboard:success="onCopyapiid" class="mr-4">
                        <v-icon small color="primary">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Copy API ID</span>
                  </v-tooltip>
                </div>
              </v-card-text>
              <v-card-actions v-if="postman_url != undefined && postman_url != '' && postman_url != null" class="pt-0"> </v-card-actions>
              <v-card-title class="py-0">Webhook</v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-form ref="form">
                <v-card-actions class="pb-0">
                  <div class="xmediumFontSize font-weight-bold mb-4">Webhook URL :</div>
                  <v-text-field
                    dense
                    outlined
                    class="FontSize field_height field_label_size ml-4"
                    placeholder="Enter Webhook URL"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="webhook_url"
                  ></v-text-field>
                  <v-btn small class="borderRadius fontStyle text-capitalize mt-n7 ml-3" color="primary" :loading="loadingWEBHOOK_URL" @click="validateMethod()">
                    <v-icon small class="mr-2">mdi-pencil</v-icon>
                    Update</v-btn
                  >
                </v-card-actions>
              </v-form>
              <v-card-actions class="py-0">
                <v-card color="#e1ffef" class="px-2" outlined style="border: 1px solid #00a650">
                  <span
                    class="FontSizeSmall"
                    v-text="
                      'This webhook will be invoked as and when a posting of the transaction into the blockchain gets committed (mined). The content of the original transaction, along with the transaction id in the blockchain will be passed in this webhook to a 3rd party system referred to by the URL'
                    "
                  ></span>
                </v-card>
              </v-card-actions>
              <v-card-actions class="py-0">
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" class="mt-n6">
            <v-card outlined class="ma-4 pa-2">
              <v-card-title class="py-0">Wallet Address</v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions class="py-0">
                <div class="xmediumFontSize font-weight-bold mb-4 ml-2">Public Key :</div>
                <div class="xmediumFontSize font-weight-bold mb-4 ml-4">{{$store.getters.get_current_user_details.issuer_details.public_key}}</div>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" class="mt-n6">
            <v-card outlined class="ma-4 pa-2">
              <v-card-title class="py-0">Encryption</v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-card-text class="py-0">
                <v-checkbox @change="changeEncryptData()" label="Encrypt Data On Blockchain" v-model="should_encrypt_data"></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-x-transition>
  </div>
</template>
<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
import Snackbar from "@/components/Extras/Snackbar.vue";
import RegenerateAPIIDWarning from "@/components/Payload/Dialogs/RegenerateAPIIDWarning.vue";
import { updateIssuerURLsAndAPIId } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Overlay from "@/components/Extras/Overlay.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
export default {
  components: {
    Overlay,
    Snackbar,
    RegenerateAPIIDWarning,
  },
  mixins: [GetCurrentUser],
  data: () => ({
    overlay: false,
    Expand: false,
    loadingWEBHOOK_URL: false,
    loadinguser_id: false,
    tableHeader: [
      { text: "Input", value: "Input" },
      { text: "Parameter", value: "Parameter" },
    ],
    SnackBarComponent: {},
    postman_url: "",
    webhook_url: "",
    RegenerateAPIIDWarningDialog: false,
    api_key: "****************************************",
    issuer_api_id: "****************************************************",
    should_encrypt_data: false,
  }),
  watch: {},
  mounted() {
    this.GetCurrentUser();
    this.postman_url = this.$store.getters.get_current_user_details.issuer_details.postman_url;
    this.webhook_url = this.$store.getters.get_current_user_details.issuer_details.webhook_url != undefined ? this.$store.getters.get_current_user_details.issuer_details.webhook_url : "";
    this.should_encrypt_data =
      this.$store.getters.get_current_user_details.issuer_details.should_encrypt_data != undefined ? this.$store.getters.get_current_user_details.issuer_details.should_encrypt_data : false;
    this.$refs.form.resetValidation();
    console.warn("GC",this.$store.getters.get_current_user_details)
  },
  methods: {
    changeEncryptData() {
      if (this.should_encrypt_data != undefined && this.should_encrypt_data != null) {
        this.updateAPIIDAndWebhookURL("ISSUER_ENCRYPTION");
      }
    },
    onCopyapiid() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Issuer API ID Copied Successfully !!",
      };
    },
    onCopy() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "API Key Copied Successfully !!",
      };
    },
    mouseover(ACTION) {
      switch (ACTION) {
        case "APIKEY":
          this.api_key = this.$store.getters.get_current_user_details.issuer_details.api_key;
          break;
        case "APIID":
          this.issuer_api_id = this.$store.getters.get_current_user_details.issuer_details.issuer_api_id;
          break;
      }
    },
    mouseleave(ACTION) {
      switch (ACTION) {
        case "APIKEY":
          this.api_key = "****************************************";
          break;
        case "APIID":
          this.issuer_api_id = "****************************************************";
          break;
      }
    },
    RegenerateAPIIDWarningDialogEmit(Toggle) {
      this.RegenerateAPIIDWarningDialog = false;
      if (Toggle == 2) {
        this.updateAPIIDAndWebhookURL("ISSUER_API_ID");
      }
    },
    async updateAPIIDAndWebhookURL(update_type) {
      try {
        this[`loading${update_type}`] = true;
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          update_type: update_type,
        };
        if (update_type == "WEBHOOK_URL") {
          inputParams.update_url = this.webhook_url;
        }
        if (update_type == "ISSUER_ENCRYPTION") {
          inputParams.should_encrypt_data = this.should_encrypt_data;
        }
        this.loadingWebhookURL = true;
        let result = await API.graphql(
          graphqlOperation(updateIssuerURLsAndAPIId, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuerURLsAndAPIId);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.GetCurrentUser();
        }
        this[`loading${update_type}`] = false;
      } catch (error) {
        this[`loading${update_type}`] = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.updateAPIIDAndWebhookURL("WEBHOOK_URL");
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Webhook URL",
        };
      }
    },
    OpenNewWindow() {
      window.open(this.postman_url);
    },
  },
};
</script>
