export const DesignTemplate = {
  data() {
    return {};
  },
  methods: {
    DesignTemplateMethod() {
      this.template_html = `
      <div class="row" style="margin:10px;background-color:${this.settings.background_color_settings.has_background_color == true ? this.settings.background_color_settings.background_color : "white"};height:1060px;border:${this.settings.border_settings.has_border == true ? `${this.settings.border_settings.border_size} solid ${this.settings.border_settings.border_color}` : ""};border-spacing: 15px;">
      <div class="ql-align-left col-md-${this.settings.header_settings.middle_header.has_middle_header == false && this.settings.header_settings.right_header.has_right_header == true? "6": this.settings.header_settings.middle_header.has_middle_header == false && this.settings.header_settings.right_header.has_right_header == false? "12": "4"}">
         ${this.settings.header_settings.left_header.has_left_header == true? `${this.settings.header_settings.left_header.left_header_type == "TEXT"? this.settings.header_settings.left_header.left_header_content: `<img style="width:150px" src="${this.settings.header_settings.left_header.left_header_content}"/>`}`: ""}
      </div>
      <div class="ql-align-center col-md-${this.settings.header_settings.middle_header.has_middle_header == false ? "0" : "4"}">
         ${this.settings.header_settings.middle_header.has_middle_header == true? `${this.settings.header_settings.middle_header.middle_header_type == "TEXT"? this.settings.header_settings.middle_header.middle_header_content: `<img style="width:150px" src="${this.settings.header_settings.middle_header.middle_header_content}"/>`}`: ""}
      </div>
      <div class="ql-align-right col-md-${this.settings.header_settings.middle_header.has_middle_header == false ? "6" : "4"}">
          ${this.settings.header_settings.right_header.has_right_header == true? `${this.settings.header_settings.right_header.right_header_type == "TEXT"? this.settings.header_settings.right_header.right_header_content: `<img style="width:150px" src="${this.settings.header_settings.right_header.right_header_content}"/>`}`: ""}
      </div>
      <div class="ql-align-center col-md-12" style="margin-top:200px">
          ${this.settings.text_settings.before_image_before_text.has_before_image_before_text_content == true ? this.settings.text_settings.before_image_before_text.text_content : ""}
          ${this.settings.image_text_before_content.has_image_text == true? this.settings.image_text_before_content.image_text_type == "IMAGE"? `<img style="width:250px" src="${this.settings.image_text_before_content.image_text_content}"/>`: this.settings.image_text_before_content.image_text_content: ""}
          ${this.settings.text_settings.before_image_after_text.has_before_image_after_text_content == true ? this.settings.text_settings.before_image_after_text.text_content : ""}
        </div>
      <div class="col-md-12">
          ${this.settings.text_settings.main_content.has_main_content == true ? this.settings.text_settings.main_content.text_content : ""}
      </div>
      <div class="ql-align-center col-md-12">
          ${this.settings.text_settings.after_image_before_text.has_after_image_before_text_content == true ? this.settings.text_settings.after_image_before_text.text_content : ""}
          ${this.settings.image_text_after_content.has_image_text == true? this.settings.image_text_after_content.image_text_type == "IMAGE"? `<img style="width:150px" src="${this.settings.image_text_after_content.image_text_content}"/>`: this.settings.image_text_after_content.image_text_content: ""}
          ${this.settings.text_settings.after_image_after_text.has_after_image_after_text_content == true ? this.settings.text_settings.after_image_after_text.text_content : ""}
        </div>
      <div class="ql-align-left col-md-6">
          ${this.settings.text_settings.before_signature.has_before_signature_text == true && this.settings.signature_settings.has_signature == true && this.settings.signature_settings.has_signature == true && this.settings.signature_settings.signature_alignment == "LEFT"? this.settings.text_settings.before_signature.text_content: ""}
          ${this.settings.signature_settings.has_signature == true && this.settings.signature_settings.signature_alignment == "LEFT"? `<img style="width:150px" src="${this.settings.signature_settings.signature_url}" />`: ""}
          ${this.settings.text_settings.after_signature.has_after_signature_text == true && this.settings.signature_settings.has_signature == true && this.settings.signature_settings.has_signature == true && this.settings.signature_settings.signature_alignment == "LEFT" ? this.settings.text_settings.after_signature.text_content: ""}
      </div>
          <div class="ql-align-right col-md-6">
          ${this.settings.text_settings.before_signature.has_before_signature_text == true && this.settings.signature_settings.has_signature == true && this.settings.signature_settings.has_signature == true && this.settings.signature_settings.signature_alignment == "RIGHT"? this.settings.text_settings.before_signature.text_content: ""}
          ${this.settings.signature_settings.has_signature == true && this.settings.signature_settings.signature_alignment == "RIGHT"? `<img style="width:150px" src="${this.settings.signature_settings.signature_url}" />`: ""}
          ${this.settings.text_settings.after_signature.has_after_signature_text == true && this.settings.signature_settings.has_signature == true && this.settings.signature_settings.has_signature == true && this.settings.signature_settings.signature_alignment == "RIGHT"? this.settings.text_settings.after_signature.text_content: ""}
        </div>
        <div style="margin-top:250px" class="ql-align-left col-md-${this.settings.footer_settings.middle_footer.has_middle_footer == false ? "6" : "4"}">
          ${this.settings.footer_settings.left_footer.has_left_footer == true? `${this.settings.footer_settings.left_footer.left_footer_type == "TEXT"? this.settings.footer_settings.left_footer.left_footer_content: `<img style="width:150px" src="${this.settings.footer_settings.left_footer.left_footer_content}"/>`}`: ""}
        </div>
        <div style="margin-top:250px" class="ql-align-center col-md-${this.settings.footer_settings.middle_footer.has_middle_footer == false ? "0" : "4"}">
            ${this.settings.footer_settings.middle_footer.has_middle_footer == true? `${this.settings.footer_settings.middle_footer.middle_footer_type == "TEXT"? this.settings.footer_settings.middle_footer.middle_footer_content: `<img style="width:150px" src="${this.settings.footer_settings.middle_footer.middle_footer_content}"/>`}`: ""}
        </div>
        <div style="margin-top:250px" class="ql-align-right col-md-${this.settings.footer_settings.middle_footer.has_middle_footer == false ? "6" : "4"}">
            ${this.settings.footer_settings.right_footer.has_right_footer == true? `${this.settings.footer_settings.right_footer.right_footer_type == "TEXT"? this.settings.footer_settings.right_footer.right_footer_content: `<img style="width:150px" src="${this.settings.footer_settings.right_footer.right_footer_content}"/>`}`: ""}
         </div>
      </div>
      `;
      console.warn("template_html",this.template_html)
    },
  },
};
