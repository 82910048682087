<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogChangeOwnership" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="secondary">
          <div>Transfer Ownership</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogChangeOwnershipEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center">
          <v-icon class="mt-2" size="50px" color="primary">mdi-account-star</v-icon>
          <div>Make {{StoreObj.user_name}} as Owner ?</div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius  text-capitalize"  color="secondary" outlined @click="dialogChangeOwnershipEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="mr-2 borderRadius  text-capitalize" :loading="loading" color="secondary" @click="transferOwnershipMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { transferOwnership } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogChangeOwnership: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading:false,
    SnackBarComponent: {},
  }),
  watch: {},
  methods: {
    async transferOwnershipMethod() {
      try {
        var inputParams = {
          user_id: this.StoreObj.user_id,
          admin_user_id: this.$store.getters.get_current_user_details.user_id,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(transferOwnership, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.TransferOwnership);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogChangeOwnershipEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.warn(error)
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogChangeOwnershipEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
