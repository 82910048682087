<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogAddText" persistent max-width="800px">
      <v-card class="overflow-hidden">
        <v-toolbar dark dense color="primary" class="elevation-0">
          <div
            v-if="StoreObj.position != undefined && StoreObj.type != undefined"
          >
            Add
            {{
              StoreObj.type.slice(0, 1) + StoreObj.type.slice(1).toLowerCase()
            }}
            to Page {{ fromPage }}
          </div>
          <v-spacer></v-spacer>
          <v-btn small class="text-capitalize" outlined @click="clearFields()"
            >Clear All</v-btn
          >
          <v-btn icon @click="dialogAddTextEmit((Toggle = 1))">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div v-if="StoreObj.type != undefined" class="font-weight-bold mt-4">
            Content Type
          </div>
          <v-form ref="formActiveFields" @submit.prevent>
            <v-radio-group
              label=""
              :rules="[(v) => !!v || 'Required']"
              dense
              class="my-0 py-0"
              v-model="addText.content_type"
              row
            >
              <v-radio label="Active Field" value="ACTIVE_FIELD"></v-radio>
              <v-radio label="Issuer Name" value="BUSINESS_NAME"></v-radio>
              <v-radio label="Active Image" value="ACTIVE_IMAGE"></v-radio>
            </v-radio-group>
            <v-card-actions class="pa-0">
              <v-text-field
                dense
                outlined
                class="FontSize field_height field_label_size mr-2"
                :rules="[
                  (v) => !!v || 'Required',
                  (v) => (v && v.length < 51) || 'Invalid',
                ]"
                label="Active Data Field"
                @keydown.enter="validateActiveFieldsMethod()"
                v-model="add_active_field.field_name"
              ></v-text-field>
              <v-btn
                small
                class="borderRadius mt-n6 text-capitalize"
                color="primary"
                @click="validateActiveFieldsMethod()"
              >
                <v-icon small>mdi-plus</v-icon>
                Add</v-btn
              >
            </v-card-actions>
          </v-form>
          <vue-editor
            ref="mytextarea"
            @focus="toggleFocus($event)"
            @blur="toggleFocus($event, false)"
            :editorToolbar="customToolbar"
            v-model="addText.content"
            placeholder="Add Content"
            class="mt-4"
          ></vue-editor>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            color="primary"
            @click="addTextMethod"
          >
            <v-icon small>mdi-plus</v-icon>
            Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
var striptags = require("striptags");
import Snackbar from "@/components/Extras/Snackbar.vue";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
    Snackbar,
  },
  props: {
    StoreObj: Object,
    dialogAddText: Boolean,
    fromPage: Number,
    pageItems: Array,
  },
  data: () => ({
    addText: {
      content: "",
      content_type: "ACTIVE_FIELD",
    },
    add_active_field: {
      field_name: "",
    },
    uploadedImage: false,
    typeItems: ["IMAGE", "TEXT"],
    customToolbar: [
      // [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline"],
      [{ color: [] }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
    ],
    allActiveFields: [],
    SnackBarComponent: {},
    selectedText: "",
  }),
  watch: {
    "addText.content"(val) {
      console.log("Check Cursor Pointer", val);
      console.log("Pawri Ho Rahi Hain", striptags(val));
    },
    "addText.content_type"(val) {
      if (val == "BUSINESS_NAME") {
        this.add_active_field.field_name = `ISSUER NAME`;
      } else if (val == "ACTIVE_FIELD") {
        this.add_active_field.field_name = "";
        if (this.$refs.formActiveFields != undefined) {
          this.$refs.formActiveFields.resetValidation();
        }
      } else if (val == "ACTIVE_IMAGE") {
        this.add_active_field.field_name = `ACTIVE IMAGE`;
      }
    },
    dialogAddText(val) {
      if (val == true) {
        this.addText.content_type = "ACTIVE_FIELD";
        if (this.StoreObj.content_present == true) {
          this.addText.content = this.StoreObj.content;
          this.addText.content_type = this.StoreObj.content_type;
          if (this.addText.content.includes("ISSUER NAME")) {
            this.addText.content = this.addText.content.replaceAll(
              "ISSUER NAME",
              this.$store.getters.get_current_user_details.issuer_details
                .issuer_name
            );
            console.warn("Check Content", this.addText.content);
          }
          this.calculateActiveFieldsMethod();
        }
      }
    },
  },
  methods: {
    toggleFocus(event) {
      let rawContent = striptags(this.addText.content);
      console.log("Raw Content", rawContent);
      console.log("Check The Event", event.selection.savedRange.index);
      this.currentCursorIndex = event.selection.savedRange.index;
      let filterHtml = this.addText.content.split(">").length;
      console.log("Filtered Length", filterHtml);
      console.log(
        "Check Sub String",
        this.addText.content.substring(0, this.currentCursorIndex)
      );
      console.log(
        "Check Sub String",
        this.addText.content.substring(
          this.currentCursorIndex,
          this.addText.content.length - 1
        )
      );
    },
    calculateActiveFieldsMethod() {
      this.allActiveFields = [];
      for (let i = 0; i < this.addText.content.split("}").length; i++) {
        if (this.addText.content.split("}")[i].includes("{")) {
          if (this.addText.content.split("}")[i].split("{")[1] != "") {
            this.allActiveFields.push(
              this.addText.content.split("}")[i].split("{")[1]
            );
          }
        }
      }
      // this.$emit("activefieldsUpdate", this.allActiveFields);
    },
    validateActiveFieldsMethod() {
      // if (this.$refs.formActiveFields.validate()) {
      if (this.addText.content_type == "ACTIVE_FIELD") {
        if (
          this.addText.content != "" &&
          this.addText.content != undefined &&
          this.addText.content != null
        ) {
          if (
            document.getSelection()["anchorNode"]["className"] !=
            "v-text-field__slot"
          ) {
            let range = document.getSelection().getRangeAt(0);
            range.deleteContents();
            range.insertNode(
              document.createTextNode(
                "${" + this.add_active_field.field_name + "}"
              )
            );
            window.getSelection()?.removeAllRanges();
          } else {
            let fullString = `${this.addText.content} ${this.add_active_field.field_name}`;
            this.addText.content = fullString.replace(
              `</p> ${this.add_active_field.field_name}`,
              "${" + `${this.add_active_field.field_name}}</p>`
            );
          }
          // this.$refs.formActiveFields.reset();
          this.add_active_field.field_name = "";
        } else {
          this.addText.content =
            "${" + `${this.add_active_field.field_name}` + "}";
          // this.$refs.formActiveFields.reset();
          this.add_active_field.field_name = "";
        }
        this.calculateActiveFieldsMethod();
      } else if (this.addText.content_type == "BUSINESS_NAME") {
        if (
          this.addText.content != "" &&
          this.addText.content != undefined &&
          this.addText.content != null
        ) {
          let fullString = `${this.addText.content} ${this.add_active_field.field_name}`;
          this.addText.content = fullString.replace(
            `</p> ${this.add_active_field.field_name}`,
            `<b>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</b>`
          );
          this.$refs.formActiveFields.reset();
        } else {
          this.addText.content = `<b>${this.$store.getters.get_current_user_details.issuer_details.issuer_name}</b>`;
          this.$refs.formActiveFields.reset();
        }
      } else if (this.addText.content_type == "ACTIVE_IMAGE") {
        // =======================================================================================
        if (
          this.addText.content != "" &&
          this.addText.content != undefined &&
          this.addText.content != null
        ) {
          if (
            document.getSelection()["anchorNode"]["className"] !=
            "v-text-field__slot"
          ) {
            let range = document.getSelection().getRangeAt(0);
            range.deleteContents();
            range.insertNode(document.createTextNode("${" + "ACTIVE IMAGE}"));
            window.getSelection()?.removeAllRanges();
          } else {
            let fullString = `${this.addText.content}` + "${" + "ACTIVE IMAGE}";
            this.addText.content = fullString;
          }

          // let fullString = `${this.addText.content} ${this.add_active_field.field_name}`;
          // this.addText.content = fullString.replace(
          //   ` ${this.add_active_field.field_name}`,
          //   "${" + "ACTIVE IMAGE}"
          // );

          this.$refs.formActiveFields.reset();
        } else {
          this.addText.content = "${" + `ACTIVE IMAGE` + "}";
          this.$refs.formActiveFields.reset();
        }
        // =======================================================================================
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Field Name",
        };
      }
    },
    checkImagePresent() {
      if (
        this.addText.content == null ||
        this.addText.content == "" ||
        this.addText.content == undefined
      ) {
        this.handleUpload();
      }
    },
    clearFields() {
      this.addText = {
        content: "",
        content_type: "ACTIVE_FIELD",
      };
      this.dialogAddTextEmit((this.Toggle = 2), this.addText);
      this.$refs.formActiveFields.resetValidation();
    },
    addTextMethod() {
      let PageItems = Array.from(this.pageItems);
      let existingActiveFieldArray = [];

      for (let i = 0; i < PageItems.length; i++) {
        if (
          PageItems[i].templateItems[4].content != "" &&
          i + 1 != this.fromPage
        ) {
          let MyLoop = PageItems[i].templateItems[4].content;
          for (let j = 0; j < MyLoop.split("}").length; j++) {
            if (MyLoop.split("}")[j].includes("{")) {
              if (MyLoop.split("}")[j].split("{")[1] != "") {
                existingActiveFieldArray.push(
                  MyLoop.split("}")[j].split("{")[1]
                );
              }
            }
          }
        }
      }

      this.calculateActiveFieldsMethod();

      this.allActiveFields = Array.from(this.allActiveFields);
      if (
        this.allActiveFields.length != 0 ||
        existingActiveFieldArray.length != 0
      ) {
        if (this.addText.content != undefined && this.addText.content != null) {
          let addText = { ...this.addText };
          this.dialogAddTextEmit((this.Toggle = 2), addText);
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Add Active Fields",
        };
      }
    },
    dialogAddTextEmit(Toggle, content) {
      this.addText.content_type = "";
      this.addText.content = "";
      this.$emit("clicked", Toggle, content, this.fromPage);
    },
  },
};
</script>
