<template>
  <div>
    <v-dialog v-model="RegenerateAPIIDWarningDialog" persistent max-width="400px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>Regenerate API ID</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="RegenerateAPIIDWarningDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-6 text-center">
          <v-icon color="primary" size="50px">mdi-key-variant</v-icon>
          <div class="mt-3">Are you sure you want to regenerate the API Id ? Existing usage of the API Id in 3rd party systems need to be updated accordingly</div>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" outlined @click="RegenerateAPIIDWarningDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="borderRadius fontStyle text-capitalize"  color="secondary" @click="RegenerateAPIIDWarningDialogEmit((Toggle = 2))"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    RegenerateAPIIDWarningDialog: Boolean,
  },
  data: () => ({
    Toggle: 0,
  }),
  watch: {},
  methods: {
    RegenerateAPIIDWarningDialogEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
