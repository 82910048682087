<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <InvalidReasonsDialog :dataObj="dataObj" :dialogInvalidReasons="dialogInvalidReasons" @clicked="dialogInvalidReasonsEmit" />
    <EditInvalidDataDialog :dataObj="dataObj" :dialogEditInvalidData="dialogEditInvalidData" @clicked="dialogEditInvalidDataEmit" />
    <v-dialog v-model="dialogValidInvalidData" persistent fullscreen>
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>Excel Data</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogValidInvalidDataEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-6">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="3" v-if="validDataTable.length == 0">
              <v-card outlined>
                <v-card-text outlined>
                  <div class="font-weight-bold pb-4">Please Fill Active Fields</div>
                  <v-select
                    v-for="(field, idx) in template_fields"
                    :key="idx"
                    dense
                    v-show="field != 'Name' && field != 'Email ID' && field != 'Country Code' && field != 'Mobile Number'"
                    outlined
                    class="field_height field_label_size FontSize"
                    :label="field"
                    v-model="templateFieldsObject[field]"
                    :items="tableHeader"
                  ></v-select>
                  <div class="font-weight-bold pb-4">'Issue To' Details</div>
                  <v-select
                    v-for="field in template_fields"
                    :key="field"
                    dense
                    v-show="field == 'Name' || field == 'Email ID' || field == 'Country Code' || field == 'Mobile Number'"
                    outlined
                    class="field_height field_label_size FontSize"
                    :label="field"
                    v-model="templateFieldsObject[field]"
                    :items="tableHeader"
                  ></v-select>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" @click="checkData()">Validate</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" :md="validDataTable.length == 0 ? 9 : 12">
              <v-card class="elevation-0" v-if="validDataTable.length != 0 || invalidDataTable.length != 0">
                <v-tabs v-model="tableDataTab">
                  <v-tab>Valid Data</v-tab>
                  <v-tab>Invalid Data</v-tab>
                </v-tabs>
                <v-data-table
                  dense
                  class="elevation-0"
                  :headers="tableDataTab == 0 ? headers.filter((item) => item.text != 'Action') : headers"
                  :items="tableDataTab == 0 ? validDataTable : invalidDataTable"
                >
                  <template v-slot:[`item.Action`]="{ item }">
                    <v-icon small class="mr-2" color="primary" @click="checkItem(item, 'INFORMATION')">mdi-information</v-icon>
                    <v-icon small color="primary" @click="checkItem(item, 'EDIT')">mdi-pencil</v-icon>
                  </template>
                </v-data-table>
              </v-card>
              <v-card v-else outlined height="100%">
                <v-card-text class="font-weight-bold text-center mt-16"> Please Fill All The Active Fields To Preview All The Excel Data </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4" v-if="tableDataTab == 0">
          <v-spacer></v-spacer>
          <v-btn small outlined class="borderRadius fontStyle text-capitalize" color="secondary" @click="dialogValidInvalidDataEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-check</v-icon>Cancel</v-btn
          >
          <v-btn small :loading="loading" class="borderRadius fontStyle text-capitalize" color="secondary" @click="issueInBulkMethod()"
            ><v-icon small class="mr-1">mdi-cloud-upload</v-icon>Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { issueCredentialsInBulk } from "@/graphql/mutations.js";
import InvalidReasonsDialog from "@/components/Dashboard/Dialogs/InvalidReasonsDialog.vue";
import EditInvalidDataDialog from "@/components/Dashboard/Dialogs/EditInvalidDataDialog.vue";
export default {
  props: {
    dialogValidInvalidData: Boolean,
    tableData: Array,
    tableHeader: Array,
    StoreObj: Object,
  },
  components: {
    InvalidReasonsDialog,
    EditInvalidDataDialog,
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
    dialogInvalidReasons: false,
    dialogEditInvalidData: false,
    tableDataTab: 0,
    templateFieldsObject: {},
    issue_to_settings: "SINGLE",
    template_fields: [],
    headers: [],
    validDataTable: [],
    invalidDataTable: [],
    dataObj: {},
  }),
  watch: {
    dialogValidInvalidData(val) {
      if (val == true) {
        this.template_fields = [];
        console.log("template_fields", this.template_fields);
        this.tableDataTab = 0;
        this.validDataTable = [];
        this.invalidDataTable = [];
        this.templateFieldsObject = {};
        this.template_fields = this.StoreObj.template_fields;
        if (this.template_fields.filter((item) => item == "Email ID").length == 0) {
          this.template_fields.push("Name", "Email ID", "Country Code", "Mobile Number");
        }
        console.log("template_fields", this.template_fields);
      }
    },
  },
  methods: {
    dialogEditInvalidDataEmit(Toggle, dataObj) {
      console.log("dataObj", dataObj);
      this.dialogEditInvalidData = false;
      if (Toggle == 2) {
        this.validDataTable.push(dataObj);
        this.invalidDataTable.splice(this.invalidDataTable.indexOf(dataObj));
      }
    },
    dialogInvalidReasonsEmit() {
      this.dialogInvalidReasons = false;
    },
    checkItem(item, action) {
      this.dataObj = item;
      if (action == "INFORMATION") {
        this.dialogInvalidReasons = true;
      } else if (action == "EDIT") {
        this.dialogEditInvalidData = true;
      }
    },
    async issueInBulkMethod() {
      let d = new Date();
      let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let date = d.getDate() + "-" + monthNames[d.getMonth()] + "-" + d.getFullYear() + "  " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          template_id: this.StoreObj.template_id,
          should_create_pdf: true,
          push_to_blockchain: true,
          credentials_array: JSON.stringify(this.validDataTable),
          date: date,
          month: `${d.getFullYear()}-${d.getMonth() + 1}`,
        };
        console.log("inputParams", inputParams);
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(issueCredentialsInBulk, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.IssueCredentialsInBulk);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogValidInvalidDataEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    checkData() {
      this.headers = [];
      let empty_fields = false;
      console.log("Check templateFieldsObject", this.templateFieldsObject);
      if (Object.keys(this.templateFieldsObject).length == 0) {
        empty_fields = true;
      } else {
        for (let key in this.templateFieldsObject) {
          if (key != "Mobile Number" && key != "Email ID") {
            if (this.templateFieldsObject[key] == undefined || this.templateFieldsObject[key] == "") {
              empty_fields = true;
            }
          }
        }
      }

      if (empty_fields == true) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please fill up all the Active fields Or Issued To Name",
        };
      } else {
        for (let key in this.templateFieldsObject) {
          if (this.templateFieldsObject[key] != "" && this.templateFieldsObject[key] != null && this.templateFieldsObject[key] != undefined) {
            this.headers.push({
              text: key.replace("_", " "),
              value: key,
            });
          }
        }
        this.headers.push({ text: "Action", value: "Action" });
        let formTable = [];
        for (let i = 0; i < this.tableData.length; i++) {
          let dataObject = {};
          for (let key in this.templateFieldsObject) {
            if (this.templateFieldsObject[key] && this.templateFieldsObject[key] != "") {
              dataObject[key] = this.tableData[i][this.templateFieldsObject[key]];
            }
          }
          formTable.push(dataObject);
        }
        for (let i = 0; i < formTable.length; i++) {
          for (let key in formTable[i]) {
            if (formTable[i][key] == undefined) {
              formTable[i]["reasons"] = [];
              formTable[i].reasons.push(`${key} does not have value`);
            }
          }
        }
        this.validDataTable = formTable.filter((item) => item.reasons == undefined);
        this.invalidDataTable = formTable.filter((item) => item.reasons != undefined);
        console.log("validDataTable", this.validDataTable);
      }
    },
    dialogValidInvalidDataEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
