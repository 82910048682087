var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Snackbar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('IssueTemplatizedCredentialsDialog',{attrs:{"StoreObj":_vm.StoreObj,"AllActiveFields":_vm.AllActiveFields,"dialogIssueTemplatizedCredential":_vm.dialogIssueTemplatizedCredential},on:{"clicked":_vm.dialogIssueTemplatizedCredentialEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"largeFontSizeNew"},[_vm._v(_vm._s(_vm.StoreObj.template_name))])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),(_vm.buttonToggle == true)?_c('v-btn',{staticClass:"borderRadius mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.dialogIssueTemplatizedCredential = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-bookmark-check")]),_vm._v("Issue")],1):_vm._e(),_c('v-btn',{staticClass:"borderRadius",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.FillPDFFieldsEmit((_vm.Toggle = 1))}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-chevron-double-left")]),_vm._v("Back")],1)],1)],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":_vm.pageItems[0] != undefined
              ? _vm.pageItems[0].page_type == 'L'
                ? '12'
                : '3'
              : '3'}},[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"no-gutters":""}},[(
                  _vm.pageItems[0] != undefined
                    ? _vm.pageItems[0].page_type == 'L'
                    : false
                )?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"4"}}):_vm._e(),_vm._l((_vm.AllActiveFields.filter(
                  function (item) { return item != 'BUSINESS_LOGO'; }
                )),function(field,idx){return _c('v-col',{key:idx,attrs:{"cols":"12","xs":"12","sm":"12","md":_vm.pageItems[0] != undefined
                    ? _vm.pageItems[0].page_type == 'L'
                      ? '4'
                      : '12'
                    : '12'}},[(field.field_label != 'ACTIVE IMAGE')?_c('v-text-field',{staticClass:"FontSize field_height field_label_size",attrs:{"dense":"","outlined":"","rules":[function (v) { return !!v || 'Required'; }],"label":field.field_label},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}}):_c('v-file-input',{staticClass:"FontSize field_label_size",attrs:{"dense":"","outlined":"","label":field.field_label},on:{"change":_vm.callS3UploadMethod},model:{value:(_vm.formfields[field.field_label]),callback:function ($$v) {_vm.$set(_vm.formfields, field.field_label, $$v)},expression:"formfields[field.field_label]"}})],1)})],2)],1),_c('v-card-actions',[_c('v-row',{staticClass:"mb-5",attrs:{"justify":"center"}},[(_vm.buttonToggle == false)?_c('v-btn',{staticClass:"borderRadius",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.validateMethod()}}},[_vm._v("Proceed")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":_vm.pageItems[0] != undefined
              ? _vm.pageItems[0].page_type == 'L'
                ? '12'
                : '9'
              : '9'}},[_c('v-card',{attrs:{"elevation":"2","color":"#e5f6ed"}},[_c('v-chip',{staticClass:"mt-n8",staticStyle:{"font-size":"13px","position":"absolute","left":"50%","transform":"translateX(-50%)"},attrs:{"small":"","color":"#37474F","dark":""}},[_vm._v(" Page "+_vm._s(_vm.currentPage)+"/"+_vm._s(_vm.totalPage)+" ")]),_c('v-virtual-scroll',{attrs:{"items":_vm.pageItems,"height":_vm.height,"item-height":_vm.pageItems[0] != undefined
                  ? _vm.pageItems[0].page_type == 'P'
                    ? 1150
                    : 820
                  : 0,"bench":100},on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var item = ref.item;
return [_c('v-card',{staticClass:"overflow-y-auto center elevation-2 mt-5",staticStyle:{"padding-top":"1px"},attrs:{"tile":"","color":_vm.pageItems[0].templateItems[11].content,"height":item.page_type != undefined
                      ? item.page_type == 'P'
                        ? '1123'
                        : _vm.pageItems[0].templateItems[10].content_present ==
                          false
                        ? '796'
                        : '786'
                      : '0',"width":item.page_type != undefined
                      ? item.page_type == 'P'
                        ? '793'
                        : '1123'
                      : '0',"id":("DisplayPage" + (item.page_number))}},[_c('div',{domProps:{"innerHTML":_vm._s(item.template_html)}})])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }