var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('AddIssuerUserDialog',{attrs:{"dialogAddIssuer":_vm.dialogAddIssuer},on:{"clicked":_vm.dialogCreateUsersEmit}}),_c('ActivateInactivateUserDialog',{attrs:{"dialogActivateInactivateUser":_vm.dialogActivateInactivateUser,"StoreObj":_vm.StoreObj},on:{"clicked":_vm.dialogActivateInactivateUserEmit}}),_c('ChangeOwnershipDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogChangeOwnership":_vm.dialogChangeOwnership},on:{"clicked":_vm.dialogChangeOwnershipEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"largeFontSizeNew"},[_vm._v("Users")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"borderRadius text-capitalize",attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.dialogAddIssuer = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Create User")],1)],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-expand-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.userTableExpand),expression:"userTableExpand"}],attrs:{"outlined":""}},[(_vm.userTableExpand==true)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.GetIssuerListHeaders,"items":_vm.GetAllIssuerUsersList,"no-data-text":_vm.noDataText},scopedSlots:_vm._u([{key:"item.sl_no",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(_vm.GetAllIssuerUsersList.indexOf(item) + 1))])]}},{key:"item.user_email_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.user_email_id))])]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.user_name))])]}},{key:"item.user_created_on",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(new Date(item.user_created_on).toLocaleString()))])]}},{key:"item.user_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.user_type.replace("_", " ")))])]}},{key:"item.user_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize",class:item.user_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'},[_vm._v(" "+_vm._s(item.user_status)+" ")])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.user_id != _vm.$store.getters.get_current_user_details.user_id && _vm.$store.getters.get_current_user_details.user_type == 'OWNER')?_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"icon":"","color":item.user_status == 'ACTIVE' ? 'red' : 'green'},on:{"click":function($event){return _vm.checkItem(item, 'ACTION')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(item.user_status == 'ACTIVE' ? 'mdi-account-cancel' : 'mdi-account-plus')}})],1):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.user_status == 'ACTIVE' ? ("Deactivate " + (item.user_name)) : ("Activate " + (item.user_name)))}})]),(_vm.$store.getters.get_current_user_details.user_type == 'OWNER')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.user_id != _vm.$store.getters.get_current_user_details.user_id)?_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.checkItem(item, 'OWNERSHIP')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-account-star')}})],1):_vm._e()]}}],null,true)},[_c('span',{attrs:{"align":"center"}},[_vm._v("Transfer Ownership To "+_vm._s(item.user_name))])]):_vm._e()]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }