<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <IssueTemplatizedCredentialsDialog
      :StoreObj="StoreObj"
      :AllActiveFields="AllActiveFields"
      :dialogIssueTemplatizedCredential="dialogIssueTemplatizedCredential"
      @clicked="dialogIssueTemplatizedCredentialEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">{{ StoreObj.template_name }}</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn
              v-if="buttonToggle == true"
              small
              class="borderRadius mr-2"
              color="primary"
              @click="dialogIssueTemplatizedCredential = true"
              ><v-icon small class="mr-1">mdi-bookmark-check</v-icon
              >Issue</v-btn
            >
            <v-btn
              small
              @click="FillPDFFieldsEmit((Toggle = 1))"
              color="primary"
              class="borderRadius"
              ><v-icon small class="mr-1">mdi-chevron-double-left</v-icon
              >Back</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            :md="
              pageItems[0] != undefined
                ? pageItems[0].page_type == 'L'
                  ? '12'
                  : '3'
                : '3'
            "
          >
            <v-form ref="form">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="4"
                  v-if="
                    pageItems[0] != undefined
                      ? pageItems[0].page_type == 'L'
                      : false
                  "
                />
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  :md="
                    pageItems[0] != undefined
                      ? pageItems[0].page_type == 'L'
                        ? '4'
                        : '12'
                      : '12'
                  "
                  v-for="(field, idx) in AllActiveFields.filter(
                    (item) => item != 'BUSINESS_LOGO'
                  )"
                  :key="idx"
                >
                  <v-text-field
                    v-if="field.field_label != 'ACTIVE IMAGE'"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Required']"
                    class="FontSize field_height field_label_size"
                    :label="field.field_label"
                    v-model="field.field_value"
                  ></v-text-field>
                  <v-file-input
                    v-else
                    dense
                    outlined
                    class="FontSize field_label_size"
                    @change="callS3UploadMethod"
                    :label="field.field_label"
                    v-model="formfields[field.field_label]"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions>
              <v-row justify="center" class="mb-5">
                <v-btn
                  v-if="buttonToggle == false"
                  small
                  class="borderRadius"
                  color="primary"
                  @click="validateMethod()"
                  >Proceed</v-btn
                >
              </v-row>
            </v-card-actions>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            :md="
              pageItems[0] != undefined
                ? pageItems[0].page_type == 'L'
                  ? '12'
                  : '9'
                : '9'
            "
          >
            <v-card elevation="2" color="#e5f6ed">
              <v-chip
                small
                class="mt-n8"
                color="#37474F"
                dark
                style="
                  font-size: 13px;
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                "
              >
                Page {{ currentPage }}/{{ totalPage }}
              </v-chip>
              <v-virtual-scroll
                :items="pageItems"
                :height="height"
                :item-height="
                  pageItems[0] != undefined
                    ? pageItems[0].page_type == 'P'
                      ? 1150
                      : 820
                    : 0
                "
                :bench="100"
                @scroll.passive="handleScroll"
              >
                <template v-slot:default="{ item }">
                  <v-card
                    tile
                    class="overflow-y-auto center elevation-2 mt-5"
                    :color="pageItems[0].templateItems[11].content"
                    :height="
                      item.page_type != undefined
                        ? item.page_type == 'P'
                          ? '1123'
                          : pageItems[0].templateItems[10].content_present ==
                            false
                          ? '796'
                          : '786'
                        : '0'
                    "
                    :width="
                      item.page_type != undefined
                        ? item.page_type == 'P'
                          ? '793'
                          : '1123'
                        : '0'
                    "
                    style="padding-top: 1px"
                    :id="`DisplayPage${item.page_number}`"
                  >
                    <div v-html="item.template_html"></div>
                  </v-card>
                </template>
              </v-virtual-scroll>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
var AWS = require("aws-sdk");
import IssueTemplatizedCredentialsDialog from "@/components/Dashboard/Dialogs/IssueTemplatizedCredentialsDialog";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
  },
  components: {
    Snackbar,
    IssueTemplatizedCredentialsDialog,
  },
  data: () => ({
    height: 0,
    Toggle: 0,
    formfields: {},
    template_html: "",
    SnackBarComponent: {},
    count: 0,
    buttonToggle: false,
    dialogIssueTemplatizedCredential: false,
    pageItems: [],
    AllActiveFields: [],
    currentPage: 0,
    totalPage: 0,
  }),
  mounted() {
    console.log(this.StoreObj);
    this.height = window.innerHeight - 150;
    this.pageItems = Array.from(this.StoreObj.settings);
    this.calculateActiveFields();
    setTimeout(() => this.AddPageData(), 1);

    // ---------------------------------------------------------------------------------
    for (let i = 0; i < this.pageItems.length; i++) {
      this.pageItems[i].template_html = this.pageItems[
        i
      ].template_html.replaceAll(
        "${BUSINESS_LOGO}",
        this.$store.getters.get_current_user_details.issuer_details
          .account_logo_url != undefined
          ? `<img style="width:150px" src="${this.$store.getters.get_current_user_details.issuer_details.account_logo_url}"/>`
          : "Please Upload Your Business Logo"
      );
    }
  },
  methods: {
    AddPageData() {
      this.overlay = true;
      for (let i = 0; i < this.pageItems.length; i++) {
        // BackGround Image Adding--------------------------------------------------
        if (this.pageItems[i].templateItems[11].imgSrc != "") {
          if (document.getElementById(`DisplayPage${i + 1}`) != null) {
            document.getElementById(
              `DisplayPage${i + 1}`
            ).style.backgroundSize =
              this.pageItems[i].page_type != undefined
                ? this.pageItems[i].page_type == "P"
                  ? "794px 1123px"
                  : "1123px 794px"
                : 0;
            document.getElementById(
              `DisplayPage${i + 1}`
            ).style.backgroundImage = `url(${this.pageItems[i].templateItems[11].imgSrc})`;
          }
        }
        // BackGround Image Removing------------------------------------------------
        else {
          if (document.getElementById(`DisplayPage${i + 1}`) != null) {
            document.getElementById(
              `DisplayPage${i + 1}`
            ).style.backgroundImage = null;
          }
        }
      }
      this.overlay = false;
    },
    calculateActiveFields() {
      this.AllActiveFields = [];
      let template_fields = [];
      this.StoreObj.settings.map((page) => {
        let sub_arrays = page.template_html.split("${");
        for (let i = 1; i < sub_arrays.length; i++) {
          template_fields.push(sub_arrays[i].split("}")[0]);
        }
      });
      this.AllActiveFields = [...new Set(template_fields)];
      template_fields = [];
      for (let j = 0; j < this.AllActiveFields.length; j++) {
        template_fields.push({
          field_label: this.AllActiveFields[j],
          field_value: "",
        });
      }
      this.AllActiveFields = template_fields;
      console.log("AllActiveFields-----------------", this.AllActiveFields);
    },
    callS3UploadMethod(field) {
      console.log("Getting Field", field);
      console.log("Check Fields", this.formfields);
      var self = this;
      var s3Bucket = new AWS.S3({
        region:
          self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId:
          self.$store.getters.get_current_user_details.s3_credentials
            .access_key,
        secretAccessKey:
          self.$store.getters.get_current_user_details.s3_credentials
            .secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(field);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket:
            self.$store.getters.get_current_user_details.s3_credentials
              .bucket_name,
          Key: `${
            self.$store.getters.get_current_user_details.issuer_id
          }/ACTIVE_IMAGE/${new Date().getTime()}_mySignature.png`,
          Body: buf,
          ContentType: field.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
          } else if (data) {
            let url = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
            console.log("Check The URL", url);
            for (let i = 0; i < self.AllActiveFields.length; i++) {
              if (self.AllActiveFields[i].field_label == "ACTIVE IMAGE") {
                self.AllActiveFields[i].field_value = url;
              }
            }
          }
        });
      };
    },
    dialogIssueTemplatizedCredentialEmit(Toggle, ResultObject) {
      this.dialogIssueTemplatizedCredential = false;
      if (Toggle == 2) {
        this.FillPDFFieldsEmit(Toggle, ResultObject);
      }
    },
    enterFieldsInPreview() {
      let ActiveFields = Array.from(this.AllActiveFields);
      console.log("ActiveFields", ActiveFields);
      // Replacing Active Fields in All Pages.................................
      for (let i = 0; i < ActiveFields.length; i++) {
        for (let j = 0; j < this.pageItems.length; j++) {
          let newHTML = "";
          // Image If available........
          if (ActiveFields[i].field_label == "ACTIVE IMAGE") {
            newHTML = this.pageItems[j].template_html.replaceAll(
              "${" + `${ActiveFields[i].field_label}` + "}",
              `<img style="width:150px" src="${ActiveFields[i].field_value}"/>`
            );
          } else {
            newHTML = this.pageItems[j].template_html.replaceAll(
              "${" + `${ActiveFields[i].field_label}` + "}",
              ActiveFields[i].field_value
            );
          }
          this.pageItems[j].template_html = newHTML;
        }
      }
      this.buttonToggle = true;
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        console.log(this.AllActiveFields);
        this.enterFieldsInPreview();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please fill up all the fields",
        };
      }
    },
    createFields() {
      this.formfields = {};
      for (let i = 0; i < this.StoreObj.template_fields.length; i++) {
        this.formfields[this.StoreObj.template_fields[i]] = "";
      }
      this.$forceUpdate();
    },
    FillPDFFieldsEmit(Toggle, ResultObject) {
      this.buttonToggle = false;
      this.$emit("clicked", Toggle, ResultObject);
    },
    handleScroll(e) {
      let pageBuffers = [];
      let eachPageHeigth = e.target.scrollHeight / this.pageItems.length;
      let currentScroll = e.target.scrollTop.toFixed();
      for (let i = 0; i < this.pageItems.length; i++) {
        pageBuffers.push(eachPageHeigth * (i + 1));
      }
      for (let j = 0; j < pageBuffers.length; j++) {
        if (
          currentScroll > pageBuffers[j] &&
          currentScroll < pageBuffers[j + 1]
        ) {
          this.currentPage = j + 2;
        } else if (currentScroll < pageBuffers[0]) {
          this.currentPage = 1;
        }
      }
      this.totalPage = this.pageItems.length;
    },
  },
};
</script>
