<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">Business Information</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" v-if="user_type == 'OWNER'">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn v-if="modify_boolean == true" small outlined color="primary" class="mr-2" @click="changeToDefaultData(), (modify_boolean = false)"
              ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
            >
            <v-btn v-if="modify_boolean == false" small color="primary" @click="modify_boolean = true"><v-icon small class="mr-1">mdi-pencil</v-icon>Edit</v-btn>
            <v-btn v-if="modify_boolean == true" small color="primary" :loading="loading" @click="validateMethod()"><v-icon small class="mr-1">mdi-content-save</v-icon>Submit Edit Request</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card class="overflow-hidden elevation-0" :height="height">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" :md="modify_boolean == false ? 8 : 9">
            <v-row v-show="modify_boolean == false" no-gutters v-for="(item, idx, i) in displayFields" :key="idx" class="mx-4">
              <v-col v-if="i != 0 && i != 4 && i != 11 && i != 15" cols="12" sm="12" md="3" class="font-weight-bold FontSizeMedium">
                {{ idx }}
              </v-col>
              <v-col v-else cols="12" sm="12" md="3" class="font-weight-bold mb-1"
                ><h3>
                  <u> {{ idx }}</u>
                </h3>
              </v-col>
              <v-col cols="12" sm="12" md="9" class="FontSizeMedium"
                >{{(item)}}
                <v-tooltip v-if="idx == 'Verification Fee'" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs" v-on="on" v-clipboard:copy="form_url" v-clipboard:success="onCopy" class="ml-3">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy Credential Verification Request URL</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-card-text v-show="modify_boolean == true">
              <v-expand-x-transition v-if="overlay == false" v-show="Expand">
                <v-card class="elevation-0 ma-2">
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="Organization Name*"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.issuer_name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                        <v-select
                          outlined
                          dense
                          class="field_height field_label_size FontSize"
                          label="Country *"
                          readonly
                          :items="GetAllSupportedCountryList"
                          item-value="country_name"
                          item-text="country_name"
                          :rules="[(v) => !!v || 'Country is required']"
                          v-model="business_info.country"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="` ${item.country_name}`"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                        <v-select
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="Please Select Identification Type"
                          :items="identificationTypeItems"
                          v-model="business_info.identification_type"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="Business Identification No. *"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.identification_number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                        <div class="heading3 mx-1 font-weight-bold">Registered Address :</div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="Address 1 *"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.issuer_address_1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="Address 2 *"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.issuer_address_2"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="State *"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.state"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="City *"
                          :items="citiesList"
                          item-text="name"
                          item-value="name"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.city"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="Postal Code *"
                          :rules="[(v) => !!v || 'Required', (v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,6}$/.test(v) || 'Postal Code Is Invalid']"
                          v-model="business_info.postal_code"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                        <div class="heading3 mx-1 font-weight-bold">Contact Details :</div>
                      </v-col>
                      <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          readonly
                          class="field_height field_label_size FontSize"
                          label="Name *"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.contact_user_name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          readonly
                          class="field_height field_label_size FontSize"
                          label="Email ID *"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.contact_email_id"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                        <v-select
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          :items="GetAllSupportedCountryList"
                          item-value="country_code"
                          item-text="country_code"
                          class="field_height field_label_size FontSize"
                          label="Country Code *"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="business_info.country_code"
                        >
                          <template slot="selection" slot-scope="{ item }">
                            {{ item.country_code }}
                          </template>
                          <template slot="item" slot-scope="{ item }">{{ item.country_name }}({{ item.country_code }}) </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                        <v-text-field
                          outlined
                          dense
                          :readonly="modify_boolean == false"
                          class="field_height field_label_size FontSize"
                          label="Contact Number *"
                          :rules="[(v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) || 'Invalid', (v) => (v && v.length < 11) || 'Invalid', (v) => (v && v.length > 9) || 'Invalid']"
                          v-model="business_info.contact_number"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="enable_verification_requests == true" cols="12" sm="12" md="12" xs="12" class="pt-0">
                        <div class="heading3 mx-1 font-weight-bold">Verification Details :</div>
                      </v-col>
                      <v-col v-if="enable_verification_requests == true" cols="2" sm="12" md="12" xs="12" class="pt-0">
                        <div class="heading3 mx-1 font-weight-bold">Verification Fee :</div>
                      </v-col>
                      <v-col v-if="enable_verification_requests == true" cols="12" sm="12" md="6" xs="12" class="pt-0">
                        <v-toolbar dense class="pa-0 elevation-0">
                          <v-text-field
                            outlined
                            dense
                            :readonly="modify_boolean == false"
                            class="field_height field_label_size FontSize"
                            label="Verification Fee *"
                            :rules="[(v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,64}$/.test(v) || 'Invalid']"
                            v-model="business_info.issuer_verification_fee"
                          ></v-text-field>
                          <div class="font-weight-bold mt-n6 ml-3">{{ issuer_details.currency_type }} + {{ country_details.verification_fee }} {{ country_details.currency_symbol }} For Surecreds</div>
                          <v-btn :loading="loadingUpdate" :disabled="modify_boolean == false" small color="primary" class="mt-n5 ml-3" @click="updateCurrentBusinessInfo(true)">Update</v-btn>
                        </v-toolbar>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-expand-x-transition>
            </v-card-text>
          </v-col>
          <v-col cols="12" xs="12" sm="12" :md="modify_boolean == false ? 4 : 3" align="left">
            <div class="font-weight-bold pb-4">Business Logo</div>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="9" align="left">
                <v-card :content-class="true" outlined @click="checkImagePresent()" width="200px" align="center" class="overflow-hidden">
                  <v-fab-transition v-if="imgSrc != null && imgSrc != '' && modify_boolean == true">
                    <v-btn absolute top right dark x-small fab color="red" class="mt-4" @click.stop="(imgSrc = null), (uploadedImage = false)">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-fab-transition>
                  <v-img contain v-if="imgSrc != null && imgSrc != ''" :src="imgSrc" :lazy-src="imgSrc" max-width="300px"></v-img>
                  <div v-else>
                    <input ref="excel-upload-input" accept="image/*" class="excel-upload-input" type="file" @change="handleClick" />
                    <v-icon class="mt-8">mdi-upload</v-icon>
                    <div align="center ">Upload Signature</div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>
<script>
var AWS = require("aws-sdk");
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
import Overlay from "@/components/Extras/Overlay.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { updateIssuerDetails } from "@/graphql/mutations.js";
import { updateIssuerURLsAndAPIId } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import { GetAllCountryCityStates } from "@/mixins/GetAllCountryCityStates.js";
export default {
  mixins: [GetAllSupportedCountry, GetCurrentUser, GetAllCountryCityStates],
  components: {
    Snackbar,
    Overlay,
  },
  props: {
    user_typeBI: String,
  },
  data: () => ({
    form_url: "",
    height: 0,
    Expand: false,
    overlay: false,
    loading: false,
    loadingUpdate: false,
    modify_boolean: false,
    business_info: {
      issuer_name: "",
      identification_type: "",
      identification_number: "",
      issuer_address_1: "",
      issuer_address_2: "",
      country: "",
      state: "",
      city: "",
      postal_code: "",
      contact_email_id: "",
      contact_user_name: "",
      country_code: "",
      contact_number: "",
      issuer_verification_fee: 0,
    },
    GetAllSupportedCountryList: [],
    identificationTypeItems: [],
    SnackBarComponent: {},
    issuer_details: {},
    Count: 0,
    stateList: [],
    imageFile: null,
    user_type: "",
    enable_verification_requests: false,
    dialogSaveBusinessInformationWarning: false,
    countryListNew: [],
    country_details: {},
    displayFields: {},
    uploadedImage: false,
    imgSrc: "",
  }),
  watch: {
    modify_boolean(val) {
      if (val == false) {
        this.imageFile = null;
      }
    },
    "business_info.country"(val) {
      this.identificationTypeItems = this.GetAllSupportedCountryList.filter((item) => item.country_name == val);
      this.getAllStatesMethod(val);
      this.Count += 1;
      if (this.Count != 1) {
        this.business_info.identification_type = "";
        this.business_info.identification_number = "";
      }
    },
  },
  mounted() {
    this.form_url = encodeURI(`https://surecreds.com/CredentialRequest/#/${this.$store.getters.get_current_user_details.issuer_details.issuer_name}`);
    this.user_type = this.$store.getters.get_user_type;
    this.GetCurrentUser();
    this.getAllCountriesMethod();
    this.GetAllSupportedCountryMethod("ACTIVE");
    this.height = window.innerHeight - 135;
    this.country_details = this.$store.getters.get_current_user_details.country_details;
    this.issuer_details = this.$store.getters.get_current_user_details.issuer_details;
    this.business_info = {
      issuer_name: this.issuer_details.issuer_name,
      identification_type: this.issuer_details.identification_type,
      identification_number: this.issuer_details.identification_number,
      issuer_address_1: this.issuer_details.issuer_address_1,
      issuer_address_2: this.issuer_details.issuer_address_2,
      country: this.issuer_details.country,
      state: this.issuer_details.state,
      city: this.issuer_details.city,
      postal_code: this.issuer_details.postal_code,
      contact_email_id: this.issuer_details.contact_email_id,
      contact_user_name: this.issuer_details.contact_user_name,
      country_code: this.issuer_details.country_code,
      contact_number: `${this.issuer_details.contact_number}`,
    };
    this.displayFields = {
      "Issuer Details :": "",
      "Issuer Name": this.issuer_details.issuer_name,
      "Identification Type": this.issuer_details.identification_type,
      "Identification Number": this.issuer_details.identification_number,
      "Registered Address": "",
      "Address 1": this.issuer_details.issuer_address_1,
      "Address 2": this.issuer_details.issuer_address_2,
      Country: this.issuer_details.country,
      State: this.issuer_details.state,
      City: this.issuer_details.city,
      "Postal Code": this.issuer_details.postal_code,
      "Contact Details :": "",
      Name: this.issuer_details.contact_user_name,
      "Email ID": this.issuer_details.contact_email_id,
      "Contact Number": `${this.issuer_details.country_code} - ${this.issuer_details.contact_number}`,
    };
    this.enable_verification_requests = this.issuer_details.enable_verification_requests;
    if (this.enable_verification_requests == true) {
      this.business_info.issuer_verification_fee = parseFloat(this.issuer_details.issuer_verification_fee);
      this.displayFields["Verification Details"] = "";
      this.displayFields["Verification Fee"] = `${parseFloat(this.issuer_details.issuer_verification_fee)} ${this.issuer_details.currency_type} + ${this.country_details.verification_fee} ${
        this.country_details.currency_symbol
      } For Surecreds`;
    }
    this.imgSrc =
      this.$store.getters.get_current_user_details.issuer_details.account_logo_url != undefined &&
      this.$store.getters.get_current_user_details.issuer_details.account_logo_url != null &&
      this.$store.getters.get_current_user_details.issuer_details.account_logo_url != ""
        ? this.$store.getters.get_current_user_details.issuer_details.account_logo_url
        : "";
  },
  methods: {
    async uploadImageToS3() {
      this.S3loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
        secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
          Key: `${self.$store.getters.get_current_user_details.issuer_id}/SIGNATURE/${new Date().getTime()}_mySignature.png`,
          Body: buf,
          ContentType: self.selectedFile.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.S3loading = false;
          } else if (data) {
            let url = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
            self.S3loading = false;
            self.uploadedImage = true;
            self.updateCurrentBusinessInfo(false, url);
            // self.updateCurrentBusinessLogo(url);
          }
        });
      };
    },
    async updateCurrentBusinessLogo(s3_URL) {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          update_type: "ACCOUNT_LOGO_URL",
          update_url: s3_URL,
        };
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(updateIssuerURLsAndAPIId, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuerURLsAndAPIId);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.GetCurrentUser();
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    checkImagePresent() {
      if (this.imgSrc == null || this.imgSrc == "" || this.imgSrc == undefined) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function (val) {
        self.imgSrc = val.target.result;
        self.uploadImageToS3();
      };
    },
    onCopy() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Verification Credential Copied Successfully !!",
      };
    },
    changeToDefaultData() {
      this.issuer_details = this.$store.getters.get_current_user_details.issuer_details;
      this.business_info = {
        issuer_name: this.issuer_details.issuer_name,
        identification_type: this.issuer_details.identification_type,
        identification_number: this.issuer_details.identification_number,
        issuer_address_1: this.issuer_details.issuer_address_1,
        issuer_address_2: this.issuer_details.issuer_address_2,
        country: this.issuer_details.country,
        state: this.issuer_details.state,
        city: this.issuer_details.city,
        postal_code: this.issuer_details.postal_code,
        contact_email_id: this.issuer_details.contact_email_id,
        contact_user_name: this.issuer_details.contact_user_name,
        country_code: this.issuer_details.country_code,
        contact_number: `${this.issuer_details.contact_number}`,
      };
      if (this.issuer_details.enable_verification_requests == true) {
        this.business_info.issuer_verification_fee = parseFloat(this.issuer_details.issuer_verification_fee);
      }
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.updateCurrentBusinessInfo(false);
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async updateCurrentBusinessInfo(updating_fee, account_logo_url) {
      try {
        if (updating_fee == false) {
          this.loading = true;
        } else {
          this.loadingUpdate = true;
        }
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          issuer_name: this.business_info.issuer_name,
          postal_code: this.business_info.postal_code,
          issuer_address_1: this.business_info.issuer_address_1,
          issuer_address_2: this.business_info.issuer_address_2,
          city: this.business_info.city,
          state: this.business_info.state,
          country_code: this.business_info.country_code,
          contact_number: this.business_info.contact_number,
          identification_type: this.business_info.identification_type,
          identification_number: this.business_info.identification_number,
          updating_fee: updating_fee,
        };
        if (account_logo_url) {
          inputParams.account_logo_url = account_logo_url;
        }
        if (this.issuer_details.enable_verification_requests == true) {
          if (updating_fee == true) {
            inputParams.issuer_verification_fee = this.business_info.issuer_verification_fee;
          } else {
            inputParams.issuer_verification_fee = 0;
          }
        } else {
          inputParams.issuer_verification_fee = 0;
        }
        let result = await API.graphql(
          graphqlOperation(updateIssuerDetails, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuerDetails);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Editing Changes Submitted Successfully!!",
          };
          this.GetCurrentUser();
        }
        if (updating_fee == false) {
          this.loading = false;
        } else {
          this.loadingUpdate = false;
        }
        this.modify_boolean = false;
      } catch (error) {
        this.loading = false;
        if (account_logo_url) {
          this.imgSrc =
            this.$store.getters.get_current_user_details.issuer_details.account_logo_url != undefined &&
            this.$store.getters.get_current_user_details.issuer_details.account_logo_url != null &&
            this.$store.getters.get_current_user_details.issuer_details.account_logo_url != ""
              ? this.$store.getters.get_current_user_details.issuer_details.account_logo_url
              : "";
        }
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
  },
};
</script>
