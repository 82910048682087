<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogIssueCredentials" persistent :max-width="pdfview == true || actionEnum == 'WITHOUTDOCUMENT' ? '1000px' : '420px'">
      <v-card :height="pdfview == true ? windowHeight : ''" class="overflow-y-auto">
        <v-toolbar dark color="secondary" dense class="elevation-0">
          <div>Issue Credential</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="IssueCredentialsEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters class="">
            <v-col cols="12" xs="12" sm="12" :md="pdfview == true || actionEnum == 'WITHOUTDOCUMENT' ? 5 : 12" class="mt-4">
              <v-text-field
                v-if="actionEnum == 'TEMPLATIZED'"
                label="Template Name"
                :rules="[(v) => !!v || 'Required']"
                v-model="StoreObj.template_name"
                dense
                readonly
                outlined
                class="FontSize field_height field_label_size"
              ></v-text-field>
              <div v-if="preview_mode == false" class="font-weight-bold mb-1">Issued By:</div>
              <v-row no-gutters v-if="preview_mode == false">
                <v-col cols="12" xs="12" sm="12" md="6" class="mb-n5">
                  <v-text-field
                    label="Issuing Organization"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="$store.getters.get_current_user_details.issuer_details.issuer_name"
                    dense
                    readonly
                    outlined
                    class="FontSize field_height field_label_size mr-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    label="Issuing User"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="$store.getters.get_current_user_details.user_name"
                    dense
                    readonly
                    outlined
                    class="FontSize field_height field_label_size ml-1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="font-weight-bold mb-3" :class="preview_mode == false ? 'mt-n5' : ''">Issued To:</div>
              <v-form ref="form" class="py-0">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="6" class="mt-n2">
                    <v-text-field
                      :readonly="preview_mode == true"
                      label="Name"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="issued_to"
                      dense
                      outlined
                      class="FontSize field_height field_label_size mr-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="mt-n2">
                    <v-text-field
                      :readonly="preview_mode == true"
                      label="Email ID (Optional)"
                      :rules="IssueCredsRules"
                      v-model="issued_to_email_id"
                      dense
                      outlined
                      class="FontSize field_height field_label_size ml-1"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <v-select
                      outlined
                      dense
                      :readonly="preview_mode == true"
                      :items="GetAllSupportedCountryList"
                      item-value="country_code"
                      item-text="country_name"
                      class="field_height field_label_size FontSize"
                      label="Country Code"
                      v-model="issued_to_country_code"
                    >
                      <template slot="selection" slot-scope="{ item }">
                        {{ item.country_code }}
                      </template>
                      <template slot="item" slot-scope="{ item }">{{ item.country_name }}({{ item.country_code }}) </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8">
                    <v-text-field
                      :readonly="preview_mode == true"
                      outlined
                      dense
                      class="field_height field_label_size FontSize ml-2"
                      label="Contact Number (Optional)"
                      :rules="contactRules"
                      v-model="issued_to_contact_number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-textarea
                  :readonly="preview_mode == true"
                  rows="1"
                  label="Additional Comments (Optional)"
                  v-model="comments"
                  dense
                  outlined
                  class="FontSize field_height field_label_size"
                ></v-textarea>
              </v-form>
              <v-text-field
                v-if="preview_mode == true && issued_to_email_id != undefined && issued_to_email_id != '' && this.issued_to_email_id != null"
                filled
                outlined
                dense
                class="field_height field_label_size FontSize"
                label="Enter Email Subject (Optional)"
                v-model="email_subject"
              ></v-text-field>
              <v-file-input
                v-if="actionEnum == 'WITHDOCUMENT' && preview_mode == false"
                accept="application/pdf,image/*,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                label="Select Document"
                dense
                outlined
                class="FontSize"
                v-model="files"
                prepend-icon=""
                persistent-hint
                hint="File size should be less than 1 MB"
              ></v-file-input>
              <v-card color="#e1ffef" class="px-2" outlined style="border: 1px solid #00a650">
                <span
                  class="FontSizeSmall"
                  v-text="
                    actionEnum == 'WITHDOCUMENT'
                      ? 'Note that the document itself it NOT stored on blockchain, only a hash code of the generated PDF document is stored'
                      : 'Note that the text itself it NOT stored on blockchain, only a hash code of the generated PDF document is stored'
                  "
                ></span>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="7" v-if="pdfview == true">
              <v-row class="BorderPrimary mx-4 mt-5">
                <div class="xmediumFontSize py-2 pl-2">Selected Document</div>
                <iframe :height="heightPDF" :src="pdfsrc" width="100%" frameborder="0"></iframe>
              </v-row>
              <v-card-actions class="pt-7 pr-4 pb-0">
                <v-spacer></v-spacer>
                <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" outlined @click="IssueCredentialsEmit((Toggle = 1))"
                  ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
                >
                <v-btn small v-if="loading == false" class="borderRadius fontStyle text-capitalize" color="primary" @click="validateMethod()"
                  ><v-icon small class="mr-1" v-text="preview_mode == false ? 'mdi-eye' : 'mdi-pencil'"></v-icon><span v-text="preview_mode == false ? 'Preview' : 'Edit'"></span
                ></v-btn>
                <v-btn :loading="loading" v-if="preview_mode == true" small class="borderRadius fontStyle text-capitalize ml-2" color="primary" @click="IssueCredentialsMethod()"
                  ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
                >
              </v-card-actions>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="7" class="mt-5 px-3" v-if="actionEnum == 'WITHOUTDOCUMENT'">
              <div class="font-weight-bold mb-2">Please Add Content</div>
              <vue-editor :disabled="preview_mode == true" class="editorHeightFT" ref="imageEditor" :editorToolbar="customToolbar" v-model="payload" placeholder="Add Content"></vue-editor>
              <v-card-actions class="pt-16 pb-0 pr-0">
                <v-spacer></v-spacer>
                <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" outlined @click="IssueCredentialsEmit((Toggle = 1))"
                  ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
                >
                <v-btn small v-if="loading == false" class="borderRadius fontStyle text-capitalize" color="primary" @click="validateMethod()"
                  ><v-icon small class="mr-1" v-text="preview_mode == false ? 'mdi-eye' : 'mdi-pencil'"></v-icon><span v-text="preview_mode == false ? 'Preview' : 'Edit'"></span
                ></v-btn>
                <v-btn :loading="loading" v-if="preview_mode == true" small class="borderRadius fontStyle text-capitalize ml-2" color="primary" @click="issueCredentialsWithOutDocMethod()"
                  ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { issueCredential, issueCredentialWithoutDoc } from "@/graphql/mutations.js";
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import { API, graphqlOperation } from "aws-amplify";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
var AWS = require("aws-sdk");
export default {
  props: {
    dialogIssueCredentials: Boolean,
    actionEnum: String,
    StoreObj: Object,
  },
  mixins: [GetCurrentUser, GetAllSupportedCountry],
  components: {
    Snackbar,
    VueEditor,
  },
  data: () => ({
    Toggle: 0,
    files: {},
    payload: "",
    loading: false,
    issued_to: "",
    issued_to_email_id: "",
    issued_to_country_code: "",
    issued_to_contact_number: "",
    comments: "",
    SnackBarComponent: {},
    pdfview: false,
    pdfsrc: "",
    windowHeight: 0,
    heightPDF: 0,
    store_data_ob_bc: true,
    document_type: "",
    IssueCredsRules: [],
    contactRules: [],
    GetAllSupportedCountryList: [],
    customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ["bold", "italic", "underline", "strike"]],
    preview_mode: false,
    email_subject: "",
  }),
  watch: {
    issued_to_contact_number(val) {
      if (val != "" && val != null) {
        this.contactRules = [(v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) || "Invalid", (v) => (v && v.length < 11) || "Invalid", (v) => (v && v.length > 9) || "Invalid"];
      } else {
        this.contactRules = [];
      }
    },
    issued_to_email_id(val) {
      if (val != "" && val != null) {
        this.emailRules = [(v) => /.+@.+/.test(v) || "Email Must Be Valid"];
      } else {
        this.emailRules = [];
      }
    },
    files(val) {
      if (val != undefined && val != "" && val != null) {
        if (val.size <= 3000000) {
          this.document_type = val.type.includes("pdf")
            ? "PDF"
            : val.type.includes("image")
            ? "IMAGE"
            : val.type.includes("vnd.openxmlformats-officedocument.wordprocessingml.document")
            ? "WORD"
            : "TEXTFILE";
          if (this.document_type != "WORD") {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(val);
            var self = this;
            fileReader.onload = async function (val) {
              self.pdfsrc = val.target.result;
              self.pdfview = true;
            };
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "File Size Should Be Less Than 1 MB",
          };
        }
      } else {
        this.pdfview = false;
      }
    },
    dialogIssueCredentials(val) {
      if (val == true) {
        this.GetAllSupportedCountryMethod('ACTIVE');
        this.store_data_ob_bc = true;
      }
    },
  },
  mounted() {
    this.windowHeight = window.innerHeight - 100;
    this.heightPDF = window.innerHeight - 280;
  },
  methods: {
    async issueCredentialsWithOutDocMethod() {
      let d = new Date();
      let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let date = d.getDate() + "-" + monthNames[d.getMonth()] + "-" + d.getFullYear() + "  " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      this.loading = true;
      var inputParams = {
        issued_to: this.issued_to,
        should_create_pdf: true,
        push_to_blockchain: true,
        user_id: this.$store.getters.get_current_user_details.user_id,
        payload: `<html>
                    <body>
                      <div>${this.payload}</div>
                    <body>
                  </html>`,
        date: date,
        month: `${d.getFullYear()}-${d.getMonth() + 1}`,
      };
      if (this.issued_to_contact_number != undefined && this.issued_to_contact_number != "" && this.issued_to_contact_number != null) {
        inputParams.phone_number =
          `${this.issued_to_country_code}${this.issued_to_contact_number}`.includes("+") == true
            ? `${this.issued_to_country_code}${this.issued_to_contact_number}`.replace("+", "")
            : `${this.issued_to_country_code}${this.issued_to_contact_number}`;
      }
      if (this.issued_to_email_id != undefined && this.issued_to_email_id != "" && this.issued_to_email_id != null) {
        inputParams.issued_to_email_id = this.issued_to_email_id;
      }
      if (this.comments != undefined && this.comments != "" && this.comments != null) {
        inputParams.comments = this.comments;
      }
      if (this.email_subject != undefined && this.email_subject != "" && this.email_subject != null) {
        inputParams.email_subject = this.email_subject;
      }
      try {
        let result = await API.graphql(
          graphqlOperation(issueCredentialWithoutDoc, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.IssueCredentialWithoutDoc);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.IssueCredentialsEmit((this.Toggle = 2), ResultObject);
          this.loading = false;
        }
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    validateMethod() {
      if (this.$store.getters.get_current_user_details.issuer_details.available_credits != 0) {
        if (this.$refs.form.validate()) {
          if (this.actionEnum == "WITHDOCUMENT") {
            if (this.files.name != undefined) {
              this.preview_mode = !this.preview_mode;
            } else {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Please select a document",
              };
            }
          } else if (this.actionEnum == "WITHOUTDOCUMENT") {
            if (this.payload != "" && this.payload != null && this.payload != undefined) {
              this.preview_mode = !this.preview_mode;
            } else {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Please add content",
              };
            }
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Fields marked with asterisks (*) are mandatory",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Your Can't Issue Credentials As Your Balance Is Zero",
        };
      }
    },
    async issueCredentialsMethod(Key, file_name) {
      let d = new Date();
      let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let date = d.getDate() + "-" + monthNames[d.getMonth()] + "-" + d.getFullYear() + "  " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      try {
        var inputParams = {
          should_create_pdf: true,
          issued_to: this.issued_to,
          user_id: this.$store.getters.get_current_user_details.user_id,
          file_name: file_name.split(".")[0],
          key: Key,
          date: date,
          month: `${d.getFullYear()}-${d.getMonth() + 1}`,
          push_to_blockchain: false,
          document_type: this.document_type,
        };
        if (this.email_subject != undefined && this.email_subject != "" && this.email_subject != null) {
          inputParams.email_subject = this.email_subject;
        }
        if (this.issued_to_contact_number != undefined && this.issued_to_contact_number != "" && this.issued_to_contact_number != null) {
          inputParams.phone_number =
            `${this.issued_to_country_code}${this.issued_to_contact_number}`.includes("+") == true
              ? `${this.issued_to_country_code}${this.issued_to_contact_number}`.replace("+", "")
              : `${this.issued_to_country_code}${this.issued_to_contact_number}`;
        }
        if (this.issued_to_email_id != undefined && this.issued_to_email_id != "" && this.issued_to_email_id != null) {
          inputParams.issued_to_email_id = this.issued_to_email_id;
        }
        if (this.comments != undefined && this.comments != "" && this.comments != null) {
          inputParams.comments = this.comments;
        }
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(issueCredential, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.IssueCredential);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.IssueCredentialsEmit((this.Toggle = 2), ResultObject);
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    async IssueCredentialsMethod() {
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
        secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      });
      var file_name = `${new Date().getTime()}_${self.files.name}`;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.files);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
          Key: `${self.$store.getters.get_current_user_details.user_id}/${file_name}`,
          Body: buf,
          ContentType: self.files.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
          } else if (data) {
            self.issueCredentialsMethod(param.Key, file_name);
          }
        });
      };
    },
    IssueCredentialsEmit(Toggle, ResultObject) {
      this.payload = "";
      this.document_type = "";
      this.store_data_ob_bc = false;
      this.IssueCredsRules = [];
      this.files = null;
      this.pdfview = false;
      this.preview_mode = false;
      this.email_subject = "";
      this.$refs.form.reset();
      this.$emit("clicked", Toggle, ResultObject);
    },
  },
};
</script>
