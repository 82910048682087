<template>
  <v-dialog v-model="deletePageDialog" persistent :max-width="'250px'">
    <v-card class="overflow-hidden">
      <v-row no-gutters justify="center">
        <v-card-text align="center">
          <h3 class="font-weight-bold" style="color: #019267">
            Delete Page {{ fromPage }} ?
          </h3>
          <v-btn small color="primary mr-5 mt-2" @click="close_dialog(false)">
            NO
          </v-btn>
          <v-btn small color="primary mt-2" @click="close_dialog(true)">
            YES
          </v-btn>
        </v-card-text>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    deletePageDialog: Boolean,
    fromPage: Number,
  },
  data: () => ({}),
  methods: {
    close_dialog(val) {
      if (val == true) {
        this.$emit("closeDialog", true);
      } else {
        this.$emit("closeDialog", false);
      }
    },
  },
};
</script>
