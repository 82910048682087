<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <ChangePasswordDialog :dialogChangePassword="dialogChangePassword" @clicked="dialogChangePasswordEmit" />
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">My Profile</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="dialogChangePassword = true"><v-icon small class="mr-1">mdi-account-key</v-icon>Change Password</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <v-expand-x-transition>
          <v-card v-show="Expand" class="elevation-0 ma-2">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-form ref="form">
                  <v-row no-gutters>
                    <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold">Name</v-col>
                    <v-col cols="12" xs="12" sm="12" md="9">
                      <v-card-actions class="pa-0">
                        <v-text-field
                          :readonly="edit_toggle == false ? true : false"
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          class="FontSize maxWidthLarge field_height field_label_size"
                          v-model="user_details.user_name"
                        ></v-text-field>
                        <v-btn :loading="loading" icon color="primary" class="mt-n7 ml-2" @click="edit_toggle = !edit_toggle">
                          <v-icon v-text="edit_toggle == false ? 'mdi-pencil' : 'mdi-check'"></v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold">Email ID</v-col>
                    <v-col cols="12" xs="12" sm="12" md="9">
                      <v-text-field dense outlined readonly class="FontSize maxWidthLarge field_height field_label_size" v-model="user_details.user_email_id"></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold">Contact Number</v-col>
                    <v-col cols="12" xs="12" sm="12" md="9">
                      <v-text-field dense outlined readonly class="FontSize maxWidthLarge field_height field_label_size" v-model="user_details.contact_number"></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold">User Type</v-col>
                    <v-col cols="12" xs="12" sm="12" md="9">
                      <v-text-field dense outlined readonly class="FontSize maxWidthLarge field_height field_label_size" v-model="user_details.user_type"></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" class="font-weight-bold">Wallet Details</v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold">Public Key :</v-col>
                    <v-col cols="12" xs="12" sm="12" md="9">
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="5" align="left">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold">My Signature</v-col>
                  <v-col cols="12" xs="12" sm="12" md="9" align="left">
                    <v-card outlined @click="checkImagePresent()" width="300px" height="200px" align="center" class="overflow-hidden">
                      <v-fab-transition v-if="imgSrc != null && imgSrc != ''">
                        <v-btn absolute top right dark x-small fab color="red" class="mt-8" @click.stop="(imgSrc = null), (uploadedImage = false), s3DeleteMethod()">
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                      </v-fab-transition>
                      <v-img v-if="imgSrc != null && imgSrc != ''" :src="imgSrc" :lazy-src="imgSrc" max-width="300px" class="mt-8"></v-img>
                      <div v-else>
                        <input ref="excel-upload-input" accept="image/*" class="excel-upload-input" type="file" @change="handleClick" />
                        <v-icon class="mt-16">mdi-upload</v-icon>
                        <div align="center ">Upload Signature</div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-x-transition>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
var AWS = require("aws-sdk");
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import ChangePasswordDialog from "@/components/MyProfile/Dialogs/ChangePasswordDialog.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import { updateUserDetails, updateIssuerURLsAndAPIId, deleteSignatureURL } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  components: {
    Snackbar,
    Overlay,
    ChangePasswordDialog,
  },
  mixins: [GetCurrentUser],
  data: () => ({
    overlay: false,
    edit_toggle: false,
    Expand: false,
    user_details: {
      user_name: "",
      user_email_id: "",
      contact_number: "",
      user_type: "",
    },
    loading: false,
    signatureList: [],
    SnackBarComponent: {},
    selectedFile: null,
    imgSrc: null,
    S3loading: false,
    uploadedImage: false,
    loadingConfirmPassword: false,
    dialogChangePassword: false,
  }),
  watch: {
    edit_toggle(val) {
      if (val == false) {
        this.validateMethod();
      }
    },
  },
  mounted() {
    this.GetCurrentUser();
    this.user_details = {
      user_name: this.$store.getters.get_current_user_details.user_name,
      user_email_id: this.$store.getters.get_current_user_details.user_email_id,
      contact_number: `${this.$store.getters.get_current_user_details.issuer_details.country_code}-${this.$store.getters.get_current_user_details.issuer_details.contact_number}`,
      user_type: this.$store.getters.get_current_user_details.user_type,
    };
    this.imgSrc = this.$store.getters.get_current_user_details.signature_url != undefined ? this.$store.getters.get_current_user_details.signature_url : "";
    this.public_key = this.$store.getters.get_current_user_details.issuer_details.public_key
    console.log("public_keypublic_key",this.public_key)
  },
  methods: {
    dialogChangePasswordEmit() {
      this.dialogChangePassword = false;
    },
    async s3DeleteMethod() {
      let Key = this.$store.getters.get_current_user_details.signature_url.replace("https://workhistory-mumbai.s3.ap-south-1.amazonaws.com/", "");
      var self = this;
      var s3Bucket = new AWS.S3({
        region: self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
        secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      });
      var param = {
        Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
        Key: Key,
      };
      await s3Bucket.deleteObject(param, function (err) {
        if (err) {
          self.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Error Occured!! Please try again",
            Top: true,
          };
        } else {
          self.deleteSignature();
        }
      });
    },
    async deleteSignature() {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(deleteSignatureURL, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.DeleteSignatureURL);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.GetCurrentUser();
        }
        this.loading = false;
        this.modify_boolean = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async updateCurrentBusinessLogo(s3_URL) {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          update_type: "SIGNATURE_URL",
          update_url: s3_URL,
        };
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(updateIssuerURLsAndAPIId, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuerURLsAndAPIId);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.GetCurrentUser();
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async uploadImageToS3() {
      this.S3loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
        secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
          Key: `${self.$store.getters.get_current_user_details.issuer_id}/SIGNATURE/${new Date().getTime()}_mySignature.png`,
          Body: buf,
          ContentType: self.selectedFile.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.S3loading = false;
          } else if (data) {
            let url = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
            self.S3loading = false;
            self.uploadedImage = true;
            self.updateCurrentBusinessLogo(url);
          }
        });
      };
    },
    checkImagePresent() {
      if (this.imgSrc == null || this.imgSrc == "" || this.imgSrc == undefined) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function (val) {
        self.imgSrc = val.target.result;
        self.uploadImageToS3();
      };
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.updateUserDetailsMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter User Name",
        };
      }
    },
    async updateUserDetailsMethod() {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          user_name: this.user_details.user_name,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(updateUserDetails, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateUserDetails);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.edit_toggle = false;
          this.GetCurrentUser();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
  },
};
</script>
