<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogUploadImage" max-width="600px" persistent>
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          Add Background Image
          <v-spacer />
          <v-btn icon @click="dialogUploadImageEmit(false)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" class="mb-n5">
            <v-row no-gutters justify="center" class="mt-3">
              <v-select
                dense
                outlined
                class="FontSize field_height field_label_size"
                :items="GetAllMediaList"
                item-text="file_name"
                item-value="file_url"
                label="Select Image"
                v-model="selectImage"
                style="max-width: 200px"
              ></v-select>
            </v-row>
            <v-row no-gutters justify="center" class="mt-3">
              <v-btn
                small
                color="secondary"
                class="borderRadius mt-n7 mb-3"
                @click="dialogUploadImageEmit(true)"
                ><v-icon small class="mr-1">mdi-plus</v-icon>Add</v-btn
              >
            </v-row>
            <v-row no-gutters justify="center" v-if="selectImage.length != 0" class="mb-3">
              <v-card outlined align="center" class="overflow-hidden">
                <v-card-text>
                  <v-img
                    contain
                    v-if="GetAllMediaList.length != 0"
                    max-width="300px"
                    :src="selectImage"
                    :lazy-src="selectImage"
                  ></v-img>
                  <h2 v-else style="padding-top: 60px">
                    Please Select An Image To Preview
                  </h2>
                </v-card-text>
              </v-card>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";

export default {
  props: {
    dialogUploadImage: Boolean,
    GetAllMediaList: Array,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    selectImage: "",
    SnackBarComponent: {},
  }),
  watch: {
    dialogUploadImage(val) {
      if (val == true) {
        this.selectImage = "";
      }
    },
  },
  methods: {
    dialogUploadImageEmit(val) {
      this.$emit("clicked", val, this.selectImage);
    },
  },
};
</script>
