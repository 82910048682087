<template>
  <v-dialog v-model="dialogAddImage" persistent :max-width="'900px'">
    <v-card class="overflow-hidden">
      <v-toolbar dark dense color="primary" class="elevation-0">
        <div
          v-if="StoreObj.position != undefined && StoreObj.type != undefined"
        >
          Add
          {{ StoreObj.type.slice(0, 1) + StoreObj.type.slice(1).toLowerCase() }}
          to Page {{ fromPage }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          small
          dark
          class="text-capitalize"
          outlined
          @click="clearImage((Toggle = 1))"
        >
          Clear All
        </v-btn>
        <v-btn icon @click="dialogAddImageEmit((Toggle = 1))">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card class="elevation-0">
              <v-radio-group
                label="Content Type"
                dense
                class="my-0 py-0"
                v-model="addImage.content_type"
                row
                @change="changeContentTypeMethod()"
              >
                <v-radio label="Image" value="IMAGE"></v-radio>
                <v-radio
                  v-if="
                    $store.getters.get_current_user_details.issuer_details
                      .account_logo_url != undefined
                  "
                  label="BUSINESS LOGO"
                  value="BUSINESS_LOGO"
                ></v-radio>
              </v-radio-group>
              <v-select
                dense
                outlined
                v-if="addImage.content_type == 'IMAGE'"
                class="FontSize field_height field_label_size"
                :items="GetAllMediaList"
                item-text="file_name"
                item-value="file_url"
                label="Select Image"
                v-model="selectImage"
                @change="selectImageMethod()"
              ></v-select>
              <div class="font-weight-bold">
                Configure Text Before/ After Image
              </div>
              <v-checkbox
                dense
                class="px-0 mx-0 pb-0 mb-0"
                label="Configure Text Before Image"
                v-model="text_settings.configure_text_before_image"
                @change="configureTextMethod('BEFOREIMAGE')"
              ></v-checkbox>
              <vue-editor
                :disabled="text_settings.configure_text_before_image == false"
                class="pb-4 editorHeightSM"
                :editorToolbar="customToolbar"
                v-model="text_settings.text_content_before_image"
                placeholder="Add Content"
                @change="checkBeforeAfterImageTextContent('BEFOREIMAGE')"
              ></vue-editor>
              <v-checkbox
                dense
                class="px-0 mx-0 pt-12 pb-0 mb-0"
                label="Configure Text After Signature"
                v-model="text_settings.configure_text_after_image"
                @change="configureTextMethod('AFTERIMAGE')"
              ></v-checkbox>
              <vue-editor
                :disabled="text_settings.configure_text_after_image == false"
                class="pb-4 editorHeightSM"
                :editorToolbar="customToolbar"
                v-model="text_settings.text_content_after_image"
                placeholder="Add Content"
                @change="checkBeforeAfterImageTextContent('AFTERIMAGE')"
              ></vue-editor>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card outlined height="100%">
              <v-card-title class="py-0">Preview</v-card-title>
              <v-card-text
                v-if="previewhtml != null && previewhtml != ''"
                class="py-0"
              >
                <div class="mt-1" v-html="previewhtml"></div>
              </v-card-text>
              <v-card v-else align="center" class="overflow-hidden elevation-0">
                <v-card-text>
                  <h3 style="padding-top: 90px">
                    Please Select An Image To Preview
                  </h3>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="addImageMethod">
          <v-icon small>mdi-plus</v-icon>
          Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  props: {
    GetAllMediaList: Array,
    dialogAddImage: Boolean,
    StoreObj: Object,
    fromPage: Number,
    PageItems: Array,
  },
  components: {
    VueEditor,
  },
  data: () => ({
    addImage: {
      content_type: "",
      content: "",
    },
    selectImage: "",
    previewhtml: "",
    text_settings: {
      image_url: "",
      text_content: "",
      configure_text_before_image: false,
      text_content_before_image: "",
      configure_text_after_image: false,
      text_content_after_image: "",
    },
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline"],
      [{ color: [] }],
    ],
  }),
  watch: {
    previewhtml(val) {
      if (
        val.includes("BUSINESS LOGO") &&
        this.$store.getters.get_current_user_details.issuer_details
          .account_logo_url != undefined
      ) {
        this.previewhtml = val.replace(
          "BUSINESS LOGO",
          `<img src="${
            this.$store.getters.get_current_user_details.issuer_details
              .account_logo_url
          }" style="width:250px;margin:20px"" id="innerImage${
            this.StoreObj.position == "TOP" ? "Top" : "Bottom"
          }${this.fromPage}"/>`
        );
      }
    },
    dialogAddImage(val) {
      if (val == true) {
        this.addImage.position = this.StoreObj.position;
        if (
          this.StoreObj.content_present == true &&
          this.StoreObj.position == "TOP"
        ) {
          console.warn("StoreObj", this.StoreObj);

          this.addImage.content_type = this.StoreObj.content_type;
          this.selectImage = this.StoreObj.text_settings.image_url;
        }
        if (
          this.StoreObj.content_present == true &&
          this.StoreObj.position == "BOTTOM"
        ) {
          this.addImage.content_type = this.StoreObj.content_type;
          this.selectImage = this.StoreObj.text_settings.image_url;
        }
        if (this.StoreObj.content_present == true) {
          this.text_settings = this.StoreObj.text_settings;
        }
      }
    },
    "text_settings.text_content_before_image"(val) {
      if (val == "") {
        this.text_settings.configure_text_before_image = false;
      }
      if (val != undefined && val != null) {
        this.changeSettingsMethod("BEFOREIMAGE");
      }
    },
    "text_settings.text_content_after_image"(val) {
      if (val == "") {
        this.text_settings.configure_text_after_image = false;
      }
      if (val != undefined && val != null) {
        this.changeSettingsMethod("AFTERIMAGE");
      }
    },
  },
  methods: {
    clearImage() {
      this.text_settings = {
        image_url: "",
        text_content: "",
        configure_text_before_image: false,
        text_content_before_image: "",
        configure_text_after_image: false,
        text_content_after_image: "",
      };
      let addImage = {};
      addImage.position = this.StoreObj.position;
      addImage.text_settings = this.text_settings;
      addImage.content = "";
      addImage.content_type = "IMAGE";
      this.dialogAddImageEmit((this.Toggle = 2), addImage);
    },
    addImageMethod() {
      if (
        this.addImage.content != undefined &&
        this.addImage.content_type != null &&
        this.addImage.content_type != undefined &&
        this.addImage.content_type != null
      ) {
        let addImage = { ...this.addImage };
        addImage.text_settings = this.text_settings;
        addImage.content = this.previewhtml;
        this.dialogAddImageEmit((this.Toggle = 2), addImage);
      }
    },
    changeSettingsMethod() {
      this.previewhtml = this.text_settings.text_content;
      if (this.text_settings.configure_text_before_image == true) {
        this.previewhtml = this.text_settings.text_content_before_image.concat(
          this.previewhtml
        );
      }
      if (this.text_settings.configure_text_after_image == true) {
        this.previewhtml = this.previewhtml.concat(
          this.text_settings.text_content_after_image
        );
      }
      this.$forceUpdate();
      this.addImage.content = this.previewhtml;
    },
    selectImageMethod() {
      this.addImage.content = this.selectImage;
      this.text_settings = {
        image_url: this.selectImage,
        text_content: `<img src="${
          this.selectImage
        }" style="width:250px;margin:20px"" id="innerImage${
          this.StoreObj.position == "TOP" ? "Top" : "Bottom"
        }${this.fromPage}"/>`,
        configure_text_before_image:
          this.StoreObj.content_present == true
            ? this.StoreObj.text_settings.configure_text_before_image
            : false,
        text_content_before_image:
          this.StoreObj.content_present == true
            ? this.StoreObj.text_settings.text_content_before_image
            : "",
        configure_text_after_image:
          this.StoreObj.content_present == true
            ? this.StoreObj.text_settings.configure_text_after_image
            : false,
        text_content_after_image:
          this.StoreObj.content_present == true
            ? this.StoreObj.text_settings.text_content_after_image
            : "",
      };
      this.changeSettingsMethod();
    },
    configureTextMethod(Position) {
      switch (Position) {
        case "BEFOREIMAGE":
          this.text_settings.text_content_before_image = "";
          break;
        case "AFTERIMAGE":
          this.text_settings.text_content_after_image = "";
          break;
      }
    },
    changeContentTypeMethod() {
      switch (this.addImage.content_type) {
        case "IMAGE":
          console.warn("Coming Inside Image");
          this.addImage.content = "";
          this.text_settings = {
            image_url: "",
            text_content: "",
            configure_text_before_image: false,
            text_content_before_image: "",
            configure_text_after_image: false,
            text_content_after_image: "",
          };
          this.selectImage = "";
          this.previewhtml = "";
          break;
        case "BUSINESS_LOGO":
          console.warn("Coming Inside Business Logo");
          this.selectImage = "";
          this.addImage.content = "";
          this.text_settings = {
            image_url:
              this.$store.getters.get_current_user_details.issuer_details
                .account_logo_url,
            text_content: `<img src="${
              this.$store.getters.get_current_user_details.issuer_details
                .account_logo_url
            }" style="width:250px;margin:20px"" id="innerImage${
              this.StoreObj.position == "TOP" ? "Top" : "Bottom"
            }${this.fromPage}"/>`,
            configure_text_before_image: false,
            text_content_before_image: "",
            configure_text_after_image: false,
            text_content_after_image: "",
          };
          this.previewhtml = `<img src="${
            this.$store.getters.get_current_user_details.issuer_details
              .account_logo_url
          }" style="width:250px;margin:20px"" id="innerImage${
            this.StoreObj.position == "TOP" ? "Top" : "Bottom"
          }${this.fromPage}"/>`;
          this.$forceUpdate();
          break;
      }
      this.changeSettingsMethod();
    },
    dialogAddImageEmit(Toggle, addImage) {
      this.text_settings = {
        image_url: "",
        text_content: "",
        configure_text_before_image: false,
        text_content_before_image: "",
        configure_text_after_image: false,
        text_content_after_image: "",
      };
      this.addImage = {
        content_type: "",
        content: "",
        position: "",
      };
      this.previewhtml = "";
      this.$emit("clicked", Toggle, addImage, this.fromPage);
    },
  },
};
</script>
