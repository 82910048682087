<template>
  <div>
    <v-dialog v-model="dialogSignatureWarning" persistent max-width="400px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>Warning !!!</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogSignatureWarningEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-6 text-center">
          <v-icon color="primary" size="50px">mdi-information</v-icon>
          <div class="mt-3">Please Upload Signature To View This Template</div>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" @click="dialogSignatureWarningEmit()"><v-icon small class="mr-1">mdi-check</v-icon>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogSignatureWarning: Boolean,
  },
  data: () => ({
    Toggle: 0,
  }),
  watch: {},
  methods: {
    dialogSignatureWarningEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
