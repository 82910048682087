<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogEditInvalidData" persistent max-width="500px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>Edit Invalid Data</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogEditInvalidDataEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-8">
          <v-form ref="invalidDataVmodel" v-model="invalidDataVmodel">
            <v-row>
              <v-col class="py-0" v-for="(field, idx, i) in dataObj" :key="i" cols="12" sm="12" xs="12" md="6">
                <v-text-field :label="idx" v-model="dataObj[idx]" dense outlined class="FontSize field_height field_label_size"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" @click="validateMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogEditInvalidData: Boolean,
    dataObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    reasons: [],
    invalidDataVmodel: false,
    SnackBarComponent: {},
  }),
  watch: {
    dialogEditInvalidData(val) {
      if (val == true) {
        console.log("Check The DataObj", this.dataObj);
        this.reasons = this.dataObj.reasons;
      }
    },
  },
  methods: {
    validateMethod() {
      let emptyField = false;
      for (let key in this.dataObj) {
        if (this.dataObj[key] == "" || this.dataObj[key] == undefined) {
          emptyField = true;
        }
      }
      if (emptyField == false) {
        this.dialogEditInvalidDataEmit((this.Toggle = 2), this.dataObj);
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please fill up all the fields",
        };
      }
    },
    dialogEditInvalidDataEmit(Toggle, dataObj) {
      this.$emit("clicked", Toggle, dataObj);
    },
  },
};
</script>
