<template>
  <div>
    <v-dialog
      v-model="PDFViewDialog"
      persistent
      fullscreen
      color="#757575"
      class="overflow-y-auto center"
    >
      <Overlay :overlay="overlay" />
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          PDF Preview
          <span class="ml-2"> ({{ StoreObj.template_name }})</span>
          <span
            style="
              font-size: 20px;
              position: fixed;
              left: 50%;
              transform: translateX(-50%);
            "
          >
            Page {{ currentPage }}/{{ totalPage }}
          </span>
          <v-spacer />
          <v-btn dark icon @click="ViewPdfPreview()">
            <v-icon>mdi-tray-arrow-down</v-icon>
          </v-btn>
          <v-btn icon @click="PDFPreviewDialogEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card elevation="0" color="#e5f6ed" class="overflow-y-auto">
          <v-virtual-scroll
            :items="pageItems"
            :height="height"
            :item-height="
              pageItems[0] != undefined
                ? pageItems[0].page_type == 'P'
                  ? 1150
                  : 820
                : 0
            "
            :bench="100"
            @scroll.passive="handleScroll"
          >
            <template v-slot:default="{ item }">
              <v-card-text class="text-center" :key="item.action">
                <v-card
                  tile
                  class="overflow-y-auto center elevation-2"
                  :color="pageItems[0].templateItems[11].content"
                  :id="`DisplayPage${item.page_number}`"
                  :height="
                    item != undefined
                      ? item.page_type == 'P'
                        ? '1123'
                        : pageItems[0].templateItems[10].content_present == false
                        ? '796'
                        : '786'
                      : '0'
                  "
                  :width="
                    item != undefined
                      ? item.page_type == 'P'
                        ? '793'
                        : '1123'
                      : '0'
                  "
                  style="padding-top: 1px"
                >
                  <div v-html="item.template_html"></div>
                </v-card>
              </v-card-text>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";

import { previewPDFTemplate } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export default {
  components: {
    Overlay,
  },
  props: {
    PDFViewDialog: Boolean,
    StoreObj: Object,
  },
  data: () => ({
    pageItems: [],
    height: 0,
    currentPage: 0,
    totalPage: 0,
    overlay: false,
  }),
  mounted() {
    this.height = window.innerHeight - 50;
  },
  watch: {
    PDFViewDialog(val) {
      if (val == true) {
        this.overlay = true;
        this.pageItems = this.StoreObj.settings;
        setTimeout(() => this.AddPageData(), 1);
      }
    },
  },
  methods: {
    handleScroll(e) {
      let pageBuffers = [];
      let eachPageHeigth = e.target.scrollHeight / this.pageItems.length;
      let currentScroll = e.target.scrollTop.toFixed();
      for (let i = 0; i < this.pageItems.length; i++) {
        pageBuffers.push(eachPageHeigth * (i + 1));
      }
      for (let j = 0; j < pageBuffers.length; j++) {
        if (
          currentScroll > pageBuffers[j] &&
          currentScroll < pageBuffers[j + 1]
        ) {
          this.currentPage = j + 2;
        } else if (currentScroll < pageBuffers[0]) {
          this.currentPage = 1;
        }
      }
      this.totalPage = this.pageItems.length;
    },
    AddPageData() {
      this.overlay = true;
      for (let i = 0; i < this.pageItems.length; i++) {
        // BackGround Image Adding------------------------------------------------
        if (this.pageItems[i].templateItems[11].imgSrc != "") {
          if (document.getElementById(`DisplayPage${i + 1}`) != null) {
            document.getElementById(
              `DisplayPage${i + 1}`
            ).style.backgroundSize =
              this.pageItems[i].page_type != undefined
                ? this.pageItems[i].page_type == "P"
                  ? "794px 1123px"
                  : "1123px 794px"
                : 0;
            document.getElementById(
              `DisplayPage${i + 1}`
            ).style.backgroundImage = `url(${this.pageItems[i].templateItems[11].imgSrc})`;
          }
        }
        // BackGround Image Removing------------------------------------------------
        else {
          if (document.getElementById(`DisplayPage${i + 1}`) != null) {
            document.getElementById(
              `DisplayPage${i + 1}`
            ).style.backgroundImage = null;
          }
        }
      }
      this.overlay = false;
    },
    async ViewPdfPreview() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(previewPDFTemplate, {
            input: {
              settings: JSON.stringify(this.pageItems),
              file_name:
                this.$store.getters.get_user_email.split("@")[0].toLowerCase() +
                ".pdf",
            },
          })
        );
        console.warn("res", JSON.parse(result.data.PreviewPDFTemplate));
        window.open(String(JSON.parse(result.data.PreviewPDFTemplate)));
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        console.log("error", error);
      }
    },
    PDFPreviewDialogEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style>
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.main_div {
  position: relative;
  height: 1000px;
  display: flow-root;
}
.sub_div {
  position: absolute;
  bottom: 0px;
}
.margin-top {
  margin-top: 150px;
}
.center {
  margin: auto;
  width: 50%;
}
.ql-font-monospace {
  font-family: "Courier New", monospace;
}
.ql-font-serif {
  font-family: Georgia, serif;
}
* {
  margin: 0;
  padding: 0;
}
.v-application p {
  margin-bottom: 0px;
}
</style>
