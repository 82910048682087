<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogChangePassword" max-width="400px" persistent>
      <v-card align="center">
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div v-text="'Change Password'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogChangePasswordEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 pb-0">
          <v-form ref="formConfirmPassword" lazy-validation>
            <v-text-field
              class="FontSize field_height field_label_size"
              outlined
              dense
              label="Enter New Password *"
              required
              :type="isPwd2 == false ? 'text' : 'password'"
              autocomplete="off"
              :rules="[(v) => !!v || 'Password is required']"
              v-model="Password.NewPassword"
            >
              <template v-slot:append-outer>
                <v-btn :disabled="Password.NewPassword == '' || Password.NewPassword == null || Password.NewPassword == undefined" icon color="primary" class="mt-n2" @click="isPwd2 = !isPwd2">
                  <v-icon v-text="isPwd2 ? 'mdi-eye' : 'mdi-eye-off'"></v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              class="FontSize field_height field_label_size"
              outlined
              dense
              label="Confirm New Password *"
              :type="isPwd3 == false ? 'text' : 'password'"
              required
              :rules="[(v) => !!v || 'Password is required']"
              v-model="Password.ConfirmPassword"
            >
              <template v-slot:append-outer>
                <v-btn
                  :disabled="Password.ConfirmPassword == '' || Password.ConfirmPassword == null || Password.ConfirmPassword == undefined"
                  icon
                  color="primary"
                  class="mt-n2"
                  @click="isPwd3 = !isPwd3"
                >
                  <v-icon v-text="isPwd3 ? 'mdi-eye' : 'mdi-eye-off'"></v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" outlined @click="dialogChangePasswordEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="borderRadius fontStyle text-capitalize" :loading="loadingConfirmPassword" color="secondary" @click="validateConfirmPassword()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogChangePassword: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    loadingConfirmPassword:false,
    SnackBarComponent: {},
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    isPwd1: false,
    isPwd2: false,
    isPwd3: false,
  }),
  watch: {
    "Password.NewPassword"(val) {
      if (val != "" && val != null && val != undefined) {
        this.isPwd2 = true;
      } else {
        this.isPwd2 = false;
        if (this.$refs.formConfirmPassword != undefined) {
          this.formConfirmPassword.resetValidation();
        }
      }
    },
    "Password.ConfirmPassword"(val) {
      if (val != "" && val != null && val != undefined) {
        this.isPwd3 = true;
      } else {
        this.isPwd3 = false;
        if (this.$refs.formConfirmPassword != undefined) {
          this.formConfirmPassword.resetValidation();
        }
      }
    },
  },
  methods: {
    validateConfirmPassword() {
      if (this.$refs.formConfirmPassword.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ChangePassword();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password Does Not Match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields Marked With Asterisks(*) Are Mandatory !!",
        };
      }
    },
    ChangePassword() {
      this.loadingConfirmPassword = true;
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, this.$store.getters.get_user_password, this.Password.ConfirmPassword);
        })
        .then(() => {
          this.loadingConfirmPassword = false;
          this.$store.commit("SET_USERPASSWORD", this.Password.ConfirmPassword);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            SnackbarText: "Password changed successfully!!",
            Top: true,
          };
          this.dialogChangePasswordEmit((this.Toggle = 1));
          let Login = {
            EmailID: this.$store.getters.get_current_user_details.user_email_id,
            Password: this.Password.ConfirmPassword,
          };
          localStorage.setItem("currentuseremail", JSON.stringify(Login));
        })
        .catch((error) => {
          this.loadingConfirmPassword = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: error.message,
            Top: true,
          };
        });
    },
    dialogChangePasswordEmit(Toggle) {
      this.isPwd2 = false;
      this.isPwd3 = false;
      if (this.$refs.formConfirmPassword != undefined) {
        this.$refs.formConfirmPassword.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
