var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SignatureWarning',{attrs:{"dialogSignatureWarning":_vm.dialogSignatureWarning},on:{"clicked":_vm.dialogSignatureWarningEmit}}),_c('PDFPreviewDialog',{attrs:{"StoreObj":_vm.StoreObj,"PDFViewDialog":_vm.PDFViewDialog,"template_html":_vm.template_html},on:{"clicked":_vm.PDFPreviewDialogEmit}}),_c('IssueSingleOrBulkWarningDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogSingleBulkIssueWarning":_vm.dialogSingleBulkIssueWarning},on:{"clicked":_vm.dialogSingleBulkIssueWarningEmit}}),_c('ValidInvalidDataDialog',{attrs:{"dialogValidInvalidData":_vm.dialogValidInvalidData,"tableData":_vm.tableData,"tableHeader":_vm.tableHeader,"StoreObj":_vm.StoreObj},on:{"clicked":_vm.dialogValidInvalidDataEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0 pl-2 pt-2",attrs:{"dense":""}},[_c('div',{staticClass:"largeFontSizeNew"},[_vm._v("Templates")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0 pr-2 pt-2",attrs:{"dense":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"borderRadius",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.ViewAllTemplatesEmit((_vm.Toggle = 2))}}},[_c('v-icon',[_vm._v("mdi-chevron-double-left")]),_vm._v("Back")],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.GetPortalPDFsListHeaders,"items":_vm.GetPortalTemplatesList.filter(function (item) { return item.is_editable == true; }),"footer-props":{
          'items-per-page-options': [10, 20, 30, 40, 50],
        },"items-per-page":50,"no-data-text":_vm.noDataText,"loading-text":"Loading... Please wait","page":_vm.page,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.template_name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.template_name))])]}},{key:"item.template_created_on",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(new Date(item.template_created_on).toLocaleString())+" ")])]}},{key:"item.category_name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.category_name == undefined ? "-" : item.category_name))])]}},{key:"item.is_editabel",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.is_editabel == true ? "Custom" : "SureCreds"))])]}},{key:"item.Actions",fn:function(ref){
        var item = ref.item;
return [_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"blue","outlined":""},on:{"click":function($event){return _vm.checkItem(item, 'PREVIEW')}}},'v-btn',attrs,false),on),[_vm._v("Preview")])]}}],null,true)},[_c('span',{attrs:{"align":"center"}},[_vm._v("Preview "+_vm._s(item.template_name))])]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.checkItem(item, 'USETEMPLATE')}}},[_vm._v("Use This Template")]),_c('download-excel',{attrs:{"data":_vm.checkData(item),"fields":_vm.checkFields(item)}},[_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"primary"}},[_vm._v("Download Excel Template ")])],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }