<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddIssuerUserDialog :dialogAddIssuer="dialogAddIssuer" @clicked="dialogCreateUsersEmit" />
    <ActivateInactivateUserDialog :dialogActivateInactivateUser="dialogActivateInactivateUser" :StoreObj="StoreObj" @clicked="dialogActivateInactivateUserEmit" />
    <ChangeOwnershipDialog :StoreObj="StoreObj" :dialogChangeOwnership="dialogChangeOwnership" @clicked="dialogChangeOwnershipEmit" />
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">Users</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn small @click="dialogAddIssuer = true" color="secondary" class="borderRadius text-capitalize"><v-icon small class="mr-1">mdi-plus</v-icon>Create User</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-expand-x-transition>
          <v-card outlined v-show="userTableExpand">
            <v-data-table v-if="userTableExpand==true" dense class="elevation-0" :headers="GetIssuerListHeaders" :items="GetAllIssuerUsersList" :no-data-text="noDataText">
              <template v-slot:[`item.sl_no`]="{ item }">
                <div class="FontSize">{{ GetAllIssuerUsersList.indexOf(item) + 1 }}</div>
              </template>
              <template v-slot:[`item.user_email_id`]="{ item }">
                <div class="FontSize">{{ item.user_email_id }}</div>
              </template>
              <template v-slot:[`item.user_name`]="{ item }">
                <div class="FontSize">{{ item.user_name }}</div>
              </template>
              <template v-slot:[`item.user_created_on`]="{ item }">
                <div class="FontSize">{{ new Date(item.user_created_on).toLocaleString() }}</div>
              </template>
              <template v-slot:[`item.user_type`]="{ item }">
                <div class="FontSize">{{ item.user_type.replace("_", " ") }}</div>
              </template>
              <template v-slot:[`item.user_status`]="{ item }">
                <div class="FontSize" :class="item.user_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'">
                  {{ item.user_status }}
                </div>
              </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.user_id != $store.getters.get_current_user_details.user_id && $store.getters.get_current_user_details.user_type == 'OWNER'"
                      icon
                      class="text-capitalize"
                      :color="item.user_status == 'ACTIVE' ? 'red' : 'green'"
                      @click="checkItem(item, 'ACTION')"
                      ><v-icon v-text="item.user_status == 'ACTIVE' ? 'mdi-account-cancel' : 'mdi-account-plus'"></v-icon
                    ></v-btn>
                  </template>
                  <span v-text="item.user_status == 'ACTIVE' ? `Deactivate ${item.user_name}` : `Activate ${item.user_name}`"></span>
                </v-tooltip>
                <v-tooltip bottom v-if="$store.getters.get_current_user_details.user_type == 'OWNER'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" class="text-capitalize" v-bind="attrs" v-on="on" v-if="item.user_id != $store.getters.get_current_user_details.user_id" icon @click="checkItem(item, 'OWNERSHIP')"
                      ><v-icon v-text="'mdi-account-star'"></v-icon
                    ></v-btn>
                  </template>
                  <span align="center">Transfer Ownership To {{ item.user_name }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-expand-x-transition>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllIssuerUsers } from "@/mixins/GetAllIssuerUsers.js";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import AddIssuerUserDialog from "@/components/Users/Dialogs/AddIssuerUserDialog.vue";
import ActivateInactivateUserDialog from "@/components/Users/Dialogs/ActivateInactivateUserDialog.vue";
import ChangeOwnershipDialog from "@/components/Users/Dialogs/ChangeOwnershipDialog.vue";
export default {
  components: {
    Overlay,
    AddIssuerUserDialog,
    ActivateInactivateUserDialog,
    ChangeOwnershipDialog,
  },
  mixins: [GetAllIssuerUsers, GetCurrentUser],
  data: () => ({
    overlay: false,
    noDataText: "",
    userTableExpand: false,
    GetIssuedCredentialsList: [],
    GetIssuerListHeaders: [
      { text: "Sl No.", value: "sl_no" },
      { text: "Name", value: "user_name" },
      { text: "Email ID", value: "user_email_id" },
      { text: "Created On", value: "user_created_on" },
      { text: "User Type", value: "user_type" },
      { text: "Status", value: "user_status" },
      { text: "Actions", value: "Actions" },
    ],
    StoreObj: {},
    dialogAddIssuer: false,
    dialogChangeOwnership: false,
    dialogActivateInactivateUser: false,
    GetAllIssuerUsersList: [],
  }),
  mounted() {
    this.GetAllIssuerUsersMethod();
    if(this.$store.getters.get_current_user_details.user_type != 'OWNER'){
      this.GetIssuerListHeaders = this.GetIssuerListHeaders.filter(item=>item.text!='Actions')
    }
  },
  methods: {
    dialogChangeOwnershipEmit(Toggle) {
      this.dialogChangeOwnership = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersMethod();
        this.GetCurrentUser();
      }
    },
    checkItem(item, ACTION) {
      this.StoreObj = item;
      switch (ACTION) {
        case "ACTION":
          this.dialogActivateInactivateUser = true;
          break;
        case "OWNERSHIP":
          this.dialogChangeOwnership = true;
          break;
      }
    },
    dialogActivateInactivateUserEmit(Toggle) {
      this.dialogActivateInactivateUser = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersMethod();
      }
    },
    dialogCreateUsersEmit(Toggle) {
      this.dialogAddIssuer = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersMethod();
      }
    },
    IssueCredentialsEmit(Toggle, response) {
      this.dialogAddIssuer = false;
      if (Toggle == 2) {
        this.StoreObj = response;
        this.GetIssuedCredentialsMethod();
      }
    },
  },
};
</script>
