<template>
  <div>
    <v-dialog v-model="dialogInvalidReasons" persistent max-width="400px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>Invalid Reasons !!!</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogInvalidReasonsEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-list-item dense v-for="(item, i) in reasons" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="`${i + 1}.${item}`"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" @click="dialogInvalidReasonsEmit()"><v-icon small class="mr-1">mdi-check</v-icon>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogInvalidReasons: Boolean,
    dataObj: Object,
  },
  data: () => ({
    Toggle: 0,
    reasons: [],
  }),
  watch: {
    dialogInvalidReasons(val) {
      if (val == true) {
        console.log("Check The DataObj", this.dataObj);
        this.reasons = this.dataObj.reasons;
      }
    },
  },
  methods: {
    dialogInvalidReasonsEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
