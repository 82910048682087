<template>
  <div>
    <v-dialog v-model="dialogSingleBulkIssueWarning" persistent max-width="400px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>Issue Settings</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogSingleBulkIssueWarningEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-6">
          <div class="font-weight-bold"></div>
          <v-radio-group dense class="my-0 py-0" v-model="issue_to_settings" row>
            <v-radio label="Single" value="SINGLE"></v-radio>
            <v-radio label="Multiple" value="MULTIPLE"></v-radio>
          </v-radio-group>
          <ExcelUpload v-if="issue_to_settings == 'MULTIPLE'" :on-success="handleSuccess" :before-upload="beforeUpload" />
        </v-card-text>
        <v-card-actions class="pt-0 pb-4" v-if="issue_to_settings != 'MULTIPLE'">
          <v-spacer></v-spacer>
          <v-btn small outlined class="borderRadius fontStyle text-capitalize" color="secondary" @click="dialogSingleBulkIssueWarningEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-check</v-icon>Cancel</v-btn
          >
          <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" @click="issueDeciderMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Proceed</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ExcelUpload from "@/components/Dashboard/Cards/ExcelUpload.vue";
export default {
  props: {
    dialogSingleBulkIssueWarning: Boolean,
    StoreObj: Object,
  },
  components: {
    ExcelUpload,
  },
  data: () => ({
    Toggle: 0,
    issue_to_settings: "SINGLE",
  }),
  watch: {
    dialogSingleBulkIssueWarning(val) {
      if (val == true) {
        this.issue_to_settings = "SINGLE";
      }
    },
  },
  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.dialogSingleBulkIssueWarningEmit((this.Toggle = 3), results, header);
    },
    issueDeciderMethod() {
      if (this.issue_to_settings == "SINGLE") {
        this.dialogSingleBulkIssueWarningEmit((this.Toggle = 2));
      } else if (this.issue_to_settings == "MULTIPLE") {
        this.dialogSingleBulkIssueWarningEmit(this.Toggle == 3);
      }
    },
    dialogSingleBulkIssueWarningEmit(Toggle, tableData, tableHeader) {
      this.$emit("clicked", Toggle, tableData, tableHeader);
    },
  },
};
</script>
