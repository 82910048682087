<template>
  <div>
    <Overlay :overlay="overlay" />
    <UploadImageDialog :StoreObj="StoreObj" :dialogUploadImage="dialogUploadImage" @clicked="dialogUploadImageEmit" />
    <DeleteImageDialog :StoreObj="StoreObj" :dialogDeleteImage="dialogDeleteImage" @clicked="dialogDeleteImageEmit" />
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">Image Library</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn small @click="(StoreObj.action = 'CREATE'), (dialogUploadImage = true)" color="secondary" class="borderRadius"><v-icon small class="mr-1">mdi-upload</v-icon>Upload Image</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <v-expand-x-transition>
          <v-card v-show="mediaTableExpand" class="elevation-0" outlined>
            <v-data-table v-if="mediaTableExpand == true" dense class="elevation-0" :headers="imagesHeader" :items="GetAllMediaList" :no-data-text="noDataText">
              <template v-slot:[`item.file_url`]="{ item }">
                <v-img :src="item.file_url" max-width="100px"></v-img>
              </template>
              <template v-slot:[`item.file_name`]="{ item }">
                <div class="FontSize">{{ item.file_name }}</div>
              </template>
              <template v-slot:[`item.media_created_on`]="{ item }">
                <div class="FontSize">{{ new Date(item.media_created_on).toLocaleString() }}</div>
              </template>
              <template v-slot:[`item.Action`]="{ item }">
                <v-btn x-small outlined color="primary" @click="checkItem(item, 'PREVIEW')">Preview</v-btn>
                <v-btn x-small outlined v-text="item.media_status == 'ACTIVE' ? 'Deactivate' : 'Activate'" class="ml-2" color="red" @click="checkItem(item, 'DELETE')"></v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-expand-x-transition>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import UploadImageDialog from "@/components/Media/Dialogs/UploadImageDialog.vue";
import DeleteImageDialog from "@/components/Media/Dialogs/DeleteImageDialog.vue";
import { GetAllMedia } from "@/mixins/GetAllMedia.js";
export default {
  components: {
    Overlay,
    UploadImageDialog,
    DeleteImageDialog,
  },
  mixins: [GetAllMedia],
  data: () => ({
    StoreObj: {},
    overlay: false,
    mediaTableExpand: false,
    dialogUploadImage: false,
    dialogDeleteImage: false,
    GetAllMediaList: [],
    imagesHeader: [
      { text: "Image", value: "file_url" },
      { text: "Name", value: "file_name" },
      { text: "Uploaded On", value: "media_created_on" },
      { text: "Action", value: "Action" },
    ],
  }),
  mounted() {
    this.GetAllMediaMethod();
  },
  methods: {
    dialogDeleteImageEmit(Toggle) {
      this.dialogDeleteImage = false;
      if (Toggle == 2) {
        this.GetAllMediaMethod();
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action == "PREVIEW") {
        this.dialogUploadImage = true;
      } else if (action == "DELETE") {
        this.dialogDeleteImage = true;
      }
    },
    dialogUploadImageEmit(Toggle) {
      this.dialogUploadImage = false;
      if (Toggle == 2) {
        this.GetAllMediaMethod();
      }
    },
  },
};
</script>
