<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogUploadImage" :max-width="imageUploaded == false ? '400px' : '800px'" persistent>
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div v-text="StoreObj.action == 'CREATE' ? 'Upload Image' : 'Preview'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogUploadImageEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row no-gutters>
              <v-col v-if="StoreObj.action == 'CREATE'" cols="12" xs="12" sm="12" :md="imageUploaded == false ? 12 : 4">
                <v-text-field dense outlined class="FontSize field_height field_label_size mt-5" label="File Name" :rules="[(v) => !!v || 'Required']" v-model="file_name"></v-text-field>
                <v-file-input prepend-icon="" accept="image/*" label="Select Image" dense outlined class="FontSize field_label_size" v-model="imageFile" clearable></v-file-input>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn small class="borderRadius" color="primary" :loading="loading" v-if="imageUploaded == true" @click="validateMethod()"><v-icon small>mdi-upload</v-icon>Upload</v-btn>
                </v-card-actions>
              </v-col>
              <v-col cols="12" xs="12" sm="12" :md="StoreObj.action == 'CREATE' ? 8 : 12" v-if="imageUploaded == true">
                <v-card class="mt-5" :class="StoreObj.action == 'CREATE' ? 'ml-4' : ''" outlined>
                  <h3 class="py-3 pl-3" v-if="StoreObj.action == 'CREATE'">Preview</h3>
                  <v-card-text align="center">
                    <v-img max-width="300px" :src="imgSrc"></v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createMedia } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
var AWS = require("aws-sdk");
export default {
  props: {
    dialogUploadImage: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    file_name: "",
    imgSrc: "",
    imageFile: null,
    SnackBarComponent: {},
    loading: false,
    imageUploaded: false,
  }),
  watch: {
    dialogUploadImage(val) {
      if (val == true) {
        if (this.StoreObj.action == "PREVIEW") {
          this.imgSrc = this.StoreObj.file_url;
          this.imageUploaded = true;
        }
      }
    },
    imageFile(val) {
      if (val != undefined && val != null && val != "") {
        var fileReader = new FileReader();
        fileReader.readAsDataURL(val);
        var self = this;
        fileReader.onload = async function (val) {
          self.imgSrc = val.target.result;
          self.imageUploaded = true;
        };
      } else {
        this.imgSrc = "";
        this.imageUploaded = false;
      }
    },
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.uploadImageToS3();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter File Name",
        };
      }
    },
    async uploadImageToS3() {
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
        secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.imageFile);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
          Key: `${self.$store.getters.get_current_user_details.issuer_id}/IMAGELIBRARY/${self.file_name}.${self.imageFile.name.split(".")[1]}`,
          Body: buf,
          ContentType: self.imageFile.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.loading = false;
          } else if (data) {
            let file_url = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
            self.UploadImageToDynamo(file_url);
          }
        });
      };
    },
    async UploadImageToDynamo(file_url) {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          file_url: file_url,
          file_name: this.file_name,
          file_size: this.imageFile.size,
          file_type: this.imageFile.type,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(createMedia, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateMedia);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogUploadImageEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogUploadImageEmit(Toggle) {
      this.imgSrc = "";
      this.imageUploaded = false;
      this.imageFile = null;
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
